import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modelpopupconfirmation',
  templateUrl: './modelpopupconfirmation.component.html',
  styleUrl: './modelpopupconfirmation.component.scss'
})
export class ModelpopupconfirmationComponent {

  @Input() show = false;
  @Input() showHeaderMessage:any;
  @Input() showModelContent:any;
  @Output() closedModelpopup = new EventEmitter<boolean>();

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    console.log(this.showHeaderMessage)
    
  }

  closeModelpopup(){
    this.show=false;
    this.closedModelpopup.emit(false);

  }
 
}
