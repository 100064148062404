export const environment = {
  // clientName: window.location.href
  //   .replace('http://', '')
  //   .replace('https://', '')
  //   .split('.')[0],
  production: false,
  ssr: false,
  brandName: 'Groupbenefitz',
  app: {


    // bfcurl:"http://admintestws.groupBenfitz.com/api/",

    //Test Enivornment
    // grp: "https://testapi.groupbenefitz.aitestpro.com/",
    // globalgrp:"https://testsignupform.groupbenefitz.aitestpro.com/",
    // logourl:'https://testadminapi.groupbenefitz.aitestpro.com/api/ap/',
    // commonurl:'https://testcommonapi.groupbenefitz.aitestpro.com/api/ap/',
    // waddellink:'https://test.groupbenefitz.aitestpro.com/waddell/8K2EHkaW1470',
    // iafglink:'https://testsignupform.groupbenefitz.aitestpro.com/iafg/',
    // kixcare:'https://testsignupform.groupbenefitz.aitestpro.com/kixcare/',

    //Sandbox Environment
    // grp:"https://api-dev.groupbenefitz.ca/",
    // globalgrp:"https://sandbox.groupbenefitz.ca/",
    // logourl:'https://api-admin.groupbenefitz.org/api/ap/',
    //commonurl:'https://api-common.groupbenefitz.org/api/ap/',

    //Production Environment
    grp:"https://api.groupbenefitz.ca/",
    globalgrp:"https://signup.groupbenefitz.ca/",
    logourl:'https://api-admin.groupbenefitz.net/api/ap/',
    commonurl:'https://api-common.groupbenefitz.org/api/ap/',
    waddellink:'https://signup.groupbenefitz.ca/waddell/8K2EHkaW1470',
    iafglink:'https://signup.groupbenefitz.ca/iafg/',
    kixcare:'https://signup.groupbenefitz.ca/kixcare/',

    //igbms Enviornmet
    // grp: 'https://api.igbms.com/',
    // globalgrp: 'https://signup.igbms.com/',
    // logourl: 'https://adminapi.igbms.com/api/ap/',
    // commonurl: 'https://api-common.groupbenefitz.org/api/ap/',
    // waddellink: 'https://signup.igbms.com/waddell/8K2EHkaW1470',

    personalInfoCaptcha: '6Lfj88QgAAAAAAJ8bY1NW0Bgmm9V8PV2onC4RCNx',
    fusbillrecaptcha: '6LfVtGwUAAAAALHn9Ycaig9801f6lrPmouzuKF11',

    localgrp: 'http://localhost:4200/',
    atp: 'https://dev.aitestpro.com/',

    id: 'fooClientId',
    key: 'secret',
  },

  googleMapKey: 'AIzaSyCx_ZaqWDu6leZ7ffeIz5sG9qrN5s4KFF0',
  debugEnabled: true,
};
