import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SignupFormComponent } from './signup-form/signup-form.component';
import { PaymentsComponent } from './payments/payments.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ConfirmationModelComponent } from './confirmation-model/confirmation-model.component';
import { FormsModelComponent } from './forms-model/forms-model.component';
import { ModelDialogComponent } from './model-dialog/model-dialog.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PdfViewerModule } from 'ng2-pdf-viewer';
 import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
 import { SignaturePadModule } from 'angular2-signaturepad';
 import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
 import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { LoaderComponent } from './loader/loader.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './loader.interceptor';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { CorporateSignupComponent } from './corporate-signup/corporate-signup.component';
import { ModelpopupconfirmationComponent } from './modelpopupconfirmation/modelpopupconfirmation.component';
import { CorporatepaymentComponent } from './corporatepayment/corporatepayment.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SignupFormComponent,
    PaymentsComponent,
    PagenotfoundComponent,
    ConfirmationModelComponent,
    FormsModelComponent,
    ModelDialogComponent,
    LoaderComponent,
    PhoneNumberPipe,
    CorporateSignupComponent,
    ModelpopupconfirmationComponent,
    CorporatepaymentComponent,
    SafeHtmlPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    PdfViewerModule,
    SignaturePadModule,
    RecaptchaModule,
    RecaptchaFormsModule
    
  ],
  providers: [provideAnimations(), {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
