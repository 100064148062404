
@if(show){
  <div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
    <div class="bg-white  rounded-xl shadow-lg w-full md:w-1/2">
      <div class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
        <h2 class="text-[14px] font-semibold">{{buttonInfo}} Dependent</h2>
        <!-- <button (click)="onClose()" class="text-gray-500 hover:text-gray-800 flex items-end justify-end">&times;</button> -->
      </div>
      <form [formGroup]="childForm" (ngSubmit)="onSubmit()">

        <div class="grid grid-cols-1 sm:grid-cols-6 gap-2 p-2">
          <div class="sm:col-span-3">
            <label for="" class="">{{lang.first_name}} <span class="text-danger">*</span></label>
            <input class="form-control checking-field" type="text" placeholder="First Name"
              formControlName="firstName" (keypress)="alphabatesOnly($event)">

          @if (f['firstName'].errors && f['firstName'].touched) {
            <div class="invalid text-red-500">
              @if (f['firstName'].errors['required']) {
              <div>{{ lang.please_enter_first_name }}</div>
              } @if (f['firstName'].errors['minlength']) {
              <div>{{ lang.first_name_minm_error }}</div>
              }
            </div>
            }

          </div>
          <div class="sm:col-span-3">
            <label for="" class="">{{lang.lastName}} <span class="text-danger">*</span></label>
            <input type="text" class="form-control checking-field" placeholder="Last Name"
              formControlName="lastName" (keypress)="alphabatesOnly($event)">


              @if (f['lastName'].errors && f['lastName'].touched) {
                <div class="invalid text-red-500">
                  @if (f['lastName'].errors['required']) {
                  <div>{{ lang.please_enter_last_name }}</div>
                  } @if (f['lastName'].errors['minlength']) {
                  <div>{{ lang.last_name_minm_error }}</div>
                  }
                </div>
                }



       </div>
       <div class="sm:col-span-3">
        <label for="" class="">{{lang.gender}} <span class="text-danger">*</span></label>

        <select class="form-control" placeholder="Select" formControlName="gender">

          <option value="" selected disabled>Select</option>
          <option value="Male">{{ lang.male }}</option>
          <option value="Female">{{ lang.female }}</option>
          <option value="Non-Binary">{{ lang.nonBinary }}</option>
          <option value="Undisclosed"> {{ lang.unDisclosed }} </option>
        </select>


        @if (f['gender'].errors && f['gender'].touched) {
          <div class="invalid text-red-500">
            @if (f['gender'].errors['required']) {
            <div>{{ lang.please_select_gender }}</div>
            }

          </div>
          }

      </div>


      <div class="sm:col-span-3">
        <label for="childInfoDOB" class="">{{lang.dateOfBirth}}<span class="text-danger">*</span></label>
        <!-- <input class="form-control checking-field" id="childInfoDOB" placeholder="MM-DD-YYYY"
          formControlName="date_of_birth"
          > -->

          <input type="text" (paste)="(false)"
          class="form-control" formControlName="date_of_birth"
          [minDate]="minDate"
          [maxDate]="maxDate" bsDatepicker
          [bsConfig]="{dateInputFormat: 'MM-DD-YYYY'}"
          placeholder="MM-DD-YYYY"
          bsDatepicker (blur)="checkChildDateofBirth($event)" (input)="checkChildDateofBirth($event)"
          (keypress)="numbersOnly($event)"/>

          @if (f['date_of_birth'].errors && f['date_of_birth'].touched) {
            <div class="invalid text-red-500">
              @if (f['date_of_birth'].errors['required']) {
              <div>{{ lang.please_choose_date_of_birth }}</div>
              }
              @if (f['date_of_birth'].errors['childrenminimumAge']) {
                <div>Age must be below 21 years</div>
                }
                @if (f['date_of_birth'].errors['validGraduationDateBirth']) {
                  <div>Please ensure the graduation date is greater than the date of birth.</div>
                  }


            </div>
            }
      </div>

      <div class="col-span-full">
        <label for="childDependentInsurence" class="">{{lang.dependentCover}} <span class="text-danger">*</span>
        </label>
        <div class="flex items-center space-x-2">
          <input
            type="radio"
            id="true"
            formControlName="is_child_having_healthcard"
            value="true" (click)="havingchildrenDependentInsurence($event)"
            class="form-radio text-blue-600"
          />
          <label for="true" class="text-gray-700">Yes</label>
        </div>

        <div class="flex items-center space-x-2">
          <input
            type="radio"
            id="false"
            formControlName="is_child_having_healthcard"
            value="false" (click)="havingchildrenDependentInsurence($event)"
            class="form-radio text-blue-600"
          />
          <label for="false" class="text-gray-700">No</label>
        </div>



        @if (f['is_child_having_healthcard'].errors && f['is_child_having_healthcard'].touched) {
          <div class="invalid text-red-500">
            @if (f['is_child_having_healthcard'].errors['required']) {
            <div>please select health card option</div>
            }

          </div>
          }

          @if(childForm.get('is_child_having_healthcard')?.value === 'true'){
            <div class="sm:col-span-3">
              <label for="" class="">{{lang.name_of_the_carrier}} <span class="text-danger">*</span></label>
              <input type="text" class="form-control checking-field"
                placeholder="{{lang.name_of_the_carrier}}" formControlName="child_carrier_name">

                @if (f['child_carrier_name'].errors && f['child_carrier_name'].touched) {
                  <div class="invalid text-red-500">
                    @if (f['child_carrier_name'].errors['required']) {
                    <div>   {{ lang.please_enter_name_of_carrier }}</div>
                    }

                  </div>
                  }



            </div>
               }

      </div>


        <div class="sm:col-span-full">
          <input type="checkbox" class="" name="" id="" formControlName="isDisabled"
           />&nbsp;{{
          lang.childdsiablility }}&nbsp;

          <!-- <i class="fa fa-info-circle" (click)="dependentWithSpecialNeedsShow()"></i> -->
        </div>

      <div class="sm:col-span-full">
        <div class="form-group col-12 paddingLeft6px">
          <input type="checkbox" class="" name="" id="" formControlName="enrolledInUniversity"
            (click)="enrolledunversity($event)" />&nbsp;{{
          lang.enrolledunversity }}&nbsp;

          <!-- <i class="fa fa-info-circle" (click)="postSecondaryShow()"></i> -->
        </div>

        @if(childForm.get('enrolledInUniversity')?.value){
          <div class="sm:col-span-3 w-1/2">
          <label class="graduationDay">{{ lang.graduation_day }}<span class="text-danger">*</span></label>

            <input type="text" class="form-control" (paste)="(false)"
                    bsDatepicker  #dp
                    placeholder="MM-DD-YYYY"
                    [bsConfig]="{dateInputFormat: 'MM-DD-YYYY'}"
                    bsDatepicker (blur)="checkDateOfgraducation($event)" (input)="checkDateOfgraducation($event)"
                     (keypress)="numbersOnly($event)"

                    [placement]='"top"'
                    formControlName="graduationDay" />

            @if (f['graduationDay'].errors && f['graduationDay'].touched) {
              <div class="invalid text-red-500">
                @if (f['graduationDay'].errors['required']) {
                <div>Please choose graduation date</div>
                }
                @if (f['graduationDay'].errors['validGraduationDate']) {
                  <div>Please ensure the graduation date is greater than the date of birth.</div>
                  }
                  @if (f['graduationDay'].errors['dateOfBirth']) {
                    <div>Please select Date of Birth</div>
                    }
              </div>
              }

        </div>
         }
      </div>
          </div>



        <div class="flex justify-end gap-2 border border-t-indigo-700 px-2">
          <button
            type="button"
            class="w-36 h-14 mt-2 mb-2 rounded-full border-2 border-gray-700 bg-gray-200 p-0.5 font-bold hover:bg-white"
            (click)="onClose()"
          >
            {{ lang.cancel }}
          </button>
          <!-- <button type="button" (click)="onClose()" class="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2">Cancel</button> -->
          <button
          type="submit"
          class="w-36 h-14 mt-2 mb-2 rounded-full border-2 border-indigo-700 p-0.5 font-bold hover:border-green-500" [disabled]="childForm.invalid">
          {{buttonInfo}}
        </button>
        </div>
      </form>
    </div>
  </div>
  }
