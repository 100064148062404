<!-- <div class="header bg-white text-white text-center fixed top-0 w-full z-1000 mx-1 sm:mx-12 mt-1" >

  <div class="grid grid-cols-3 sm:col-grid-3 gap-2">
    <div class="flex items-start justify-start mt-5">

      @if(parentBrokerLogo && parentBrokerLogo!='null'){
      <img class="w-56 h-24 absolute left-0  md:w-56 md:h-auto md:rounded-none mx-auto" src={{parentBrokerLogo}}  alt="" width="384" height="512">

      }
    </div>
    <div class="flex md:inline-flex items-end justify-end mt-5 md:item-center md:justify-center">
      <img class="w-56 h-24 md:w-56 md:h-auto md:rounded-none mx-auto" src={{brokerlogo}}  alt="" width="384" height="512">


    </div>
    <div class="mt-12 w-1/4 text-end sm:col-span-1">

    </div>

  </div>
  <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 mt-5">
    <div class="col-span-1 text-center text-black font-bold text-2xl">
  <p [innerHTML]="message"></p>
    </div>


  </div>

  </div>

 -->
 <div class="header bg-white text-white text-center fixed top-0 w-full z-1000 mx-1 sm:mx-12" >

  <div class="grid grid-cols-3 sm:col-grid-3 gap-2 h-40 mt-1">
    <div class="flex items-start justify-start">
      <!-- <img src="https://api-admin.groupbenefitz.net/api/ap/app/resources/images/broker/GroupBenefitz.png"> -->
      <!-- <img class="w-56 h-24 absolute left-0 md:w-56 md:h-auto md:rounded-none mx-auto" src={{brokerlogo}}  alt="" width="384" height="512"> -->

      @if(parentBrokerLogo && parentBrokerLogo!='null'){
      <img class="block max-w-[300px] max-h-[100px] w-auto h-auto object-contain" src={{parentBrokerLogo}}  alt="" >

      }
    </div>
    <div class="flex md:inline-flex items-end justify-end  md:item-center md:justify-center">
      <img class="block max-w-[300px] max-h-[100px] w-auto h-auto object-contain" src={{brokerlogo}}  alt="" >
      <!-- <img src={{brokerlogo}} id="imglogo" > -->
            <!-- <img src="https://api-admin.groupbenefitz.net/api/ap/app/resources/images/broker/GroupBenefitz.png"  id="imglogo"> -->


    </div>
    <div class="mt-12 w-1/4 text-end sm:col-span-1">
      <!-- <select class="form-control ng-pristine ng-invalid ng-touched"  id="languageSelection" placeholder="Select">
        <option value="en" label="English">English</option>
        <option value="fr" label="French">French</option>

      </select> -->
    </div>

  </div>
  <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 mt-5">
    <div class="col-span-1 text-center text-black font-bold text-2xl">
  <!-- Health Enrollment Application -->
  <p [innerHTML]="message"></p>
    </div>


  </div>
    <!-- <div class="row headerBar" >

      <div class=" col-md-3 col-4">
        <img *ngIf="parentBrokerLogo && parentBrokerLogo!='null'" src={{parentBrokerLogo}}  class="responsive" alt="" id="imglogo" >
      </div>

      <div class="col-4 col-md-6" id="corporate">

        <div *ngIf="!brokerlogo || brokerlogo=='null'; else showLogo" class="alt-broker-logo" ></div>

        <ng-template #showLogo>
          <img  src={{brokerlogo}}  class="d-sm-block mx-auto d-none" alt="" id="imglogo">
          <img  src={{brokerlogo}}  class="d-sm-none mx-auto d-block" alt="" style="max-width:100%" >
        </ng-template>



      </div>

      <div class="col-3" id="languageWrapper">

        <div class="languageWrapper row">

          <div class="col-7 px-0 text-right">

            <label id="language">Language&nbsp;</label>

          </div>

          <div class="col-7 px-1">

            <select class="form-control ng-pristine ng-invalid ng-touched"  id="languageSelection" placeholder="Select" (change)="emit(keyword.value);" #keyword>
              <option value="en" label="English">English</option>
              <option value="fr" label="French">French</option>

            </select>

          </div>



        </div>



      </div>

  </div>
  <div class="row">
  <h1 *ngIf="!message || message=='null' || message==''; else showFormTitle" class="formTitle formTitle_alt headertext" [innerHTML]="message"  style="margin-top:10px;"></h1>
  <ng-template #showFormTitle> <h1 class="formTitle headertext" [innerHTML]="message" ></h1>
  </ng-template>
  </div> -->
  </div>
