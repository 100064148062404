import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from './../environments/environment';

import { DecimalPipe, formatNumber } from "@angular/common";
import { CorporateService } from "./../services/corporate.service";
import Swal from 'sweetalert2';
import {
  floatWith2Decimals,
  groupBy,
  sumBy,
  adjustWith2DecimalsForUI,
} from "./../commonfunctions/commonFunction";
import { SharedService } from '../shared/shared.service';
@Component({
  selector: 'app-corporatepayment',
  templateUrl: './corporatepayment.component.html',
  styleUrl: './corporatepayment.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [DecimalPipe],

})

export class CorporatepaymentComponent implements OnInit {

  public paymentSucessResponse:boolean=false;
  public paymenttransactionResponse:boolean=false;

  public subscriptiondetails:any;
  public visible =false;
  public showbuttons =false

  public countries =[]
  public plansummary:any
  public plansummaryMain:any;
  public plansummaryLIFE:any;
  public optplansummary:any;
  public provisioning:any =false;
  public currency:any;
  public currencySymbol:any;
  public uploadfile:any;
  public demovalue:any;
public responsevalue:any;
public failedarray:any =[]
  customerLink: any;
 ShowModelPopup=false;
ShowModelPopupTitle: any;
 ShowModelPopupMessage: any;
 showEnrollmentModel=false
showHeaderMessage:any
showModelContent:any
  iafgPlansAvailability: boolean =false;
  plansummaryLIFEFinal: any =[];
  constructor(private CorporateService:CorporateService,private Shared:SharedService,private _sanitizer: DomSanitizer,public router:Router,private route: ActivatedRoute,private _decimalPipe: DecimalPipe) { }





  ngOnInit() {
    sessionStorage.setItem("pageName", "Signup Process");
    this.postAnalyticsData();
    setInterval(() => {
      this.postAnalyticsData();
    }, 30000);



    this.uploadfile =this.Shared.getMessage()
    this.subscriptiondetails = sessionStorage.getItem("subscriptionresult")


    this.route.queryParams.subscribe(params => {




      if(sessionStorage.getItem("paymentMethod")=="CC"){


        if(params["success"]=="True"){

          this.paymenttransactionResponse =true
          this.visible =true
          this.showbuttons =false

        }
        else{
          this.paymenttransactionResponse =true
          this.visible =true

          this.showbuttons =false
          this.failedarray.push("CCFailed")

          sessionStorage.setItem("failed",JSON.stringify(this.failedarray))


        }


      }

      else{
        this.paymenttransactionResponse =true
        this.visible =true

        this.showbuttons =false
      }



    let personalInfo  =JSON.parse(sessionStorage.getItem("Data")||'{}')
    let customerId  =sessionStorage.getItem("customerId")
    // let publicApiKey  =JSON.parse(sessionStorage.getItem("publicApiKey"))

    let billingpostalcode= sessionStorage.getItem("billingpostalcode")
    let billingprovincelist =sessionStorage.getItem("billingprovincelist")
    let billingaddresscity =sessionStorage.getItem("billingaddresscity")
    let addressline2 =sessionStorage.getItem("addressline2")
    let addressline1 =sessionStorage.getItem("addressline1")
    // this.paymentSucessResponse = true


    let billingAddressData={}

    if(sessionStorage.getItem("homeAddress")=="true"){

billingAddressData={}

    }
    else{
      billingAddressData= JSON.parse(sessionStorage.getItem("billingAddress")||'{}')

    }

    let bankdetailsobj =sessionStorage.getItem("bankDetails")



    this.countries =JSON.parse(sessionStorage.getItem("countrydetails")||"[]")

    this.countries.forEach((element:any)=>{
      this.currency =element.currency
      this.currencySymbol =element.currencySymbol
    })

  let summary =JSON.parse(sessionStorage.getItem("plansummary")||'[]')
  var formData: any = new FormData();



    let plans:any= []




    this.plansummaryMain = JSON.parse(sessionStorage.getItem("allSelectedPlans")||'')
    this.plansummaryLIFE = JSON.parse(sessionStorage.getItem("planssummarymainLife")||'')
    let planDetailsArrayBlock =JSON.parse(sessionStorage.getItem("planssummarymainLife")||'[]')



    this.plansummaryLIFE.forEach((element:any,index:number) => {

      element.member.maximumCoverageWithoutEvidence =element.member.defaultRange

      if(element.spouse && element.spouse.maximumCoverageWithoutEvidence){
        element.spouse.maximumCoverageWithoutEvidence =element.spouse.defaultRange

      }
      element['amount'] = element.guaranteedAcceptanceDetails.amount
      element['amountUI'] = "$"+element.guaranteedAcceptanceDetails.amount
      element['planPrice'] = element.guaranteedAcceptanceDetails.amount
      element['total'] =       element.guaranteedAcceptanceDetails.total
      element['totalPrice'] =       element.guaranteedAcceptanceDetails.total
      element['skuTotalPrice'] =       element.guaranteedAcceptanceDetails.total
      element['totalUI'] = "$"+element.guaranteedAcceptanceDetails.total
      element['tax'] = element.guaranteedAcceptanceDetails.tax
      element['taxUI'] = "$"+element.guaranteedAcceptanceDetails.tax
      this.plansummaryLIFEFinal.push(element)

    });
     planDetailsArrayBlock.forEach((element:any,index:number) => {
      if(element.id==13){
        this.iafgPlansAvailability =true
      }
      else{
        this.iafgPlansAvailability =false
      }

    });

    this.plansummary =this.plansummaryMain.concat(this.plansummaryLIFEFinal)

    // this.plansummary =this.plansummaryMain.concat(this.plansummaryLIFE)
    // this.optplansummary = JSON.parse(sessionStorage.getItem("allSelectedOptPlans"))
    this.plansummary = this.plansummary.sort(function(a:any, b:any) {
      return (a.packageId - b.packageId);
    })

    this.plansummary.forEach((element:any) => {

if(element.packageId==1){
  this.provisioning =true
}

   element.totalPrice = this._decimalPipe.transform(element.totalPrice,"1.2-2")?.replace(/,/g, "")
   element.total = this._decimalPipe.transform(element.totalPrice,"1.2-2")?.replace(/,/g, "")
   element.tax = this._decimalPipe.transform(element.tax,"1.2-2")?.replace(/,/g, "")
   element.amount = this._decimalPipe.transform(element.amount,"1.2-2")?.replace(/,/g, "")
   element.planLevelName = element.groupName
    plans.push(element)
    });

    // plans.concat(this.optplansummary)


    let employeeKey = sessionStorage.getItem("employeelink");
      let employeeGeneric;

    if(employeeKey && employeeKey !="undefined"){
      employeeGeneric = false

    }else{
      employeeGeneric =true

    }


 formData.append("plans",JSON.stringify(plans));
 formData.append("customerId", parseInt(sessionStorage.getItem("customerId")||'0'));
 formData.append("fusebill_customerId", parseInt(sessionStorage.getItem("fusebillCustomerId")||'0'));
 formData.append("fusebill_paymentMethodId", parseInt(params["paymentMethodId"]) || 0);

 formData.append("enrollmentDate", sessionStorage.getItem("enrollmentdate"));
 formData.append("add_address_toTaxation", false);
 formData.append("totalAmount", sessionStorage.getItem("totalAmount"));

//  formData.append("plans",);
 formData.append("currency", "CAD");
 formData.append("currencySymbol", "$");

 formData.append("totalAmountUI", sessionStorage.getItem("totalAmountUI"));
 formData.append("stateId", parseInt(sessionStorage.getItem("stateid")||'1'));
 formData.append("signature", sessionStorage.getItem("signature") || "");

 formData.append("timestamp",new Date().getTime());

 formData.append("customerTierId",sessionStorage.getItem("customerTierId")?parseInt(sessionStorage.getItem("customerTierId")||'1'):1 || 1);

 formData.append("payrollDeduction",true)
 formData.append("corporateStateId",sessionStorage.getItem("companyStateId"))
 formData.append("employeeWorkingStateId",sessionStorage.getItem("corporateStateId"))


 formData.append("userKey", sessionStorage.getItem("userKey"));
 formData.append("employeeGeneric", employeeGeneric);
 formData.append("iafgPlansAvailability", this.iafgPlansAvailability);








if(sessionStorage.getItem("paymentMethod")=="CC"){
  formData.append("fusebill_paymentMethod", sessionStorage.getItem("paymentMethod"));


}else if(sessionStorage.getItem("paymentMethod")=="PAD"){
  formData.append("padFilename",sessionStorage.getItem("filename")+".pdf");
 formData.append("session",sessionStorage.getItem("session")) || "";
 formData.append("files",this.uploadfile) || "";
 formData.append("fusebill_paymentMethod", sessionStorage.getItem("paymentMethod"));

}
else{
  formData.append("fusebill_paymentMethod", "NONE");


}




 //this.consoleMessage(formData)

// if(sessionStorage.getItem("Demoform")=="true"){
//   this.demovalue="test/ROE"
// }
// else{
//   this.demovalue="v2/subscription"
// }

this.demovalue="v4/subscription"
 var requestOptions:any = {

  method: 'POST',
  body: formData,
  redirect: 'follow'

};

if(!sessionStorage.getItem("subscriptionCalled") || sessionStorage.getItem("subscriptionCalled")!="1"){
  sessionStorage.setItem("subscriptionCalled","1")

 fetch(environment.app.grp+"api/corporate/customer/"+this.demovalue+"", requestOptions)

 .then(response => response.text())

 .then((resposne:any) => {
  //this.consoleMessage(resposne)
  resposne=JSON.parse(resposne)
   //this.consoleMessage(resposne.status)
   if(resposne.status==200){

    this.customerLink = resposne.customerPortalLink

    this.paymenttransactionResponse =false
    this.paymentSucessResponse = true
    this.visible =false
    this.provisioning =false
   this.showbuttons =true
   this.paymenttransactionResponse =false
   this.paymentSucessResponse = true

   this.subscriptiondetails = resposne.data
   if(sessionStorage.getItem("Demoform")=="true"){
    this.responsevalue=resposne.message+"<br>"+resposne.data

  }
  else{

    this.responsevalue=`Thank you for your enrollment!  Please check your inbox for your welcome email. Login to your Member Portal using the activation code in the welcome email and update the password.`

    // this.responsevalue="Thank you for applying for coverage with us. You will receive your booklet/card (if applicable) and instructions on accessing your program 3-5 days before your coverage begins."
  }
  this.showEnrollmentModel =true
  this.showHeaderMessage = 'Enrollment successful'
  this.showModelContent =this.responsevalue

   sessionStorage.clear()

    sessionStorage.clear()

  }

  else{
    this.paymenttransactionResponse =false
    this.visible =false
    this.showEnrollmentModel =true
    this.showHeaderMessage = 'Enrollment successful'
    this.showModelContent =this.responsevalue

     sessionStorage.clear()


  }

})
.catch(error => {
  //this.consoleMessage('error', error)
  if([0,500,501,502,503].includes(error.status)){
    this.ShowModelPopup =true
    this.ShowModelPopupMessage ="Sorry, the server is currently undergoing maintenance. Please try again later. We apologize for any inconvenience."
    this.ShowModelPopupTitle ="Info"


  }
  else{

    this.ShowModelPopup =true
    this.ShowModelPopupMessage =error.error.message ? error.error.message : error.error.error,
    this.ShowModelPopupTitle ="Info"


  }
});


}
else{
  this.paymenttransactionResponse =false
  this.visible =false
  this.provisioning=false

  this.ShowModelPopup =true
  this.ShowModelPopupMessage ='You are already done with the subscription.Please check your mail or contact admin@groupbenefitz.ca',
  this.ShowModelPopupTitle ="Info"


}

  })



  }

  onClosedModelpopup(confirmed: boolean) {
    if (confirmed) {
      this.ShowModelPopup = false;
      this.showEnrollmentModel =false;
    } else {
      this.ShowModelPopup = false;
      this.showEnrollmentModel =false;
    }
  }
  closeModelpopup(){
    this.showEnrollmentModel =false
    window.open(this.customerLink)

  }
  postAnalyticsData() {
    var pageName = sessionStorage.getItem("pageName");
    if (
      sessionStorage.getItem("ipaddress")
    ) {
      fetch(environment.app.grp + "api/common/analytics", {
        method: "POST",

        body: JSON.stringify({
          ipAddress: sessionStorage.getItem("ipaddress"),
          timestamp: sessionStorage.getItem("timeStamp"),
          pageName: pageName ? pageName : "PersonalInfo",
          gbReferer: window.document.referrer || "",
          pageUrl: window.location.href,
        }),

        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
    }
  }
  consoleMessage(...args:any){
    // console.log(args)
    }
public gotohomepage(){
  this.router.navigate(['/signup']);
}

public printpage(){
  window.print();
}
}

