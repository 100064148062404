

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SignupformService } from './../services/signupform.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DecimalPipe } from '@angular/common';
import Swal from 'sweetalert2';
import { environment } from '../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrl: './payments.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers:[DecimalPipe]
})


export class PaymentsComponent {

  public paymentSucessResponse:boolean=false;
  public paymenttransactionResponse:boolean=false;

  public subscriptiondetails:any;
  public visible =false;
  public showbuttons =false
  public lang:any ={}
  public languageTokensArray:any =[];
  public countries =[]
  public plansummaryMain:any
  public plansummary:any
  public plansummaryLIFE:any
  public provisioning:any =false;
  public currency:any;
  public currencySymbol:any;
  public uploadfile:any;
  public demovalue:any;
public responsevalue:any;
public failedarray:any =[]
showEnrollmentModel=false
showHeaderMessage:any
showModelContent:any
  customerLink: any;
  ShowModelPopup=false;
  ShowModelPopupTitle: any;
  ShowModelPopupMessage: any;
  iafgPlansAvailability: boolean =false;
  plansummaryLIFEFinal: any =[];
  constructor(private _SignupformService: SignupformService,private Shared:SharedService,private _sanitizer: DomSanitizer,public router:Router,private route: ActivatedRoute,private _decimalPipe: DecimalPipe) { }

  ngOnInit(): void {
    sessionStorage.setItem("pageName", "Signup Process");
    this.postAnalyticsData();
    setInterval(() => {
      this.postAnalyticsData();
    }, 30000);
    let personalInfo  =JSON.parse(sessionStorage.getItem("Data") ||'')

    if(personalInfo){
      // setTimeout(() => {
      //   // let formLink = sessionStorage.getItem("formlink");

      //   let formLink = 'SHZMOxgC0301';
      //           this._SignupformService
      // .getDataConfig(formLink)
      // .subscribe(
      //   (result: any) => {
      //     // this.isLoaded =true

      //     if (result.status == "200") {


      //       this.languageTokensArray =
      //         result.data.language_details.languageTokens;

      //       this.lang = {};

      //       for (let token of this.languageTokensArray) {
      //         this.lang[token.key] = token.value;

      //         // //this.consoleMessage(this.lang)
      //       }




      //     } else {
      //       this.ShowModelPopup =true
      //       this.ShowModelPopupMessage =result.message ? result.message : result.error,
      //       this.ShowModelPopupTitle ="Info"


      //     }
      //   },
      //   (error) => {
      //     if([0,500,501,502,503].includes(error.status)){
      //       this.ShowModelPopup =true
      //       this.ShowModelPopupMessage ="Sorry, the server is currently undergoing maintenance. Please try again later. We apologize for any inconvenience."
      //       this.ShowModelPopupTitle ="Info"
      //     }
      //     else{

      //       this.ShowModelPopup =true
      //       this.ShowModelPopupMessage =error.error.message
      //       ? error.error.message
      //       : error.error.error,
      //       this.ShowModelPopupTitle ="Info"

      //   }

      //   }
      // )


      // }, 2000);

      this.uploadfile =this.Shared.getMessage()

    this.subscriptiondetails = sessionStorage.getItem("subscriptionresult")

      this.route.queryParams.subscribe(params => {


        if(sessionStorage.getItem("paymentMethod")=="CC"){
          if(params['success']=="True"){

            this.paymenttransactionResponse =true
            this.visible =true
            this.showbuttons =false
            sessionStorage.setItem("failed","")
            let inputData = JSON.parse(sessionStorage.getItem("Data") || '');

            let enrollmentData={
              "customerName":inputData.firstName +""+inputData.lastName,
              "contactNum":inputData.phone_number,
              "dob":inputData.date_of_birth,
              "email":inputData.email,
              "process":"enrollmentSumamry",
              "request":{"paymentMethod":"CC","signature":sessionStorage.getItem("signature") || "","paymentMethodId":params['paymentMethodId']}
            }

                this._SignupformService.savePlan(enrollmentData).subscribe((result: any) => {
                  //this.consoleMessage(result)
                })
          }
          else{
            this.paymenttransactionResponse =true
            this.visible =true
            this.showbuttons =false
            this.failedarray.push("CCFailed")
            sessionStorage.setItem("failed",JSON.stringify(this.failedarray))
            let inputData = JSON.parse(sessionStorage.getItem("Data") || '');

            let enrollmentData={
              "customerName":inputData.firstName +""+inputData.lastName,
              "contactNum":inputData.phone_number,
              "dob":inputData.date_of_birth,
              "email":inputData.email,
              "process":"enrollmentSumamry",
              "request":{"paymentMethod":"CC","signature":sessionStorage.getItem("signature") || "","paymentMethodId":0}
            }

                this._SignupformService.savePlan(enrollmentData).subscribe((result: any) => {
                  //this.consoleMessage(result)
                })
          }


        }

        else{
          this.paymenttransactionResponse =true
          this.visible =true
          this.showbuttons =false
        }


      let personalInfo  =JSON.parse(sessionStorage.getItem("Data") ||'')
      let customerId  =sessionStorage.getItem("customerId")
      // let publicApiKey  =JSON.parse(sessionStorage.getItem("publicApiKey"))
      let billingpostalcode= sessionStorage.getItem("billingpostalcode")
      let billingprovincelist =sessionStorage.getItem("billingprovincelist")
      let billingaddresscity =sessionStorage.getItem("billingaddresscity")
      let addressline2 =sessionStorage.getItem("addressline2")
      let addressline1 =sessionStorage.getItem("addressline1")


      let billingAddressData={}

      if(sessionStorage.getItem("homeAddress")=="true"){
  //       billingAddressData= {
  //         "customer_id": sessionStorage.getItem("customerid"),
  //         "fusebill_customer_id": sessionStorage.getItem("fusebillCustomerId"),
  //         "line1": "",
  //         "line2": "",
  //         "city": "",
  //         "state": "",
  //         "state_id": "",
  //         "country": "",
  //         "country_id": "",
  //         "postal_zip":""
  // }
  billingAddressData={}

      }
      else{
        billingAddressData= JSON.parse(sessionStorage.getItem("billingAddress") || '{}')

      }

      let bankdetailsobj =sessionStorage.getItem("bankDetails")

      // let bankdetails ={
      //   "bankCode": bankdetailsobj[0].bankcode,
      //   "branchCode":this.bankpayForm.get('branchcode').value,
      //   "bankName":this.bankpayForm.get('bankname').value,
      //   "accountNumber":this.bankpayForm.get('accountnumber').value

      // }


      this.countries =JSON.parse(sessionStorage.getItem("countrydetails") ||'{}')

      this.countries.forEach((element:any)=>{
        this.currency =element.currency
        this.currencySymbol =element.currencySymbol
      })
      // parseFloat(sessionStorage.getItem("totalAmount"))
        // "greenshieldRegistration" : true,
    // "equitableRegistration": true,  //false   //true
    // "billing_sameas_homeAddress": sessionStorage.getItem("homeAddress")=="true"?true:false,
    // "billing_address":billingAddressData,
    let summary =JSON.parse(sessionStorage.getItem("plansummary")||'[]')
    var formData: any = new FormData();
    let inputData={

    "customerId": parseInt(sessionStorage.getItem("normalcustomerid")||''),
    "fusebill_customerId": parseInt(sessionStorage.getItem("customerId")||''),
    "fusebill_paymentMethodId": parseInt(params['paymentMethodId']) || 0,

    "enrollmentDate": sessionStorage.getItem("enrollmentdate"),
    "add_address_toTaxation": false,
    "totalAmount":sessionStorage.getItem("totalAmount"),

    "plans":summary,
    "currency": "CAD",
    "currencySymbol": "$",

    "totalAmountUI": sessionStorage.getItem("totalAmountUI"),
    "stateId":parseInt(sessionStorage.getItem("stateid")||''),
    "signature": sessionStorage.getItem("signature") || "",
    "fusebill_paymentMethod":sessionStorage.getItem("paymentMethod")  // "CC"

   }



      // for (const a of summary) {
      //   //this.consoleMessage(typeof a)
      //   //this.consoleMessage(a)


      // }

      let plans:any= []

      this.plansummaryMain = JSON.parse(sessionStorage.getItem("plansummarymain")||'')
      this.plansummaryLIFE = JSON.parse(sessionStorage.getItem("planssummarymainLife")||'')
      let planDetailsArrayBlock =JSON.parse(sessionStorage.getItem("planssummarymainLife")||'[]')




      this.plansummaryLIFE.forEach((element:any,index:number) => {

        element.member.maximumCoverageWithoutEvidence =element.member.defaultRange

        if(element.spouse && element.spouse.maximumCoverageWithoutEvidence){
          element.spouse.maximumCoverageWithoutEvidence =element.spouse.defaultRange
        }
        element['amount'] = element.guaranteedAcceptanceDetails.amount
        element['amountUI'] = "$"+element.guaranteedAcceptanceDetails.amount
        element['planPrice'] = element.guaranteedAcceptanceDetails.amount
        element['total'] =       element.guaranteedAcceptanceDetails.total
        element['totalPrice'] =       element.guaranteedAcceptanceDetails.total
        element['skuTotalPrice'] =       element.guaranteedAcceptanceDetails.total
        element['totalUI'] = "$"+element.guaranteedAcceptanceDetails.total
        element['tax'] = element.guaranteedAcceptanceDetails.tax
        element['taxUI'] = "$"+element.guaranteedAcceptanceDetails.tax
        this.plansummaryLIFEFinal.push(element)

      });
       planDetailsArrayBlock.forEach((element:any,index:number) => {
        if(element.id==13){
          this.iafgPlansAvailability =true
        }
        else{
          this.iafgPlansAvailability =false
        }

      });

      this.plansummary =this.plansummaryMain.concat(this.plansummaryLIFEFinal)
      console.log(this.plansummary)
      this.plansummary = this.plansummary.sort(function(a:any, b:any) {
        return (a.packageId - b.packageId);
      })
      this.plansummary.forEach((element:any) => {

        if(element.packageId ==1){
          this.provisioning =true
        }

     element.totalPrice = this._decimalPipe.transform(element.totalPrice,"1.2-2")?.replace(/,/g, "")
     element.total = this._decimalPipe.transform(element.totalPrice,"1.2-2")?.replace(/,/g, "")
     element.tax = this._decimalPipe.transform(element.tax,"1.2-2")?.replace(/,/g, "")
     element.amount = this._decimalPipe.transform(element.amount,"1.2-2")?.replace(/,/g, "")
      plans.push(element)
      });


   formData.append("plans",JSON.stringify(plans));
   formData.append("customerId", parseInt(sessionStorage.getItem("customerId")||'0'));
   formData.append("fusebill_customerId", parseInt(sessionStorage.getItem("fusebillCustomerId")||'0'));
   formData.append("fusebill_paymentMethodId", parseInt(params['paymentMethodId']) || 0);
   formData.append("enrollmentDate", sessionStorage.getItem("enrollmentdate"));
   formData.append("add_address_toTaxation", false);
   formData.append("totalAmount", sessionStorage.getItem("totalAmount"));
   formData.append("currency", "CAD");
   formData.append("currencySymbol", "$");
   formData.append("totalAmountUI", sessionStorage.getItem("totalAmountUI"));
   formData.append("stateId", parseInt(sessionStorage.getItem("stateid")||'1'));
   formData.append("signature", sessionStorage.getItem("signature") || "");
   formData.append("failed", sessionStorage.getItem("failed") || "[]");
   formData.append("fusebill_paymentMethod", sessionStorage.getItem("paymentMethod"));
   formData.append("timestamp",new Date().getTime());
   formData.append("userKey", sessionStorage.getItem("userKey"));
   formData.append("iafgPlansAvailability",this.iafgPlansAvailability);






  if(sessionStorage.getItem("paymentMethod")=="CC"){

  }else{
    formData.append("padFilename",sessionStorage.getItem("filename")+".pdf");
   formData.append("session",sessionStorage.getItem("session")) || "";
   formData.append("files",this.uploadfile) || "";
  }


  // if(sessionStorage.getItem("Demoform")=="true"){
  //   // this.demovalue="test/ROE"

  //   this.demovalue="v2/subscription"

  // }
  // else{
  //   this.demovalue="v2/subscription"
  // }

  this.demovalue="v4/subscription"

   var requestOptions:any = {

    method: 'POST',
    body: formData,
    redirect: 'follow'

  };


  if(!sessionStorage.getItem("subscriptionCalled") || sessionStorage.getItem("subscriptionCalled")!="1"){
    sessionStorage.setItem("subscriptionCalled","1")

   fetch(environment.app.grp+"api/customer/"+this.demovalue+"", requestOptions)

   .then(response => response.text())

   .then((resposne:any) => {//this.consoleMessage(resposne)
    resposne=JSON.parse(resposne)
     //this.consoleMessage(resposne.status)
     if(resposne.status==200){
      this.customerLink = resposne.customerPortalLink
      this.paymenttransactionResponse =false
      this.paymentSucessResponse = true
      this.visible =false
      this.provisioning =false
     this.showbuttons =true


     this.subscriptiondetails = resposne.data
     if(sessionStorage.getItem("Demoform")=="true"){
      this.responsevalue=resposne.message

    }
    else
    {
      // this.responsevalue=this.lang["enrollment_success"]
      this.responsevalue=`Thank you for your enrollment!  Please check your inbox for your welcome email. Login to your Member Portal using the activation code in the welcome email and update the password.`

      // this.responsevalue=`Thank you for your enrollment!  Please check your inbox for your Welcome email. Login to your Member Portal using the activation code in the Welcome email and update the password.`
     }


     this.showEnrollmentModel =true
     this.showHeaderMessage = 'Enrollment successful'
     this.showModelContent =this.responsevalue

      sessionStorage.clear()

    }

    else{
      this.paymenttransactionResponse =false

      this.showEnrollmentModel =true
      this.showHeaderMessage = resposne.message?resposne.message:resposne.error,
      this.showModelContent =this.responsevalue

       sessionStorage.clear()
       sessionStorage.setItem("paymentfail","1")


    }

  })
  .catch(error => {//this.consoleMessage('error', error)

    if([0,500,501,502,503].includes(error.status)){

      this.ShowModelPopup =true
      this.ShowModelPopupMessage ="Sorry, the server is currently undergoing maintenance. Please try again later. We apologize for any inconvenience.",
      this.ShowModelPopupTitle ="Info"

    }
    else{

      this.ShowModelPopup =true
      this.ShowModelPopupMessage =error.error.message ? error.error.message : error.error.error,
      this.ShowModelPopupTitle ="Info"

}

  });
  }
  else{
    this.paymenttransactionResponse =false
    this.visible =false
    this.provisioning=false

    this.ShowModelPopup =true
      this.ShowModelPopupMessage = 'You are already done with the subscription.Please check your mail or contact admin@groupbenefitz.ca',
      this.ShowModelPopupTitle ="Info"

  }



    })

  }
  else{
    this.paymenttransactionResponse =false
    this.visible =false
    this.provisioning=false

    this.ShowModelPopup =true
    this.ShowModelPopupMessage = 'You are already done with the subscription.Please check your mail or contact admin@groupbenefitz.ca',
    this.ShowModelPopupTitle ="Info"


    // return false
  }

  }
  closeModelpopup(){
    this.showEnrollmentModel =false
    window.open(this.customerLink)
  // console.log(args)
  }
  postAnalyticsData() {
    var pageName = sessionStorage.getItem("pageName");
    if (
      sessionStorage.getItem("ipaddress")

    ) {
      fetch(environment.app.grp + "api/common/analytics", {
        method: "POST",

        body: JSON.stringify({
          ipAddress: sessionStorage.getItem("ipaddress"),
          timestamp: sessionStorage.getItem("timeStamp"),
          pageName: pageName ? pageName : "PersonalInfo",
          gbReferer: window.document.referrer || "",
          pageUrl: window.location.href,
        }),

        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
    }
  }


public gotohomepage(){
  this.router.navigate(['/signup']);
}

public printpage(){
  window.print();
}
onClosedModelpopup(confirmed: boolean) {
  if (confirmed) {
    this.ShowModelPopup = false;
    this.showEnrollmentModel =false;
  } else {
    this.ShowModelPopup = false;
    this.showEnrollmentModel =false;
  }
}
}
