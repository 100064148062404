

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
// import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SignaturePad } from 'angular2-signaturepad';
import { SignupformService } from './../services/signupform.service';

@Component({
  selector: 'app-model-dialog',
  templateUrl: './model-dialog.component.html',
  styleUrl: './model-dialog.component.scss'
})
export class ModelDialogComponent {
  @Input() show = false;
  @Input() showPAD =false
  @Input() message:any;
  @Input() headMessage:any;
  @Output() pdfconfirmed = new EventEmitter<boolean>();
  @Output() padPdfconfirmed = new EventEmitter<boolean>();

  pdfData:any;
  signatureShow = false;
  showPADAggrement =false;
  @ViewChild(SignaturePad) signaturePad!: SignaturePad;

  signaturePadOptions: Object = {
    'minWidth': 1,
    'canvasWidth': 500,
    'canvasHeight': 300
  };
  signatureNeeded: any;
  public failedarray:any =[]
  constructor(public _SignupformService:SignupformService){

  }
    ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
this.pdfData = this.message

  }


ngAfterViewInit() {
  this.signaturePad.set('minWidth', 1); // set szimek/signature_pad options at runtime
  this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
}
drawComplete() {
  // will be notified of szimek/signature_pad's onEnd event
  console.log(this.signaturePad.toDataURL());
}
drawStart() {
  // will be notified of szimek/signature_pad's onBegin event
  console.log('begin drawing');
}

clearSignature() {
  this.signaturePad.clear();
}

saveSignature() {
  const base64Data = this.signaturePad.toDataURL();
  this.signatureNeeded = this.signaturePad.isEmpty();

  
  if(this.signatureNeeded==false){
    sessionStorage.setItem("signature", this.signaturePad.toDataURL("image/png", 1.0));
    this.signatureShow =false
       this.padPdfconfirmed.emit(false);
    this.showPAD = false;
 
  }


}


  
  confirm() {
    this.pdfconfirmed.emit(true);
    this.show = false;
  }

  cancel() {
    this.pdfconfirmed.emit(false);
    this.show = false;
  }

  rejectPADpafaggrement(){
    this.padPdfconfirmed.emit(false);
    this.showPAD = false;
  }
  signaturestartmodel(){
    setTimeout(() => {
      this.signatureShow =true
    }, 100);

    
    // this.padPdfconfirmed.emit(false);
    // this.showPAD = false;
    

  }
}


