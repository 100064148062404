<div class="pt-28 mx-1 sm:mx-12">

  <!-- <div class="container"> -->
  <div class="flex sticky w-full z-1000 bg-white">

    <!-- <div class="w-full"  > -->
      <!-- <button type="submit" class="inline-block relative drop-shadow-lg bg-purple-500 border-purple-300 py-[8px] px-[20px] rounded-lg text-2xl text-center font-bold uppercase before:content-[' '] before:absolute before:z-0 before:bg-purple-500 before:border-purple-300 before:w-[38px] before:h-[38px] before:transform before:rotate-45 before:top-[5px] before:-right-[13px] before:origin-[50%] before:rounded-lg">
        <span>Next</span>
      </button> -->
   <div class="grid grid-cols-1 sticky w-full sm:grid-cols-4">
    <button
      *ngFor="let tab of tabs; let i = index"
      (click)="setActiveTab(i)" [disabled]="activeTab !== i"
      [ngClass]="getTabClass(i)"
      class="flex items-center text-center   justify-center mt-2 h-16 w-full rounded-tr-full rounded-bl-lg  p-0.5 font-bold"
      [class.text-green-700]="activeTab === i"
      [class.animate-pulse]="activeTab === i"
      [class.bg-green-200]="activeTab === i"
      [class.bg-gray-200]="activeTab <i"
      [class.border-transparent]="activeTab !== i">
      {{ tab.title }}
      <!-- [disabled]="activeTab !== i"     -->
      @if(activeTab === i){&nbsp;&nbsp;
      <span class="relative flex justify-end h-12 w-12 mb-16">
        @if(activeTab !== i){
        <svg class="h-28 w-14 text-blue-900"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polyline points="20 6 9 17 4 12" /></svg>

        }
        <!-- <div id="arrowAnim" class="col-span-1">
          <div class="arrowSliding">
            <div class="arrow"></div>
          </div>
          <div class="arrowSliding delay1">
            <div class="arrow"></div>
          </div>
          <div class="arrowSliding delay2">
            <div class="arrow"></div>
          </div>
          <div class="arrowSliding delay3">
            <div class="arrow"></div>
          </div>
        </div> -->
        <!-- <img
          class="h-20 w-20 mb-15"
          src="./../../assets/img/image.gif"
        /> -->

        <!-- <span
          class="animate-ping absolute inline-flex h-8 w-8 mt-9 rounded-full bg-green-400 opacity-75"
        ></span> -->
        <!-- <span class="relative inline-flex rounded-full h-4 w-4 bg-green-700"></span> -->
      </span>
      }
    </button>

    @if(employeeDealingPlansarr.length>0 || payrollCCplansarr.length>0 || iafgplansCheck.length>0){
    <button class="flex items-center justify-center mt-2 h-16 w-full">
      <div class="h-16 w-16">
        <img src="../../assets/img/cart.png" alt="" />
      </div>
      <p class="text-[12px]">
        {{ lang.monthly_premium }} <br />{{ lang.taxesIncluded }} <br>
        <!-- @if(effectiveEmployeeTotalContibution && effectiveEmployeeTotalContibution.length>0){ -->
          <strong>${{ effectiveEmployeeTotalContibution | number: "1.2-2" }}</strong>
          <!-- } -->
      </p>
    </button>
  }
  </div>
    <!-- </div> -->
  </div>

  <div
    class="linescrollbar"
    style="height: calc(100vh - 270px); overflow-y: scroll"
  >
    @if(activeTab === 0)
    {


    <form autocomplete="off" [formGroup]="userForm" (ngSubmit)="onSubmit(userForm.value, 0)">
      <div class="grid grid-cols-2 md:grid-cols-4 gap-2">

@if(employercheck){
        <div class="md:col-span-4 w-full lg:w-1/2">
          <label for="firstname">


            Employer Name<span style="color: red">*</span></label>
          <input
            type="text"
            class="form-control checking-field"
            id="employername"
            placeholder="Employer Name"


            formControlName="employername"

            maxlength="40"
            minlength="2"
          />
          @if (f['employername'].errors &&
          f['employername'].touched) {
          <div class="invalid text-red-500">
            @if (f['employername'].errors['required']) {
            <div>Please enter Employer Name</div>
            }
          </div>
          }

        </div>
      }
        <div class="md:col-span-4 w-full lg:w-1/2">
          <label for="planEnrollDate">
            {{ lang.planEnrollDate }}<span style="color: red">*</span></label
          >
          <select
            class="form-control"
            id="planEnrollmentDate"
            placeholder="Select"
            formControlName="planEnrollmentDate"

          >
            <option value="" label="">{{ lang.select }}</option>
            <option
              *ngFor="let name of configplanEnrollmentDateFull"
              [value]="name"
              [label]="name"
            >
              {{ name }}
            </option>
          </select>
          @if (f['planEnrollmentDate'].errors &&
          f['planEnrollmentDate'].touched) {
          <div class="invalid text-red-500">
            @if (f['planEnrollmentDate'].errors['required']) {
            <div>{{ lang.please_choose_plan_enrolment_date }}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-2">
          <label for="firstname"
            >{{ lang.first_name }}<span style="color: red">*</span></label
          >
          <input
            type="text"
            class="form-control checking-field"
            id="firstName"
            placeholder="{{ lang.first_name }}" [(ngModel)]="applicantFirstName"
            formControlName="firstName" (keypress)="alphabatesOnly($event)"
            maxlength="40"
            minlength="2"
          />
          @if (f['firstName'].errors && f['firstName'].touched) {
          <div class="invalid text-red-500">
            @if (f['firstName'].errors['required']) {
            <div>{{ lang.please_enter_first_name }}</div>
            } @if (f['firstName'].errors['minlength']) {
            <div>{{ lang.first_name_minm_error }}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-2">
          <label for="lastName"
            >{{ lang.last_name }}<span style="color: red">*</span></label
          >
          <input
            type="text"
            class="form-control checking-field"
            id="lastName" [(ngModel)]="applicantLastName"
            placeholder="{{ lang.last_name }}" (keypress)="alphabatesOnly($event)"
            formControlName="lastName"
            maxlength="40"
            minlength="2"
          />
          @if (f['lastName'].errors && f['lastName'].touched) {
          <div class="invalid text-red-500">
            @if (f['lastName'].errors['required']) {
            <div>{{ lang.please_enter_last_name }}</div>
            } @if (f['lastName'].errors['minlength']) {
            <div>{{ lang.last_name_minm_error }}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-2">
          <label for="maidenname"
            >{{ lang.email }}<span style="color: red">*</span></label
          >
          <input
            type="email"
            class="form-control checking-field"
            id="email" [(ngModel)]="applicantEmail"
            placeholder="{{ lang.email }}"
            formControlName="email"
          />
          @if (f['email'].errors && f['email'].touched) {
          <div class="invalid text-red-500">
            @if (f['email'].errors['required']) {
            <div>{{ lang.please_enter_email }}</div>
            } @if (f['email'].errors['pattern']) {
            <div>{{ lang.please_enter_valid_email }}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-2">
          <label for="phone_number"
            >{{ lang.phoneNumber }}<span style="color: red">*</span></label
          >
          <input
            type="tel"
            class="form-control checking-field"
            id="phone_number" (change)="phoneFormat($event)"
            [(ngModel)]="applicantPhoneNumber"
            placeholder="+1 (123) 456-7890"
            formControlName="phone_number"
          />
          @if (f['phone_number'].errors && f['phone_number'].touched) {
          <div class="invalid text-red-500">
            @if (f['phone_number'].errors['required']) {
            <div>{{ lang.please_enter_phone_number }}</div>
            } @if (f['phone_number'].errors['minlength']) {
            <div>{{ lang.phone_number_is_minimum_of_10_digits }}</div>
            }
            @if (f['phone_number'].errors['maxlength']) {
              <div>{{ lang.phone_number_is_minimum_of_10_digits }}</div>
              }
          </div>
          }
        </div>
        <div
          class="md:col-span-4 w-full lg:w-1/2 underline decoration-indigo-700 font-bold"
        >
          {{ lang.homeMailingAddress }}
        </div>

        <div class="col-span-2">
          <label for="street_address_line1"
            >{{ lang.streetAddressLine
            }}<span style="color: red">*</span></label
          >
          <input
            type="text"
            class="form-control checking-field"
            id="street_address_line1"
            placeholder="{{ lang.streetAddressLine }}"
            formControlName="street_address_line1"
          />
          @if (f['street_address_line1'].errors &&
          f['street_address_line1'].touched) {
          <div class="invalid text-red-500">
            @if (f['street_address_line1'].errors['required']) {
            <div>{{ lang.please_enter_street_address }}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-2">
          <label for="street_address_line2"
            >{{ lang.streetAddressLine2 }}<span></span
          ></label>
          <input
            type="text"
            class="form-control"
            id="street_address_line2"
            placeholder="{{ lang.streetAddressLine2 }}"
            formControlName="street_address_line2"
          />
        </div>
        <div class="md:col-span-4 w-full lg:w-1/2">
          <label for="apt">{{ lang.apartment_suite_number }}</label>
          <input
            type="text"
            class="form-control"
            id="apt"
            placeholder="{{ lang.apartment_suite_number }}"
            formControlName="apt"
          />
        </div>

        <div class="col-span-1">
          <label for="city"
            >{{ lang.city }}<span style="color: red">*</span></label
          >
          <input
            type="text"
            class="form-control checking-field"
            id="city"
            placeholder="{{ lang.city }}"
            formControlName="city"
          />
          @if (f['city'].errors && f['city'].touched) {
          <div class="invalid text-red-500">
            @if (f['city'].errors['required']) {
            <div>{{ lang.please_enter_city }}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-1">
          <label for="Province"
            >Province of Residence<span style="color: red">*</span></label
          >

          <select
            class="form-control"
            id="Province"
            placeholder=""
            formControlName="province"
            (change)="provincelist($event)"
          >
            <option value="" disabled>{{ lang.select }}</option>

            <option
              *ngFor="let name of configprovinceres"
              [value]="name.shortName"
            >
              {{ name.shortName }}
            </option>
          </select>

          @if (f['province'].errors && f['province'].touched) {

          <div class="invalid text-red-500">
            @if (f['province'].errors['required']) {
            <div>{{ lang.please_select_province }}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-1">
          <label for="postal_code"
            >{{ lang.postalCode }}<span style="color: red">*</span></label
          >
          <input
            type="text"
            class="form-control checking-field"
            id="postal_code"
            placeholder="{{ lang.postalCode }}"
            formControlName="postal_code"
            (input)="changeTextToUppercase('postal_code', $event)"
          />

          @if (f['postal_code'].errors && f['postal_code'].touched) {
          <div class="invalid text-red-500">
            @if (f['postal_code'].errors['required']) {
            <div>{{ lang.please_enter_postal_code }}</div>
            }
          </div>
          } @if (invalidPostalCodeForProvince==true) {
          <div class="invalid text-red-500">
            {{ lang.please_enter_valid_postal_code }}
          </div>
          }
        </div>


        <div class="col-span-1">
            <label for="Province">Province of Employment<span style="color: red">*</span></label>

            <select
              class="form-control"
              id="Province"
              placeholder=""

              (change)="workingProvincelist($event)"
              formControlName="workingProvince">
              <option value="">{{lang.select}}</option>

              <option
                *ngFor="let name of configpworkingrovinceres"
                [value]="name.shortName"
              >
                {{ name.shortName }}
              </option>
            </select>



            @if (f['workingProvince'].errors && f['workingProvince'].touched) {

              <div class="workingProvince text-red-500">
                @if (f['workingProvince'].errors['required']) {
                <div>{{ lang.please_select_province }}</div>
                }
              </div>
              }

        </div>
        <div class="col-span-1">
          <label for="date_of_birth"
            >{{ lang.dateOfBirth }}<span style="color: red">*</span></label
          >

          <input type="text" (paste)="(false)"
          class="form-control" formControlName="date_of_birth"
          [minDate]="minDate"
          [maxDate]="maxDate"
          #dp
          [bsConfig]="{dateInputFormat: 'MM-DD-YYYY'}"
          placeholder="MM-DD-YYYY"
          bsDatepicker (blur)="checkDateOfBirth($event)" (input)="checkDateOfBirth($event)"
          (keypress)="numbersOnly($event)"/>

          <!-- <input id="datepicker-autohide"  formControlName="date_of_birth" datepicker datepicker-autohide type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  placeholder="MM-DD-YYYY"> -->

          @if (f['date_of_birth'].errors && f['date_of_birth'].touched) {
          <div class="invalid text-red-500">
            @if (f['date_of_birth'].errors['required']) {
            <div>{{ lang.please_choose_date_of_birth }}</div>
            }
          </div>
          }
        </div>

        <div class="col-span-1">
          <label for="gender"
            >{{ lang.gender }}<span style="color: red">*</span></label
          >
          <!--  -->
          <select
            class="form-control"
            id="gender"
            placeholder=""
            formControlName="gender"
          >
            <option value="">{{ lang.select }}</option>
            <option value="Male">{{ lang.male }}</option>
            <option value="Female">{{ lang.female }}</option>
            <option value="Non-Binary">{{ lang.nonBinary }}</option>
            <option value="Undisclosed">{{ lang.unDisclosed }}</option>
          </select>

          @if (f['gender'].errors && f['gender'].touched) {
          <div class="invalid text-red-500">
            @if (f['gender'].errors['required']) {
            <div>{{ lang.please_select_gender }}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-1">
          <label for="date_of_hiring"
            >{{ lang.dateOfHire }}<span style="color: red">*</span></label
          >
          <!-- [minDate]="minDateHire"
          [maxDate]="maxDateHire" -->


          <input type="text" (paste)="(false)"
          class="form-control" formControlName="date_of_hiring"

          #dp
          [bsConfig]="{dateInputFormat: 'MM-DD-YYYY'}"
          placeholder="MM-DD-YYYY" [(ngModel)]="applicantDateofhire"
          bsDatepicker (blur)="checkDateOfHire($event)" (input)="checkDateOfHire($event)"
          (keypress)="numbersOnly($event)"/>
          @if (f['date_of_hiring'].errors && f['date_of_hiring'].touched) {
          <div class="invalid text-red-500">
            @if (f['date_of_hiring'].errors['required']) {
            <div>{{ lang.please_choose_date_of_hire }}</div>
            }
          </div>
          }
        </div>

        <div class="col-span-1">
          <label for="company_name"
            >{{ lang.companyName }}<span style="color: red">*</span></label
          >
          <input
            type="text"
            class="form-control checking-field"
            id="company_name"
            placeholder="{{ lang.companyName }}"
            [ngModel]="companyName" [readonly]="true"
            formControlName="company_name"
          />
          @if (f['company_name'].errors && f['company_name'].touched) {
          <div class="invalid text-red-500">
            @if (f['company_name'].errors['required']) {
            <div>{{ lang.please_enter_company_name }}</div>
            }
          </div>
          }
        </div>

        <div class="col-span-1">
          <label for="job_title"
            >{{ lang.jobTitle }}<span style="color: red">*</span></label
          >
          <input
            type="text"
            class="form-control checking-field"
            id="job_title"
            placeholder="{{ lang.jobTitle }}"
            formControlName="job_title"
          />
          @if (f['job_title'].errors && f['job_title'].touched) {
          <div class="invalid text-red-500">
            @if (f['job_title'].errors['required']) {
            <div>{{ lang.please_enter_job_title }}</div>
            }
          </div>
          }
        </div>

        <div class="col-span-2 md:col-span-4 w-full lg:w-full my-2">
          <hr class="h-px bg-indigo-700 border-y-2 dark:bg-gray-700" />
        </div>
        <div class="col-span-2">
          <label for="working_20hours">
            {{ lang.moreThan20Hours }}
          </label>
          <div class="flex items-center space-x-2">
            <input
              type="radio"
              id="true"
              formControlName="working_20hours"
              value="true"
              class="form-radio text-blue-600"
              (change)="workingHoursCheck($event)"
            />
            <label for="true" class="text-gray-700">Yes</label>
          </div>

          <div class="flex items-center space-x-2">
            <input
              type="radio"
              id="false"
              formControlName="working_20hours"
              value="false"
              class="form-radio text-blue-600"
              (change)="workingHoursCheck($event)"
            />
            <label for="false" class="text-gray-700">No</label>
          </div>
          @if (f['working_20hours'].errors && f['working_20hours'].touched) {
          <div class="invalid text-red-500">
            @if (f['working_20hours'].errors['required']) {
            <div>{{ lang.workingHours_message }}</div>
            } @if (f['working_20hours'].errors['invalidhoursselection']) {
            <div>{{ lang.workingHours_message }}</div>
            }
          </div>
          } @if(userForm.get('working_20hours')?.value === 'true'){
          <div
            class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 gap-4"
          >
            <div class="col-span-1 w-1/2">
              <label for="hours_per_week"
                >{{ lang.hours_per_week
                }}<span style="color: red">*</span></label
              >
              <input
                class="form-control checking field"
                F
                type="number"
                name="hours_per_week"
                id="hours_per_week"
                placeholder="Hours per week"
                value="40"
                name="hours_per_week"
                formControlName="hours_per_week"
                maxlength="2"
                min="20"
                max="80"
              />
              @if (f['hours_per_week'].errors && f['hours_per_week'].touched) {
              <div class="invalid text-red-500">
                @if (f['hours_per_week'].errors['required']) {
                <div>{{ lang.please_select_working_hours_per_week }}</div>
                }
                @if (f['hours_per_week'].errors['mininvalidhours']) {
                  <div>{{ lang.hours_per_week_should_be_more_than_20 }}</div>
                  }
                  @if (f['hours_per_week'].errors['maxinvalidhours']) {
                    <div>{{ lang.hours_per_week_should_be_less_than_80 }}</div>
                    }
              </div>
              }
            </div>
          </div>
          }
        </div>

        <div class="col-span-2" id="box">
          <label class="lineheight">
            {{ lang.provinceHealthCover }}
          </label>
          <div class="flex items-center space-x-2">
            <input
              type="radio"
              id="true"
              formControlName="provincial_health_coverage"
              value="true"
              class="form-radio text-blue-600"  (change)="provincialCoverageHealth($event)"
            />
            <label for="true" class="text-gray-700">Yes</label>
          </div>

          <div class="flex items-center space-x-2">
            <input
              type="radio"
              id="false"
              formControlName="provincial_health_coverage"
              value="false"
              class="form-radio text-blue-600"  (change)="provincialCoverageHealth($event)"
            />
            <label for="false" class="text-gray-700">No</label>
          </div>
          @if (f['provincial_health_coverage'].errors &&
          f['provincial_health_coverage'].touched) {
          <div class="invalid text-red-500">
            @if (f['provincial_health_coverage'].errors['required']) {
            <div>{{ lang.not_eligible_health_card_province }}</div>
            }
          </div>
          }
          @if (userForm.get('provincial_health_coverage')?.value === 'false'){
          <div class="col-span-1">
            <label
              >Date of Arrival in Canada<span style="color: red">*</span></label
            >


            <input type="text"
          class="form-control" formControlName="dateOfArrival"

          #dp
          [bsConfig]="{dateInputFormat: 'MM-DD-YYYY'}"
          placeholder="MM-DD-YYYY"
          bsDatepicker (blur)="checkDateOfArrival($event)"
          (keypress)="numbersOnly($event)"/>

            @if (f['dateOfArrival'].errors && f['dateOfArrival'].touched) {
            <div class="invalid text-red-500">
              @if (f['dateOfArrival'].errors['required']) {
              <div>Please select date of arrival in Canada</div>
              }
            </div>
            }
          </div>
          <div class="col-span-1">
            <label
              >Is the employee a landed immigrant?<span style="color: red"
                >*</span
              ></label
            >

            <div class="flex items-center space-x-2">
              <input
                type="radio"
                id="true"
                formControlName="isEmployeeImigrant"
                value="true"
                class="form-radio text-blue-600"
                (change)="landedimmigrantStatus($event)"
              />
              <label for="true" class="text-gray-700">Yes</label>
            </div>

            <div class="flex items-center space-x-2">
              <input
                type="radio"
                id="false"
                formControlName="isEmployeeImigrant"
                value="false"
                class="form-radio text-blue-600"
                (change)="landedimmigrantStatus($event)"
              />
              <label for="false" class="text-gray-700">No</label>
            </div>

            @if (f['isEmployeeImigrant'].errors &&
            f['isEmployeeImigrant'].touched) {
            <div class="invalid text-red-500">
              @if (f['isEmployeeImigrant'].errors['required']) {
              <div>Please choose employee landed immigrant status</div>
              }
            </div>
            }
          </div>

          @if(userForm.get('isEmployeeImigrant')?.value === 'false'){
          <div class="col-span-1">
            <label
              >Will he/she be applying for landed status?<span
                style="color: red"
                >*</span
              ></label
            >

            <div class="flex items-center space-x-2">
              <input
                type="radio"
                id="true"
                formControlName="applylandedstaus"
                value="true"
                class="form-radio text-blue-600"

              />
              <label for="true" class="text-gray-700">Yes</label>
            </div>

            <div class="flex items-center space-x-2">
              <input
                type="radio"
                id="false"
                formControlName="applylandedstaus"
                value="false"
                class="form-radio text-blue-600"

              />
              <label for="false" class="text-gray-700">No</label>
            </div>

            @if (f['applylandedstaus'].errors && f['applylandedstaus'].touched)
            {
            <div class="invalid text-red-500">
              @if (f['applylandedstaus'].errors['required']) {
              <div>Please choose landed status</div>
              }
            </div>
            }
          </div>
          }

          <div class="col-span-1">
            <label>Country of Origin<span style="color: red">*</span></label>

            <select
              class="form-control"
              id="countryOrigin"
              placeholder="Select"
              formControlName="countryOrigin"
            >
              <option value="" label="">{{ lang.select }}</option>
              <!-- <option value="08-11-2022" label="">08-11-2022</option> -->
              <option value="Afghanistan">Afghanistan</option>
              <option value="Åland Islands">Åland Islands</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="American Samoa">American Samoa</option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Anguilla">Anguilla</option>
              <option value="Antarctica">Antarctica</option>
              <option value="Antigua and Barbuda">Antigua and Barbuda</option>
              <option value="Argentina">Argentina</option>
              <option value="Armenia">Armenia</option>
              <option value="Aruba">Aruba</option>
              <option value="Australia">Australia</option>
              <option value="Austria">Austria</option>
              <option value="Azerbaijan">Azerbaijan</option>
              <option value="Bahamas">Bahamas</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="Barbados">Barbados</option>
              <option value="Belarus">Belarus</option>
              <option value="Belgium">Belgium</option>
              <option value="Belize">Belize</option>
              <option value="Benin">Benin</option>
              <option value="Bermuda">Bermuda</option>
              <option value="Bhutan">Bhutan</option>
              <option value="Bolivia">Bolivia</option>
              <option value="Bosnia and Herzegovina">
                Bosnia and Herzegovina
              </option>
              <option value="Botswana">Botswana</option>
              <option value="Bouvet Island">Bouvet Island</option>
              <option value="Brazil">Brazil</option>
              <option value="British Indian Ocean Territory">
                British Indian Ocean Territory
              </option>
              <option value="Brunei Darussalam">Brunei Darussalam</option>
              <option value="Bulgaria">Bulgaria</option>
              <option value="Burkina Faso">Burkina Faso</option>
              <option value="Burundi">Burundi</option>
              <option value="Cambodia">Cambodia</option>
              <option value="Cameroon">Cameroon</option>
              <option value="Canada">Canada</option>
              <option value="Cape Verde">Cape Verde</option>
              <option value="Cayman Islands">Cayman Islands</option>
              <option value="Central African Republic">
                Central African Republic
              </option>
              <option value="Chad">Chad</option>
              <option value="Chile">Chile</option>
              <option value="China">China</option>
              <option value="Christmas Island">Christmas Island</option>
              <option value="Cocos (Keeling) Islands">
                Cocos (Keeling) Islands
              </option>
              <option value="Colombia">Colombia</option>
              <option value="Comoros">Comoros</option>
              <option value="Congo">Congo</option>
              <option value="Congo, The Democratic Republic of The">
                Congo, The Democratic Republic of The
              </option>
              <option value="Cook Islands">Cook Islands</option>
              <option value="Costa Rica">Costa Rica</option>
              <option value="Cote D'ivoire">Cote D'ivoire</option>
              <option value="Croatia">Croatia</option>
              <option value="Cuba">Cuba</option>
              <option value="Cyprus">Cyprus</option>
              <option value="Czech Republic">Czech Republic</option>
              <option value="Denmark">Denmark</option>
              <option value="Djibouti">Djibouti</option>
              <option value="Dominica">Dominica</option>
              <option value="Dominican Republic">Dominican Republic</option>
              <option value="Ecuador">Ecuador</option>
              <option value="Egypt">Egypt</option>
              <option value="El Salvador">El Salvador</option>
              <option value="Equatorial Guinea">Equatorial Guinea</option>
              <option value="Eritrea">Eritrea</option>
              <option value="Estonia">Estonia</option>
              <option value="Ethiopia">Ethiopia</option>
              <option value="Falkland Islands (Malvinas)">
                Falkland Islands (Malvinas)
              </option>
              <option value="Faroe Islands">Faroe Islands</option>
              <option value="Fiji">Fiji</option>
              <option value="Finland">Finland</option>
              <option value="France">France</option>
              <option value="French Guiana">French Guiana</option>
              <option value="French Polynesia">French Polynesia</option>
              <option value="French Southern Territories">
                French Southern Territories
              </option>
              <option value="Gabon">Gabon</option>
              <option value="Gambia">Gambia</option>
              <option value="Georgia">Georgia</option>
              <option value="Germany">Germany</option>
              <option value="Ghana">Ghana</option>
              <option value="Gibraltar">Gibraltar</option>
              <option value="Greece">Greece</option>
              <option value="Greenland">Greenland</option>
              <option value="Grenada">Grenada</option>
              <option value="Guadeloupe">Guadeloupe</option>
              <option value="Guam">Guam</option>
              <option value="Guatemala">Guatemala</option>
              <option value="Guernsey">Guernsey</option>
              <option value="Guinea">Guinea</option>
              <option value="Guinea-bissau">Guinea-bissau</option>
              <option value="Guyana">Guyana</option>
              <option value="Haiti">Haiti</option>
              <option value="Heard Island and Mcdonald Islands">
                Heard Island and Mcdonald Islands
              </option>
              <option value="Holy See (Vatican City State)">
                Holy See (Vatican City State)
              </option>
              <option value="Honduras">Honduras</option>
              <option value="Hong Kong">Hong Kong</option>
              <option value="Hungary">Hungary</option>
              <option value="Iceland">Iceland</option>
              <option value="India">India</option>
              <option value="Indonesia">Indonesia</option>
              <option value="Iran, Islamic Republic of">
                Iran, Islamic Republic of
              </option>
              <option value="Iraq">Iraq</option>
              <option value="Ireland">Ireland</option>
              <option value="Isle of Man">Isle of Man</option>
              <option value="Israel">Israel</option>
              <option value="Italy">Italy</option>
              <option value="Jamaica">Jamaica</option>
              <option value="Japan">Japan</option>
              <option value="Jersey">Jersey</option>
              <option value="Jordan">Jordan</option>
              <option value="Kazakhstan">Kazakhstan</option>
              <option value="Kenya">Kenya</option>
              <option value="Kiribati">Kiribati</option>
              <option value="Korea, Democratic People's Republic of">
                Korea, Democratic People's Republic of
              </option>
              <option value="Korea, Republic of">Korea, Republic of</option>
              <option value="Kuwait">Kuwait</option>
              <option value="Kyrgyzstan">Kyrgyzstan</option>
              <option value="Lao People's Democratic Republic">
                Lao People's Democratic Republic
              </option>
              <option value="Latvia">Latvia</option>
              <option value="Lebanon">Lebanon</option>
              <option value="Lesotho">Lesotho</option>
              <option value="Liberia">Liberia</option>
              <option value="Libyan Arab Jamahiriya">
                Libyan Arab Jamahiriya
              </option>
              <option value="Liechtenstein">Liechtenstein</option>
              <option value="Lithuania">Lithuania</option>
              <option value="Luxembourg">Luxembourg</option>
              <option value="Macao">Macao</option>
              <option value="Macedonia, The Former Yugoslav Republic of">
                Macedonia, The Former Yugoslav Republic of
              </option>
              <option value="Madagascar">Madagascar</option>
              <option value="Malawi">Malawi</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Maldives">Maldives</option>
              <option value="Mali">Mali</option>
              <option value="Malta">Malta</option>
              <option value="Marshall Islands">Marshall Islands</option>
              <option value="Martinique">Martinique</option>
              <option value="Mauritania">Mauritania</option>
              <option value="Mauritius">Mauritius</option>
              <option value="Mayotte">Mayotte</option>
              <option value="Mexico">Mexico</option>
              <option value="Micronesia, Federated States of">
                Micronesia, Federated States of
              </option>
              <option value="Moldova, Republic of">Moldova, Republic of</option>
              <option value="Monaco">Monaco</option>
              <option value="Mongolia">Mongolia</option>
              <option value="Montenegro">Montenegro</option>
              <option value="Montserrat">Montserrat</option>
              <option value="Morocco">Morocco</option>
              <option value="Mozambique">Mozambique</option>
              <option value="Myanmar">Myanmar</option>
              <option value="Namibia">Namibia</option>
              <option value="Nauru">Nauru</option>
              <option value="Nepal">Nepal</option>
              <option value="Netherlands">Netherlands</option>
              <option value="Netherlands Antilles">Netherlands Antilles</option>
              <option value="New Caledonia">New Caledonia</option>
              <option value="New Zealand">New Zealand</option>
              <option value="Nicaragua">Nicaragua</option>
              <option value="Niger">Niger</option>
              <option value="Nigeria">Nigeria</option>
              <option value="Niue">Niue</option>
              <option value="Norfolk Island">Norfolk Island</option>
              <option value="Northern Mariana Islands">
                Northern Mariana Islands
              </option>
              <option value="Norway">Norway</option>
              <option value="Oman">Oman</option>
              <option value="Pakistan">Pakistan</option>
              <option value="Palau">Palau</option>
              <option value="Palestinian Territory, Occupied">
                Palestinian Territory, Occupied
              </option>
              <option value="Panama">Panama</option>
              <option value="Papua New Guinea">Papua New Guinea</option>
              <option value="Paraguay">Paraguay</option>
              <option value="Peru">Peru</option>
              <option value="Philippines">Philippines</option>
              <option value="Pitcairn">Pitcairn</option>
              <option value="Poland">Poland</option>
              <option value="Portugal">Portugal</option>
              <option value="Puerto Rico">Puerto Rico</option>
              <option value="Qatar">Qatar</option>
              <option value="Reunion">Reunion</option>
              <option value="Romania">Romania</option>
              <option value="Russian Federation">Russian Federation</option>
              <option value="Rwanda">Rwanda</option>
              <option value="Saint Helena">Saint Helena</option>
              <option value="Saint Kitts and Nevis">
                Saint Kitts and Nevis
              </option>
              <option value="Saint Lucia">Saint Lucia</option>
              <option value="Saint Pierre and Miquelon">
                Saint Pierre and Miquelon
              </option>
              <option value="Saint Vincent and The Grenadines">
                Saint Vincent and The Grenadines
              </option>
              <option value="Samoa">Samoa</option>
              <option value="San Marino">San Marino</option>
              <option value="Sao Tome and Principe">
                Sao Tome and Principe
              </option>
              <option value="Saudi Arabia">Saudi Arabia</option>
              <option value="Senegal">Senegal</option>
              <option value="Serbia">Serbia</option>
              <option value="Seychelles">Seychelles</option>
              <option value="Sierra Leone">Sierra Leone</option>
              <option value="Singapore">Singapore</option>
              <option value="Slovakia">Slovakia</option>
              <option value="Slovenia">Slovenia</option>
              <option value="Solomon Islands">Solomon Islands</option>
              <option value="Somalia">Somalia</option>
              <option value="South Africa">South Africa</option>
              <option value="South Georgia and The South Sandwich Islands">
                South Georgia and The South Sandwich Islands
              </option>
              <option value="Spain">Spain</option>
              <option value="Sri Lanka">Sri Lanka</option>
              <option value="Sudan">Sudan</option>
              <option value="Suriname">Suriname</option>
              <option value="Svalbard and Jan Mayen">
                Svalbard and Jan Mayen
              </option>
              <option value="Swaziland">Swaziland</option>
              <option value="Sweden">Sweden</option>
              <option value="Switzerland">Switzerland</option>
              <option value="Syrian Arab Republic">Syrian Arab Republic</option>
              <option value="Taiwan">Taiwan</option>
              <option value="Tajikistan">Tajikistan</option>
              <option value="Tanzania, United Republic of">
                Tanzania, United Republic of
              </option>
              <option value="Thailand">Thailand</option>
              <option value="Timor-leste">Timor-leste</option>
              <option value="Togo">Togo</option>
              <option value="Tokelau">Tokelau</option>
              <option value="Tonga">Tonga</option>
              <option value="Trinidad and Tobago">Trinidad and Tobago</option>
              <option value="Tunisia">Tunisia</option>
              <option value="Turkey">Turkey</option>
              <option value="Turkmenistan">Turkmenistan</option>
              <option value="Turks and Caicos Islands">
                Turks and Caicos Islands
              </option>
              <option value="Tuvalu">Tuvalu</option>
              <option value="Uganda">Uganda</option>
              <option value="Ukraine">Ukraine</option>
              <option value="United Arab Emirates">United Arab Emirates</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="United States">United States</option>
              <option value="United States Minor Outlying Islands">
                United States Minor Outlying Islands
              </option>
              <option value="Uruguay">Uruguay</option>
              <option value="Uzbekistan">Uzbekistan</option>
              <option value="Vanuatu">Vanuatu</option>
              <option value="Venezuela">Venezuela</option>
              <option value="Viet Nam">Viet Nam</option>
              <option value="Virgin Islands, British">
                Virgin Islands, British
              </option>
              <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
              <option value="Wallis and Futuna">Wallis and Futuna</option>
              <option value="Western Sahara">Western Sahara</option>
              <option value="Yemen">Yemen</option>
              <option value="Zambia">Zambia</option>
              <option value="Zimbabwe">Zimbabwe</option>
            </select>
            @if (f['countryOrigin'].errors && f['countryOrigin'].touched) {
            <div class="invalid text-red-500">
              @if (f['countryOrigin'].errors['required']) {
              <div>Please select country of origin.</div>
              }
            </div>
            }
          </div>

          <div class="col-span-1">
            <label
              >Is coverage required for the employee?<span style="color: red"
                >*</span
              ></label
            >

            <div class="flex items-center space-x-2">
              <input
                type="radio"
                id="true"
                formControlName="coverageRequiredOfEmployee"
                value="true"
                class="form-radio text-blue-600"

              />
              <label for="true" class="text-gray-700">Yes</label>
            </div>

            <div class="flex items-center space-x-2">
              <input
                type="radio"
                id="false"
                formControlName="coverageRequiredOfEmployee"
                value="false"
                class="form-radio text-blue-600"

              />
              <label for="false" class="text-gray-700">No</label>
            </div>
            @if (f['coverageRequiredOfEmployee'].errors &&
            f['coverageRequiredOfEmployee'].touched) {
            <div class="invalid text-red-500">
              @if (f['coverageRequiredOfEmployee'].errors['required']) {
              <div>Please select coverage required for employee.</div>
              }
            </div>
            }
          </div>

          }
        </div>
        <div class="col-span-2 md:col-span-4 w-full lg:w-full">
          <hr class="h-px bg-indigo-700 border-y-2 dark:bg-gray-700" />
        </div>
        <div class="col-span-2" id="box">
          <!-- <h4 class="spouseinfo">{{lang.spouseinfo}}</h4> -->
          <div
            class="col-span-2 w-full underline decoration-indigo-700 font-bold"
          >
            {{ lang.spouseinfo }}
          </div>
          <label class="lineheight">
            {{ lang.checkSpouse }}
          </label>
          <div class="flex items-center space-x-2">
            <input
              type="radio"
              id="true"
              formControlName="having_spouse"
              value="true"
              class="form-radio text-blue-600"
              (change)="spouseInfoCheck($event)"
            />
            <label for="true" class="text-gray-700">Yes</label>
          </div>

          <div class="flex items-center space-x-2">
            <input
              type="radio"
              id="false"
              formControlName="having_spouse"
              value="false"
              class="form-radio text-blue-600"
              (change)="spouseInfoCheck($event)"
            />
            <label for="false" class="text-gray-700">No</label>
          </div>
          @if (f['having_spouse'].errors && f['having_spouse'].touched) {
          <div class="invalid text-red-500">
            @if (f['having_spouse'].errors['required']) {
            <div>{{ lang.please_choose_spouse_status }}</div>
            }
          </div>
          } @if(userForm.get('having_spouse')?.value === 'true'){
          <div
            class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 gap-4"
          >
            <div class="col-span-1">
              <label for="planEnrollDate">
                {{ lang.first_name }}<span style="color: red">*</span></label
              >
              <input
                class="form-control"
                id="firstname"
                placeholder="{{spouseplaceholdername }}" (keypress)="alphabatesOnly($event)"
                formControlName="spousefirstname"
              />
              @if (f['spousefirstname'].errors && f['spousefirstname'].touched)
              {
              <div class="invalid text-red-500">
                @if (f['spousefirstname'].errors['required']) {
                <div>{{ lang.please_enter_spouse_first_name }}</div>
                } @if (f['spousefirstname'].errors['minlength']) {
                <div>{{ lang.firstName_minm_error }}</div>
                }
              </div>
              }
            </div>
            <div class="col-span-1">
              <label for="planEnrollDate">
                {{ lang.last_name }}<span style="color: red">*</span></label
              >
              <input
                class="form-control"
                id="firstname"
                placeholder="{{ lang.last_name }}" (keypress)="alphabatesOnly($event)"
                formControlName="spouselastname"
              />
              @if (f['spouselastname'].errors && f['spouselastname'].touched) {
              <div class="invalid text-red-500">
                @if (f['spouselastname'].errors['required']) {
                <div>{{ lang.please_enter_spouse_last_name }}</div>
                } @if (f['spouselastname'].errors['minlength']) {
                <div>{{ lang.lastName_minm_error }}</div>
                }
              </div>
              }
            </div>
            <div class="col-span-1">
              <label for="planEnrollDate">
                {{ lang.gender }}<span style="color: red">*</span></label
              >
              <select
                class="form-control"
                id="spousegender"
                placeholder=""
                formControlName="spousegender"
              >
                <option value="" disabled>{{ lang.select }}</option>
                <option value="Male">{{ lang.male }}</option>
                <option value="Female">{{ lang.female }}</option>
                <option value="Non-Binary">{{ lang.nonBinary }}</option>
                <option value="Undisclosed">{{ lang.unDisclosed }}</option>
              </select>
              @if (f['spousegender'].errors && f['spousegender'].touched) {
              <div class="invalid text-red-500">
                @if (f['spousegender'].errors['required']) {
                <div>{{ lang.please_select_gender }}</div>
                }
              </div>
              }
            </div>
            <div class="col-span-1">
              <label for="planEnrollDate">
                {{ lang.dateOfBirth }}<span style="color: red">*</span></label
              >

              <input type="text" (paste)="(false)"
          class="form-control" formControlName="spousedateofbirth"
          [minDate]="minDate"
          [maxDate]="maxDate"
          #dp
          [bsConfig]="{dateInputFormat: 'MM-DD-YYYY'}"
          placeholder="MM-DD-YYYY"
          bsDatepicker (blur)="checkSpouseDateofBirth($event)"
          (keypress)="numbersOnly($event)"/>

              @if (f['spousedateofbirth'].errors &&
              f['spousedateofbirth'].touched) {
              <div class="invalid text-red-500">
                @if (f['spousedateofbirth'].errors['required']) {
                <div>{{ lang.please_choose_date_of_birth }}</div>
                }
              </div>
              }
            </div>
            <div class="col-span-1">
              <label class="lineheight">
                {{ lang.spouseCover }}
              </label>
              <div class="flex items-center space-x-2">
                <input
                  type="radio"
                  id="true"
                  formControlName="spousehealthcard"
                  value="true"
                  class="form-radio text-blue-600"
                  (click)="spousehealthcard($event)"
                />
                <label for="true" class="text-gray-700">Yes</label>
              </div>

              <div class="flex items-center space-x-2">
                <input
                  type="radio"
                  id="false"
                  formControlName="spousehealthcard"
                  value="false"
                  class="form-radio text-blue-600"
                  (click)="spousehealthcard($event)"
                />
                <label for="false" class="text-gray-700">No</label>
              </div>
              @if (f['spousehealthcard'].errors &&
              f['spousehealthcard'].touched) {
              <div class="invalid text-red-500">
                @if (f['spousehealthcard'].errors['required']) {
                <div>{{ lang.please_choose_spouse_insurance_status }}</div>
                }
              </div>
              }
              @if(userForm.get('spousehealthcard')?.value === 'true'){
              <div class="col-span-1">
                <label for="carrierName"
                  >{{ lang.name_of_the_carrier
                  }}<span style="color: red">*</span></label
                >
                <input
                  type="text"
                  class="form-control checking-field"
                  id="spousenameofthecarrier"
                  placeholder="{{ lang.name_of_the_carrier }}"
                  formControlName="Spousenameofthecarrier"

                />

                @if (f['Spousenameofthecarrier'].errors &&
                f['Spousenameofthecarrier'].touched) {
                <div class="invalid text-red-500">
                  @if (f['Spousenameofthecarrier'].errors['required']) {
                  <div>{{ lang.please_enter_name_of_carrier }}</div>
                  }
                </div>
                }



              </div>
              <div class="col-span-1">
                <label for="coverage">{{lang.cob_coverage }}<span style="color: red">*</span></label
              >

              <select
                    class="form-control"
                    id="spousecob"
                    placeholder="Select"
                    formControlName="spousecob"

                  >
                    <option value="">{{lang.select}}</option>
                    <option value="SINGLE">{{lang.single}}</option>
                    <option value="COUPLE">{{lang.couple}}</option>
                    <option value="FAMILY">{{lang.family}}</option>

                    <!-- <option
                      *ngFor="let name of configMatrialStatus"
                      [value]="name"
                    >
                      {{ name }}
                    </option> -->
                  </select>
                  @if (f['spousecob'].errors &&
                  f['spousecob'].touched) {
                  <div class="invalid text-red-500">
                    @if (f['spousecob'].errors['required']) {
                    <div>{{ lang.please_enter_name_of_cob_coverage }}</div>
                    }
                  </div>
                  }


            </div>




              }
            </div>
          </div>
          }
        </div>
        <div class="col-span-2" id="box">
          <!-- <h4 class="spouseinfo">{{lang.spouseinfo}}</h4> -->
          <div
            class="col-span-2 w-full underline decoration-indigo-700 font-bold"
          >
            {{ lang.dependent }}
          </div>
          <label class="lineheight">
            {{ lang.dependentChild }}
          </label>
          <div class="flex items-center space-x-2">
            <input
              type="radio"
              id="true"
              formControlName="having_dependent_children"
              value="true"
              class="form-radio text-blue-600" (click)="havingchildren($event)"
            />
            <label for="true" class="text-gray-700">Yes</label>
          </div>

          <div class="flex items-center space-x-2">
            <input
              type="radio"
              id="false"
              formControlName="having_dependent_children"
              value="false"
              class="form-radio text-blue-600" (click)="havingchildren($event)"
            />
            <label for="false" class="text-gray-700">No</label>
          </div>
          @if (f['having_dependent_children'].errors &&
          f['having_dependent_children'].touched) {
          <div class="invalid text-red-500">
            @if (f['having_dependent_children'].errors['required']) {
            <div>{{ lang.please_choose_children_status }}</div>
            }
          </div>
          }
          @if(userForm.get('having_dependent_children')?.value === 'true'){
            <div
            class="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4"
          >
            <div class="col-span-1 w-1/2 sm:grid-cols-2">
              <label for="no_of_children"
              >{{lang.noChildren }}<span style="color: red">*</span></label>

            <input
              class="form-control checking field"
              type="number"
              name="no_of_children"
              id="no_of_children"
              value="1"
              name="childrenvalue"
              formControlName="no_of_children"
               onkeypress="return /^[0-9]+$/.test(event.key)"
              min="1"
            />

            </div>
            <div class="col-span-1">

                <button
                  type="button"
                  class="w-28 h-12 mt-8 rounded-full border-2 border-indigo-700 bg-gray-200 p-0.5 font-bold transition duration-700 ease-in-out hover:bg-white"
                  (click)="childAddFun()">
                  Add
                </button>
            </div>
            </div>


              @if (userForm.get('having_dependent_children')?.value === 'true' && childTableRowsLength==0) {
              <div class="invalid text-red-500">Please add at least one child</div>
              }
              @if(childTableRowsLength>0){
                <div class="col-span-1 table-responsive">
                  <table class="table" style="font-size:14px">
                    <thead>
                      <tr class="trbottomsolid">
                        <th class="bgColor-tr" scope="col">S.no</th>
                        <th class="bgColor-tr" scope="col">{{lang.first_name}}</th>
                        <th class="bgColor-tr" scope="col">{{lang.last_name}}</th>
                        <th class="bgColor-tr" scope="col">{{lang.gender}}</th>
                        <th class="bgColor-tr" scope="col">{{lang.dateOfBirth}}</th>
                        <th class="bgColor-tr" scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let childData of childInfoAddDataArray; let i = index">
                        <td class="thBottomNone"> {{i+1}}</td>
                        <td class="thBottomNone text-capitalize"> {{childData.firstName}}</td>
                        <td class="thBottomNone text-capitalize"> {{childData.lastName}}</td>
                        <td class="thBottomNone"> {{childData.gender}}</td>
                        <td class="thBottomNone"> {{childData.date_of_birth | date:'MM-dd-yyyy'}}</td>
                        <td class="thBottomNone text-center inline-flex items-center">
                          <svg class="h-10 w-10 text-green-600" (click)="EditChild(i)" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>
                            <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                             <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"  />
                              <line x1="16" y1="5" x2="19" y2="8" />
                            </svg>
                          <svg class="h-9 w-9 text-red-600"  (click)="DeleteChild(i)"width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                             <path stroke="none" d="M0 0h24v24H0z"/>
                              <rect x="3" y="4" width="18" height="4" rx="2" />
                               <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                                <line x1="10" y1="12" x2="14" y2="12" />
                              </svg>
<!-- <button type="button" class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2" (click)="EditChild(i)">Edit</button>
<button type="button" class="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2" (click)="DeleteChild(i)">Delete</button> -->
                          <!-- <i class="fa fa-edit childEditSymbleColor" style="font-size:18px" (click)="childInforTableEdit(i)"></i>&nbsp;
                          <i class="fa fa-trash-o childDeleteSymbleColor" style="font-size:18px" (click)="deleteChildrenSwal(i)"></i> -->
                          <!-- <i class="bi bi-pencil-square childEditSymbleColor" (click)="childInforTableEdit(i)"></i>
                          <i class="bi bi-trash-fill ms-2 childDeleteSymbleColor" (click)="deleteChildrenSwal(i)"></i> -->


                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
               }

          }
        </div>
        <div class="col-span-2 md:col-span-4 w-full lg:w-full">
          <hr class="h-px bg-indigo-700 border-y-2 dark:bg-gray-700" />
        </div>

        @if((userForm.get('provincial_health_coverage')?.value === 'false' && userForm.get('having_spouse')?.value === 'true') || (userForm.get('provincial_health_coverage')?.value === 'false' && userForm.get('having_dependent_children')?.value === 'true'))
        {

        <div class="col-span-2">
          <div
          class="col-span-2 w-full underline decoration-indigo-700 font-bold"
        >
        Dependent(s) Information
        </div>
        <label class="lineheight">
          Is Coverage required for Dependent(s)
        </label>
        <div class="flex items-center space-x-2">
          <input
            type="radio"
            id="true"
            value="true"
            class="form-radio text-blue-600"
            formControlName="coverageRequiredOfDependents"
            (change)="dependentCoverage($event)"
          />
          <label for="true" class="text-gray-700">Yes</label>
        </div>

        <div class="flex items-center space-x-2">
          <input
            type="radio"
            id="false"
            value="false"
            class="form-radio text-blue-600"
            formControlName="coverageRequiredOfDependents"
            (change)="dependentCoverage($event)"
          />
          <label for="false" class="text-gray-700">No</label>
        </div>
        @if (f['coverageRequiredOfDependents'].errors && f['coverageRequiredOfDependents'].touched) {
        <div class="invalid text-red-500">
          @if (f['coverageRequiredOfDependents'].errors['required']) {
          <div>Please select coverage required for dependents(s).</div>
          }
        </div>
        }

        @if(userForm.get('coverageRequiredOfDependents')?.value === 'true'){

          <div class="col-span-2">
            <label
              >Dependent(s) Date of Arrival in Canada<span style="color: red">*</span></label
            >

               <input type="text"
               class="form-control" formControlName="dependentdateOfArrival"

               #dp
               [bsConfig]="{dateInputFormat: 'MM-DD-YYYY'}"
               placeholder="MM-DD-YYYY"
               bsDatepicker (blur)="checkDateOfADependentArrival($event)"
               (keypress)="numbersOnly($event)"/>

               @if (f['dependentdateOfArrival'].errors && f['dependentdateOfArrival'].touched) {
                <div class="invalid text-red-500">
                  @if (f['dependentdateOfArrival'].errors['required']) {
                  <div>Please Select date of arrival in Canada</div>
                  }
                </div>
                }


            <div>
              <label
              >Country of Origin<span style="color: red">*</span></label>
            </div>

            <select
            class="form-control"
            id="countryOrigin"
            placeholder="Select"
            formControlName="dependentcountryOrigin"
          >
            <option value="" label="">{{lang.select}}</option>
            <!-- <option value="08-11-2022" label="">08-11-2022</option> -->
            <option value="Afghanistan">Afghanistan</option>
            <option value="Åland Islands">Åland Islands</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="American Samoa">American Samoa</option>
            <option value="Andorra">Andorra</option>
            <option value="Angola">Angola</option>
            <option value="Anguilla">Anguilla</option>
            <option value="Antarctica">Antarctica</option>
            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
            <option value="Argentina">Argentina</option>
            <option value="Armenia">Armenia</option>
            <option value="Aruba">Aruba</option>
            <option value="Australia">Australia</option>
            <option value="Austria">Austria</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Bahamas">Bahamas</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Barbados">Barbados</option>
            <option value="Belarus">Belarus</option>
            <option value="Belgium">Belgium</option>
            <option value="Belize">Belize</option>
            <option value="Benin">Benin</option>
            <option value="Bermuda">Bermuda</option>
            <option value="Bhutan">Bhutan</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
            <option value="Botswana">Botswana</option>
            <option value="Bouvet Island">Bouvet Island</option>
            <option value="Brazil">Brazil</option>
            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
            <option value="Brunei Darussalam">Brunei Darussalam</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Burkina Faso">Burkina Faso</option>
            <option value="Burundi">Burundi</option>
            <option value="Cambodia">Cambodia</option>
            <option value="Cameroon">Cameroon</option>
            <option value="Canada">Canada</option>
            <option value="Cape Verde">Cape Verde</option>
            <option value="Cayman Islands">Cayman Islands</option>
            <option value="Central African Republic">Central African Republic</option>
            <option value="Chad">Chad</option>
            <option value="Chile">Chile</option>
            <option value="China">China</option>
            <option value="Christmas Island">Christmas Island</option>
            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
            <option value="Colombia">Colombia</option>
            <option value="Comoros">Comoros</option>
            <option value="Congo">Congo</option>
            <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
            <option value="Cook Islands">Cook Islands</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Cote D'ivoire">Cote D'ivoire</option>
            <option value="Croatia">Croatia</option>
            <option value="Cuba">Cuba</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czech Republic">Czech Republic</option>
            <option value="Denmark">Denmark</option>
            <option value="Djibouti">Djibouti</option>
            <option value="Dominica">Dominica</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Egypt">Egypt</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Equatorial Guinea">Equatorial Guinea</option>
            <option value="Eritrea">Eritrea</option>
            <option value="Estonia">Estonia</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
            <option value="Faroe Islands">Faroe Islands</option>
            <option value="Fiji">Fiji</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="French Guiana">French Guiana</option>
            <option value="French Polynesia">French Polynesia</option>
            <option value="French Southern Territories">French Southern Territories</option>
            <option value="Gabon">Gabon</option>
            <option value="Gambia">Gambia</option>
            <option value="Georgia">Georgia</option>
            <option value="Germany">Germany</option>
            <option value="Ghana">Ghana</option>
            <option value="Gibraltar">Gibraltar</option>
            <option value="Greece">Greece</option>
            <option value="Greenland">Greenland</option>
            <option value="Grenada">Grenada</option>
            <option value="Guadeloupe">Guadeloupe</option>
            <option value="Guam">Guam</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guernsey">Guernsey</option>
            <option value="Guinea">Guinea</option>
            <option value="Guinea-bissau">Guinea-bissau</option>
            <option value="Guyana">Guyana</option>
            <option value="Haiti">Haiti</option>
            <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
            <option value="Honduras">Honduras</option>
            <option value="Hong Kong">Hong Kong</option>
            <option value="Hungary">Hungary</option>
            <option value="Iceland">Iceland</option>
            <option value="India">India</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
            <option value="Iraq">Iraq</option>
            <option value="Ireland">Ireland</option>
            <option value="Isle of Man">Isle of Man</option>
            <option value="Israel">Israel</option>
            <option value="Italy">Italy</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Japan">Japan</option>
            <option value="Jersey">Jersey</option>
            <option value="Jordan">Jordan</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kenya">Kenya</option>
            <option value="Kiribati">Kiribati</option>
            <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
            <option value="Korea, Republic of">Korea, Republic of</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
            <option value="Latvia">Latvia</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Lesotho">Lesotho</option>
            <option value="Liberia">Liberia</option>
            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Macao">Macao</option>
            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
            <option value="Madagascar">Madagascar</option>
            <option value="Malawi">Malawi</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Maldives">Maldives</option>
            <option value="Mali">Mali</option>
            <option value="Malta">Malta</option>
            <option value="Marshall Islands">Marshall Islands</option>
            <option value="Martinique">Martinique</option>
            <option value="Mauritania">Mauritania</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Mayotte">Mayotte</option>
            <option value="Mexico">Mexico</option>
            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
            <option value="Moldova, Republic of">Moldova, Republic of</option>
            <option value="Monaco">Monaco</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Montenegro">Montenegro</option>
            <option value="Montserrat">Montserrat</option>
            <option value="Morocco">Morocco</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Myanmar">Myanmar</option>
            <option value="Namibia">Namibia</option>
            <option value="Nauru">Nauru</option>
            <option value="Nepal">Nepal</option>
            <option value="Netherlands">Netherlands</option>
            <option value="Netherlands Antilles">Netherlands Antilles</option>
            <option value="New Caledonia">New Caledonia</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Niger">Niger</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Niue">Niue</option>
            <option value="Norfolk Island">Norfolk Island</option>
            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
            <option value="Norway">Norway</option>
            <option value="Oman">Oman</option>
            <option value="Pakistan">Pakistan</option>
            <option value="Palau">Palau</option>
            <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
            <option value="Panama">Panama</option>
            <option value="Papua New Guinea">Papua New Guinea</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Philippines">Philippines</option>
            <option value="Pitcairn">Pitcairn</option>
            <option value="Poland">Poland</option>
            <option value="Portugal">Portugal</option>
            <option value="Puerto Rico">Puerto Rico</option>
            <option value="Qatar">Qatar</option>
            <option value="Reunion">Reunion</option>
            <option value="Romania">Romania</option>
            <option value="Russian Federation">Russian Federation</option>
            <option value="Rwanda">Rwanda</option>
            <option value="Saint Helena">Saint Helena</option>
            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
            <option value="Saint Lucia">Saint Lucia</option>
            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
            <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
            <option value="Samoa">Samoa</option>
            <option value="San Marino">San Marino</option>
            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Senegal">Senegal</option>
            <option value="Serbia">Serbia</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sierra Leone">Sierra Leone</option>
            <option value="Singapore">Singapore</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Solomon Islands">Solomon Islands</option>
            <option value="Somalia">Somalia</option>
            <option value="South Africa">South Africa</option>
            <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
            <option value="Spain">Spain</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Sudan">Sudan</option>
            <option value="Suriname">Suriname</option>
            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
            <option value="Swaziland">Swaziland</option>
            <option value="Sweden">Sweden</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
            <option value="Taiwan">Taiwan</option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
            <option value="Thailand">Thailand</option>
            <option value="Timor-leste">Timor-leste</option>
            <option value="Togo">Togo</option>
            <option value="Tokelau">Tokelau</option>
            <option value="Tonga">Tonga</option>
            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Turkey">Turkey</option>
            <option value="Turkmenistan">Turkmenistan</option>
            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
            <option value="Tuvalu">Tuvalu</option>
            <option value="Uganda">Uganda</option>
            <option value="Ukraine">Ukraine</option>
            <option value="United Arab Emirates">United Arab Emirates</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="United States">United States</option>
            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
            <option value="Uruguay">Uruguay</option>
            <option value="Uzbekistan">Uzbekistan</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Viet Nam">Viet Nam</option>
            <option value="Virgin Islands, British">Virgin Islands, British</option>
            <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
            <option value="Wallis and Futuna">Wallis and Futuna</option>
            <option value="Western Sahara">Western Sahara</option>
            <option value="Yemen">Yemen</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabwe">Zimbabwe</option>


          </select>



        @if (f['dependentcountryOrigin'].errors && f['dependentcountryOrigin'].touched) {
          <div class="invalid text-red-500">
            @if (f['dependentcountryOrigin'].errors['required']) {
            <div>Please select dependent(s) country of Origin.</div>
            }
          </div>
          }
          <div>
              <label
              >Are the dependents landed immigrants?<span style="color: red">*</span></label>
            </div>

            <div class="flex items-center space-x-2">
              <input
                type="radio"
                id="true"
                value="true"
                class="form-radio text-blue-600"
                formControlName="dependentLandedImigrant"
                (change)="dependentLandedImigrant($event)"
              />
              <label for="true" class="text-gray-700">Yes</label>
            </div>

            <div class="flex items-center space-x-2">
              <input
                type="radio"
                id="false"

                value="false"
                class="form-radio text-blue-600"
                formControlName="dependentLandedImigrant"
                (change)="dependentLandedImigrant($event)"
              />
              <label for="false" class="text-gray-700">No</label>
            </div>



            @if (f['dependentLandedImigrant'].errors && f['dependentLandedImigrant'].touched) {
              <div class="invalid text-red-500">
                @if (f['dependentLandedImigrant'].errors['required']) {
                <div>Please Select dependent(s) landed immigrant</div>
                }
              </div>
              }

              @if(userForm.get('dependentLandedImigrant')?.value === 'false'){

                <div class="col-span-2">
                  <label
                  >Will they be applying for landed immigrant status?<span style="color: red">*</span></label>
                </div>




                <div class="flex items-center space-x-2">
                  <input
                    type="radio"
                    id="true"
                    value="true"
                    class="form-radio text-blue-600"
                    formControlName="dependentapplylandedstaus"

                  />
                  <label for="true" class="text-gray-700">Yes</label>
                </div>

                <div class="flex items-center space-x-2">
                  <input
                    type="radio"
                    id="false"

                    value="false"
                    class="form-radio text-blue-600"
                    formControlName="dependentapplylandedstaus"

                  />
                  <label for="false" class="text-gray-700">No</label>
                </div>
                @if (f['dependentapplylandedstaus'].errors && f['dependentapplylandedstaus'].touched) {
                  <div class="invalid text-red-500">
                    @if (f['dependentapplylandedstaus'].errors['required']) {
                    <div>Please Select dependents(s) landed status</div>
                    }
                  </div>
                  }

              }



            <div class="col-span-2">
              <label
              >Will the dependent(s) be in Canada for more than 3 months? <span style="color: red">*</span></label>
            </div>




            <div class="flex items-center space-x-2">
              <input
                type="radio"
                id="true"
                value="true"
                class="form-radio text-blue-600"
                formControlName="dependentMorethan3years"

              />
              <label for="true" class="text-gray-700">Yes</label>
            </div>

            <div class="flex items-center space-x-2">
              <input
                type="radio"
                id="false"

                value="false"
                class="form-radio text-blue-600"
                formControlName="dependentMorethan3years"

              />
              <label for="false" class="text-gray-700">No</label>
            </div>
            @if (f['dependentMorethan3years'].errors && f['dependentMorethan3years'].touched) {
              <div class="invalid text-red-500">
                @if (f['dependentMorethan3years'].errors['required']) {
                <div>Please Select status of dependent(s) in canada</div>
                }
              </div>
              }


        </div>
        }




        </div>

      }
        <!-- <div class="col-span-4">
        <hr class="border-t-1 border-black w-full ">
      </div> -->
      @if((userForm.get('provincial_health_coverage')?.value === 'false' && userForm.get('having_spouse')?.value === 'true') || (userForm.get('provincial_health_coverage')?.value === 'false' && userForm.get('having_dependent_children')?.value === 'true'))
        {

      <div class="col-span-2 md:col-span-4 w-full lg:w-full">
        <hr class="h-px bg-indigo-700 border-y-2 dark:bg-gray-700" />
      </div>
    }
        <div class="col-span-2">
          <input
            type="checkbox"
            formControlName="termsandconditions"
          />&nbsp;<span class="termsandconditions cursor-pointer"
            >{{ lang.read_terms_and_conditions_2 }}
            <span class="termsandconditionspara" (click)="termandconditions()" >
              <span class="termsandconditionslink" style="color: #007bff">{{
                lang.click_terms_and_conditions
              }}</span>
            </span>
          </span>
          @if (f['termsandconditions'].errors &&
          f['termsandconditions'].touched) {
          <div class="invalid text-red-500">
            @if (f['termsandconditions'].errors['required']) {
            <div>{{ lang.please_read_the_terms_and_conditions }}</div>
            }
          </div>
          }
        </div>

        <div class="col-span-2">
          <input
            type="checkbox"
            [value]="true"
            formControlName="disclouseradvisor"
          />&nbsp;<span class="Advisor cursor-pointer"
            >{{ lang.read_advisor_disclosure_2 }}
            <span (click)="advisorDisclousre()">
              <span class="advisordisclosure" style="color: #007bff">{{
                lang.click_advisor_disclosure
              }}</span>
            </span>
          </span>
          @if (f['disclouseradvisor'].errors && f['disclouseradvisor'].touched)
          {
          <div class="invalid text-red-500">
            @if (f['disclouseradvisor'].errors['required']) {
            <div>{{ lang.please_read_the_advisor_disclosure }}</div>
            }
          </div>
          }
        </div>
      </div>

      <div class="relative w-full">
        <div class="text-end fixed bottom-16 right-0 w-full mx-12">

          <button
            type="submit"
            class="w-36 h-14 mb-1 rounded-full border-2 text-center inline-flex items-center border-indigo-700 bg-gray-200 p-0.5 font-bold transition duration-700 ease-in-out hover:bg-white px-5 py-2.5 me-2"
          >{{ lang.next }}&nbsp;&nbsp;
          <svg class="h-10 w-10 me-2 text-sky-900"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">
            <line x1="5" y1="12" x2="19" y2="12" />
            <polyline points="12 5 19 12 12 19" />
          </svg>


          </button>
        </div>
      </div>
    </form>

    }
     @if(activeTab === 1){
    <!-- <div class="container mx-auto py-20 linescrollbar" style="height: calc(100vh - 200px);overflow-y: scroll;"> -->
      <div class="firstname">{{lang.hi}} &nbsp;{{ applicantfirstname }},</div>

      <div class="firstnameinfo">{{ completeapplicantinfo }} </div>
      @for (package of mainarray; track package) {

    <div   class="text-black text-center text-[18px] font-bold mt-4 mb-4">
      {{ package.name }}
    </div>

    <div
      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 border rounded-md w-full gap-4 plansscreen"
    >
      @for (group of package.groups; track group;let i = $index)
      {

         @for (plan of group.plans; track plan;let i=$index)
          {
            @for (product of plan.productAddonssNames;track product;let j=$index) {

      <div
        class="shadow-lg px-2 rounded-lg w-82"
        [style.background-color]="
          plan.isBundle == false
            ? plan.productAddonss[product][0].planlevel.backgroundColor
            : group.backgroundColor
        "
      >

        <div class="text-center text-white font-bold h-14 mt-1">

          @if(group.categorization.paidByCompany==1){
            <h6>Company Paid</h6>
          }
          @if(group.categorization.coveredByCompany==1){
            <h6>Upgrade To</h6>
          }
          @if(group.categorization.paidByEmployee==1){
            <h6>Voluntary Opt-In</h6>
          }
          <h1 class="text-[16px] mt-4">{{product}}</h1>

        </div>


        <div class="p-2">
          @if(plan.isBundle==true){
          <div
            class="h-48 overflow-y-scroll scrollbar-thin text-center bg-white my-8"
          >
            @if(group.planLevelFeatures){
               @for(features of
            group.planLevelFeatures; track features){

@if(features.feature.name!=null){
              <div   class="accordion-header" (click)="toggleItem1(features)" [style.background-color]="
              plan.isBundle==false?plan.productAddonss[product][0].planlevel.backgroundColor:group.backgroundColor" >
{{ features.feature.name }}
                <!-- @if(features.feature.name==null){
                  {{ features.feature.category }}
                }@else{
                  {{ features.feature.name }}
                } -->
                <span class="featureinfofaicon">

                  @if(features.expanded){
                    <svg class="w-5 h-5 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"/>
                  </svg>
                  }
                 @else{
                  <svg class="w-5 h-5 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"/>
                </svg>
                  }
                  <!-- <i *ngIf="features.expanded; else downarrowIcon1" class="fa fa-angle-right featureinfofaicon" aria-hidden="true"></i>
                  <ng-template #downarrowIcon1>
                    <i class="fa fa-angle-down featureinfofaicon" aria-hidden="true"></i>
                  </ng-template> -->
                </span>
              </div>
              <div *ngIf="features.expanded" class="accordion-content">
                <p [innerHTML]="features.description | safeHtml"></p>

              </div>
            }
            @else{
              <p class="text-left" style="padding:5px" [innerHTML]="features.description | safeHtml"></p>

            }
            }
          } @else {
            <!-- <p [innerHTML]="plan.productAddonss[product][0].description2"></p> -->
            }
          </div>

          } @else{
          <div
            class="h-96 overflow-y-scroll scrollbar-thin text-center bg-white"
          >
            @if(plan.productAddonss[product][0].planlevel.planLevelFeatures){
            @for (features of
            plan.productAddonss[product][0].planlevel.planLevelFeatures; track
            features){
             <strong>{{ features.feature.name }}</strong><br />

            <p [innerHTML]="features.description | safeHtml"></p>
            }
          } @else {
            <p class="text-left" [innerHTML]="plan.productAddonss[product][0].description2 | safeHtml"></p>
            }
          </div>

          } @for(coverage of plan.productAddonss[product]; track coverage;let k=$index){
            @if(plan.insuranceType!="LIFE"){
          <div class=" grid grid-cols-2 h-34 text-white text-[16px]">
<div class="col-span-1">
  <input
  type="checkbox"
   #planCheckdetails
   id="plancheck{{plan.isBundle?plan.id:plan.productAddonss[product][k].planlevel.id }}"

   class="plancheckbox{{ package.id }} plancv newPlan{{plan.id}} {{group.categorization.paidByCompany==1?'cp_plan':group.categorization.coveredByCompany==1?'cc_plan':'ep_plan'}} coverage{{coverage.planCoverageUI | uppercase}}"
   attr.data="{{ package.name }}##{{ group.name }}##{{
     plan.planCoverage
   }}##{{plan.productAddonss[product][k].calculatedTax.price}}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
     plan.frqMonthly
   }}##{{ package.optIn }}##{{
     group.disallowedPlanLevels
   }}##{{ group.parentId }}##{{ group.id }}##{{
     plan.name
   }}##{{ plan.planLevel }}##{{ plan.packageId }}##0##{{plan.planOptions}}##{{plan.productAddonss[product][k].id}}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}"
   (change)="
     onCheckboxChange(
       $event,
       package.allowMultiple,
       plan.productAddonss[product][k].plan_level.disallowedPlanLevels,
       plan.planOptions,
       group.parentId,
       package.name,
       group.name,
       plan.planCoverage,
       0,
       0,
       0,
       0,
       $event,
       i,
       j,
       k,
       plan,
       plan.productAddonss[product][k],package,group
     )
  ">
{{ coverage.planCoverageUI }}
</div>
<div claass="col-span-1 text-[12px]">

  @if(group.categorization.paidByCompany==1){
    <span style="font-size: 14px;float:right">You pay {{ currencySymbol}}0.00</span>
  }
  @if(group.categorization.coveredByCompany==1){
    <span style="font-size: 14px;float:right">You pay through payroll deduction {{ currencySymbol}}{{(plan.products?plan.products[k].upgradeCalculation.deltaplanPrice:plan.upgradeCalculation.deltaplanPrice) | number: "1.2-2"}}<small>*</small></span>
  }
  @if(group.categorization.paidByEmployee==1){
    <span style="font-size: 14px;float:right">You pay through CC/PAD {{ currencySymbol}}{{coverage.price | number: "1.2-2" }}<small>*</small></span>
  }

</div>
 </div>
}


 @if(plan.insuranceType=="LIFE"){

  <!-- Member -->


    <div class="flex flex-col gap-2 text-xs mx-2 my-3">
      <div class="flex px-1.5 items-center justify-between font-semibold text-white">
        <div class="font-semibold text-lg md:text-xl">
           Total
        </div>
        <div class="text-end text-lg md:text-xl">
          <p class="text-end w-full outline-none">
            ${{
            allFormData[plan.planCoverage]?.total || 0
            | number : "1.2-2"
            }}
          </p>
        </div>
      </div>
      <div class="grid grid-cols-4 gap-2 lg:gap-4 items-center justify-between">

        <div class="col-span-3 flex gap-2 items-center">
          <input id="member" type="checkbox"  attr.data="{{ package.name }}##{{ group.name }}##{{
            plan.planCoverage
          }}##{{
            plan.productAddonss[product][k].calculatedTax.price
          }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
            plan.frqMonthly
          }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{
            group.parentId
          }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{
            plan.packageId
          }}##0##{{ plan.planOptions }}##{{
            plan.productAddonss[product][k].id
          }}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}" name="{{ plan.planCoverage }}-member" id="planCheckLife{{ plan.planCoverage }}-MEMBER"
            class="w-6 h-6 my-auto flex-shrink-0 rounded cursor-pointer"
            (change)="onCheckboxChangeP(
                              $event,
                              plan.planCoverage,
                              'member',
                              'coverage',
                              plan.productAddonss[product][k]
                            )"
                           />
          <label htmlFor="member"
            class="mr-2 lg:mr-2 capitalize text-white w-full h-full label-text mt-3 text-[12px]">
            MEMBER
          </label>
        </div>
        <div class="col-span-1 text-white text-right font-semibold">
          <p class="bg-transparent text-end w-full h-full my-auto text-lg outline-none">
            ${{
            allFormData[ plan.planCoverage]?.member?.premium ||
            0 | number : "1.2-2"
            }}
          </p>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-2">
        <div class="col-span-1 text-base md:text-xl mt-3 text-[12px] text-white">
          <p>
            In the last 12 months, have you used, in any
            form whatsoever, tobacco, nicotine or
            cannabis mixed with tobacco?
          </p>

          <div class="grid grid-cols-3 mt-2">
            <div class="flex justify-center items-center gap-2">
              <input id="yes" type="radio" name="member-{{ plan.planCoverage }}"
              class="member-{{ plan.planCoverage }}-yes"
              attr.data="{{ package.name }}##{{ group.name }}##{{
                plan.planCoverage
              }}##{{
                plan.productAddonss[product][k].calculatedTax.price
              }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
                plan.frqMonthly
              }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{
                group.parentId
              }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{
                plan.packageId
              }}##0##{{ plan.planOptions }}##{{
                plan.productAddonss[product][k].id
              }}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}"
              [disabled]="
                                isDataAvailable(plan.planCoverage, 'member')
                              " class="w-6 h-6 my-auto outline-none cursor-pointer" [checked]="
                                allFormData[plan.planCoverage] &&
                                allFormData[plan.planCoverage]['member'] &&
                                allFormData[plan.planCoverage]['member'][
                                  'isSmoker'
                                ] === 'yes'
                              " value="yes" (change)="
                                handleRadioChange(
                                  $event,
                                  plan.planCoverage,
                                  'member',plan.planOptions,plan,
                                  plan.productAddonss[product][k],package,group,plan.productAddonss[product][k].member
                                )
                              " />
              <label htmlFor="yes" class="w-full mr-2 text-white text-[12px]">
                Yes
              </label>
            </div>
            <div class="flex justify-center items-center gap-2">
              <input id="no" type="radio" value="no" name="member-{{ plan.planCoverage }}" class="member-{{ plan.planCoverage }}-no"
              attr.data="{{ package.name }}##{{ group.name }}##{{
plan.planCoverage
}}##{{
plan.productAddonss[product][k].calculatedTax.price
}}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
plan.frqMonthly
}}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{
group.parentId
}}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{
plan.packageId
}}##0##{{ plan.planOptions }}##{{
plan.productAddonss[product][k].id
}}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}"
              [disabled]="
                                isDataAvailable(plan.planCoverage, 'member')
                              " class="w-6 h-6 my-auto outline-none cursor-pointer" [checked]="
                                allFormData[plan.planCoverage] &&
                                allFormData[plan.planCoverage]['member'] &&
                                allFormData[plan.planCoverage]['member'][
                                  'isSmoker'
                                ] === 'no'
                              " (change)="
                              handleRadioChange(
                                  $event,
                                  plan.planCoverage,
                                  'member',plan.planOptions,plan,
                                  plan.productAddonss[product][k],package,group,plan.productAddonss[product][k].member
                                )
                              " />
              <label htmlFor="no" class="w-full mr-2 text-white text-[12px]">
                No
              </label>
            </div>
          </div>
        </div>
        <div class="">
          <div className="my-5 ">
            <p htmlFor="rangeSlider" class="block my-3 text-center font-semibold mb-2 text-[12px] text-white" >
              <!-- ${{ plan.productAddonss[product][k].member.maximumCoverageWithoutEvidence | number }} -->
              ${{ plan.productAddonss[product][k].member.maximumCoverageWithoutEvidence/1000 | number }}k


            </p>
            <div className="relative">
              <div *ngIf="
                                plan.productAddonss[product][k].member.maximumCoverageWithEvidence &&
                                plan.productAddonss[product][k].member.minimumCoverage
                              " class="range-ticks flex justify-between mt-2">
                <span *ngFor="
                                  let tick of generateTicks(
                                    plan.productAddonss[product][k].member.maximumCoverageWithEvidence,
                                    plan.productAddonss[product][k].member.minimumCoverage
                                  );
                                  let i = index
                                " class="tick" [ngStyle]="{
                                  position: 'absolute',
                                  left:
                                    i === 0
                                      ? '0.4%'
                                      : i ===
                                      plan.productAddonss[product][k].member.maximumCoverageWithEvidence /
                                      plan.productAddonss[product][k].member.minimumCoverage
                                      ? '99.6%'
                                      : (((tick /
                                      plan.productAddonss[product][k].member.maximumCoverageWithEvidence) *
                                          100)+0.5) +
                                        '%',
                                  top: '9px',
                                  width: '2px',
                                  height: '6px',
                                  backgroundColor: 'white'
                                }"></span>
              </div>
              <div class="range-slider-container">
                <div class="range-slider-wrapper">
              <input type="range" attr.data="{{ package.name }}##{{ group.name }}##{{
                plan.planCoverage
              }}##{{
                plan.productAddonss[product][k].calculatedTax.price
              }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
                plan.frqMonthly
              }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{
                group.parentId
              }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{
                plan.packageId
              }}##0##{{ plan.planOptions }}##{{
                plan.productAddonss[product][k].id
              }}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}" [disabled]="
                                isDataAvailable(plan.planCoverage, 'member','isSmoker')
                              " id="{{ plan.planCoverage }}-member-rangeSlider" name="{{
                                plan.planCoverage
                              }}-member-rangeSlider" [(ngModel)]="plan.productAddonss[product][k].member.maximumCoverageWithoutEvidence"  [ngModelOptions]="{standalone: true}"
                (change)="
                                handleRangeChange(
                                  $event,
                                  plan.planCoverage,
                                  'member',plan.planOptions,plan,
                                  plan.productAddonss[product][k],package,group,$event,plan.productAddonss[product][k].member
                                )
                              " [min]="0" [max]="plan.productAddonss[product][k].member.maximumCoverageWithEvidence"
                [step]="plan.productAddonss[product][k].member.minimumCoverage" [ngStyle]="{
                                background: getSliderBackground(
                                  plan.productAddonss[product][k].member.maximumCoverageWithoutEvidence,
                                  plan.productAddonss[product][k].member.maximumCoverageWithEvidence,
                                  plan.productAddonss[product][k].member.minimumCoverage,plan.productAddonss[product][k].member.defaultRange
                                )
                              }"
                class="my-2 border appearance-none w-full h-4 rounded-md focus:outline-none" />
                <p class="name-label leading-4 text-[10px]" [ngStyle]="{'width.%': greenValue}">Guaranteed Approval</p>

                @if(showyellowbar){
                  <p class="name-label leading-4  text-[10px]" [ngStyle]="{'right.%': 0}" style="top:-10px" >Pending Approval</p>
                  }
                <div class="range-ticks1">
                  <span *ngFor="let val of rangeValues" class="tick1"></span>
                </div>

                </div>
                <div class="range-labels">
                  <span *ngFor="let val of rangeValues" class="range-label" >${{ val/1000 }}k</span>
                </div>

                </div>


            </div>
          </div>


          <!-- @if(allFormData[plan.planCoverage]?.member && allFormData[plan.planCoverage]?.member.length>0){ -->
          <!-- <p *ngIf="
                            allFormData[plan.planCoverage]?.member?.coverage.maximumCoverageWithoutEvidence >
                            plan.productAddonss[product][k].member.defaultRange
                          " class="text-right leading-3 text-[12px] text-white">
            Acceptance pending approval
          </p>
          <p *ngIf="
                            allFormData[plan.planCoverage]?.member?.coverage.maximumCoverageWithoutEvidence <=
                            plan.productAddonss[product][k].member.defaultRange
                          " class="text-right leading-3 text-[12px] text-white">
            Guaranteed approval
          </p> -->
        <!-- } -->
        </div>
      </div>
    </div>


  <!-- Spouse -->


    <div class="flex flex-col gap-2 text-xs mx-2 my-3" *ngIf="userForm.get('having_spouse')?.value === 'true'">
      <div class="grid grid-cols-4 gap-2 lg:gap-4 items-center justify-between">
        <div class="col-span-3 flex gap-2 items-center">
          <input id="spouse" type="checkbox" attr.data="{{ package.name }}##{{ group.name }}##{{
            plan.planCoverage
          }}##{{
            plan.productAddonss[product][k].calculatedTax.price
          }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
            plan.frqMonthly
          }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{
            group.parentId
          }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{
            plan.packageId
          }}##0##{{ plan.planOptions }}##{{
            plan.productAddonss[product][k].id
          }}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}" id="planCheckLife{{ plan.planCoverage }}-SPOUSE" name="{{ plan.planCoverage }}-spouse"
            class="w-6 h-6 my-auto flex-shrink-0 rounded cursor-pointer" [disabled]="
                                                        isDataAvailableSpouse(plan.planCoverage, 'member')"
            (change)="onCheckboxChangeP(
                              $event,
                              plan.planCoverage,
                              'spouse',
                              'coverage',
                              plan.productAddonss[product][k]
                            )" />
          <label htmlFor="spouse"
            class="mr-2 lg:mr-2 font-bold capitalize text-white w-full h-full label-text text-[12px] mt-3">
            SPOUSE
          </label>
        </div>



        <div class="col-span-1 text-white text-right font-semibold">
          <p class="bg-transparent text-end w-full h-full my-auto text-lg outline-none">
            ${{
            allFormData[plan.planCoverage]?.spouse?.premium ||
            0 | number : "1.2-2"
            }}
          </p>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-2">
        <div class="col-span-1 text-base md:text-xl mt-3 text-[12px] text-white">
          <p>
            In the last 12 months, have you used, in any
            form whatsoever, tobacco, nicotine or
            cannabis mixed with tobacco?
          </p>

          <div class="grid grid-cols-3 mt-2">
            <div class="flex justify-center items-center gap-2">
              <input id="yes" type="radio" class="spouse-{{ plan.planCoverage }}-yes" attr.data="{{ package.name }}##{{ group.name }}##{{
                plan.planCoverage
              }}##{{
                plan.productAddonss[product][k].calculatedTax.price
              }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
                plan.frqMonthly
              }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{
                group.parentId
              }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{
                plan.packageId
              }}##0##{{ plan.planOptions }}##{{
                plan.productAddonss[product][k].id
              }}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}" [disabled]="
                                isDataAvailable(plan.planCoverage, 'spouse')
                              " value="yes" name="spouse-{{ plan.planCoverage}}"
                class="w-6 h-6 my-auto outline-none cursor-pointer" [checked]="
                                allFormData[plan.planCoverage] &&
                                allFormData[plan.planCoverage]['spouse'] &&
                                allFormData[plan.planCoverage]['spouse'][
                                  'isSmoker'
                                ] === 'yes'
                              " (change)="
                              handleRadioChange(
                                  $event,
                                  plan.planCoverage,
                                  'spouse',plan.planOptions,plan,
                                  plan.productAddonss[product][k],package,group,plan.productAddonss[product][k].spouse
                                )
                              " />
              <label htmlFor="yes" class="w-full mr-2 text-white text-[12px]">
                Yes
              </label>
            </div>
            <div class="flex justify-center items-center gap-2">

              <input id="no" type="radio" value="no" class="spouse-{{ plan.planCoverage }}-no" attr.data="{{ package.name }}##{{ group.name }}##{{
                plan.planCoverage
              }}##{{
                plan.productAddonss[product][k].calculatedTax.price
              }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
                plan.frqMonthly
              }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{
                group.parentId
              }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{
                plan.packageId
              }}##0##{{ plan.planOptions }}##{{
                plan.productAddonss[product][k].id
              }}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}"  [disabled]="
                                isDataAvailable(plan.planCoverage, 'spouse')
                              " name="spouse-{{ plan.planCoverage }}"
                class="w-6 h-6 my-auto outline-none cursor-pointer" [checked]="
                                allFormData[plan.planCoverage] &&
                                allFormData[plan.planCoverage]['spouse'] &&
                                allFormData[plan.planCoverage]['spouse'][
                                  'isSmoker'
                                ] === 'no'
                              " (change)="
                              handleRadioChange(
                                  $event,
                                  plan.planCoverage,
                                  'spouse',plan.planOptions,plan,
                                  plan.productAddonss[product][k],package,group,plan.productAddonss[product][k].spouse
                                )
                              " />
              <label htmlFor="no" class="w-full mr-2 text-white text-[12px]">
                No
              </label>
            </div>
          </div>
        </div>
        <div class="">
          <div className="my-5">
            <p class="block my-3 text-center mb-2 font-semibold text-[12px] text-white" >
              ${{  plan.productAddonss[product][k].spouse.maximumCoverageWithoutEvidence/1000 | number }}k
            </p>
            <div className="relative">
              <div *ngIf="
                                plan.productAddonss[product][k].spouse.maximumCoverageWithEvidence &&
                                plan.productAddonss[product][k].spouse.minimumCoverage
                              " class="range-ticks flex justify-between mt-2">
                <span *ngFor="
                                  let tick of generateTicks(
                                    plan.productAddonss[product][k].spouse.maximumCoverageWithEvidence,
                                    plan.productAddonss[product][k].spouse.minimumCoverage
                                  );
                                  let i = index
                                " class="tick" [ngStyle]="{
                                  position: 'absolute',
                                  left:
                                    i === 0
                                      ? '0.4%'
                                      : i ===
                                      plan.productAddonss[product][k].spouse.maximumCoverageWithEvidence /
                                      plan.productAddonss[product][k].spouse.minimumCoverage
                                      ? '99.5%'
                                      : (((tick /
                                      plan.productAddonss[product][k].spouse.maximumCoverageWithEvidence) *
                                          100)+0.3)+
                                        '%',
                                  top: '9px',
                                  width: '2px',
                                  height: '6px',
                                  backgroundColor: 'white'
                                }"></span>
              </div>
              <div class="range-slider-container">
                <div class="range-slider-wrapper">
              <input type="range" attr.data="{{ package.name }}##{{ group.name }}##{{
                plan.planCoverage
              }}##{{
                plan.productAddonss[product][k].calculatedTax.price
              }}##0##0##0##{{ plan.id }}##{{ plan.fusebillId }}##{{
                plan.frqMonthly
              }}##{{ package.optIn }}##{{ group.disallowedPlanLevels }}##{{
                group.parentId
              }}##{{ group.id }}##{{ plan.name }}##{{ plan.planLevel }}##{{
                plan.packageId
              }}##0##{{ plan.planOptions }}##{{
                plan.productAddonss[product][k].id
              }}##{{plan.productAddonss[product][k].plan_level.id}}##{{plan.isBundle}}" [disabled]="
                                isDataAvailable(plan.planCoverage, 'spouse','isSmoker')
                              " id="{{ plan.planCoverage }}-spouse-rangeSlider" name="{{
                                plan.planCoverage
                              }}-spouse-rangeSlider" [(ngModel)]="plan.productAddonss[product][k].spouse.maximumCoverageWithoutEvidence"
                (change)="
                              handleRangeChange(
                                  $event,
                                  plan.planCoverage,
                                  'spouse',plan.planOptions,plan,
                                  plan.productAddonss[product][k],package,group,$event,plan.productAddonss[product][k].spouse
                                )
                              " [min]="0" [max]="plan.productAddonss[product][k].spouse.maximumCoverageWithEvidence"
                [step]="plan.productAddonss[product][k].spouse.minimumCoverage" [ngStyle]="{
                                background: getSliderBackground(
                                  plan.productAddonss[product][k].spouse.maximumCoverageWithoutEvidence,
                                  plan.productAddonss[product][k].spouse.maximumCoverageWithEvidence,
                                  plan.productAddonss[product][k].spouse.minimumCoverage,plan.productAddonss[product][k].member.defaultRange
                                )
                              }"
                class="my-2 border appearance-none w-full h-4 rounded-md focus:outline-none"
                style="background: #78aaef" />
                <p class="name-label leading-4 text-[10px]" [ngStyle]="{'width.%': greenValue}">Guaranteed Approval</p>

                @if(showyellowbar){
                  <p class="name-label leading-4  text-[10px]" [ngStyle]="{'right.%': 0}" style="top:-10px" >Pending Approval</p>
                  }
                <div class="range-ticks1">
                  <span *ngFor="let val of rangeValues" class="tick1"></span>
                </div>

                </div>
                <div class="range-labels">
                  <span *ngFor="let val of rangeValues" class="range-label" >${{ val/1000 }}k</span>
                </div>

                </div>
            </div>
          </div>

          <!-- <p *ngIf="
          allFormData[plan.planCoverage]?.spouse?.coverage.maximumCoverageWithoutEvidence >
          plan.productAddonss[product][k].spouse.defaultRange
        " class="text-right leading-3 text-[12px] text-white">
Acceptance pending approval
</p>
<p *ngIf="
          allFormData[plan.planCoverage]?.spouse?.coverage.maximumCoverageWithoutEvidence <=
          plan.productAddonss[product][k].spouse.defaultRange
        " class="text-right leading-3 text-[12px] text-white">
Guaranteed approval
</p> -->
        </div>

      </div>
    </div>



  <!-- Dependent -->


    <!-- <div class="flex flex-col gap-2 text-xs mx-2 my-3" *ngIf="userForm.get('having_dependent_children')?.value === 'true'">
      <div class="grid grid-cols-4 gap-2 lg:gap-4 items-center justify-evenly">
        <div class="col-span-2 flex gap-2 items-center">
          <input id="dependent" type="checkbox" name="{{ plan.planCoverage }}-dependent"
            class="w-6 h-6 my-auto flex-shrink-0 rounded cursor-pointer" (change)="
                            onCheckboxChangeP(
                              $event,
                              plan.planCoverage,
                              'dependent',
                              'coverage',
                              plan.productAddonss[product][k].dependent
                            )
                          " />
          <label htmlFor="dependent"
            class="mr-2 lg:mr-2 font-bold text-white w-full h-full label-text">
            DEPENDENT
          </label>
        </div>

        <div class="col-span-2 text-white text-right font-semibold">
          <p class="bg-transparent text-end w-full h-full my-auto text-lg outline-none">
            ${{
            allFormData[plan.planCoverage]?.dependent
            ?.premium || 0 | number : "1.2-2"
            }}
          </p>
        </div>
        <div *ngFor="
                          let c of signUpData.childrenDetails;
                          let index = index
                        "
          class="col-span-4 grid grid-cols-4 gap-2 lg:gap-4 items-center justify-evenly">
          <p htmlFor="dependent"
            class="mr-2 lg:mr-2 col-span-2 capitalize text-lg text-start justify-center font-bold text-white w-full h-full">
            {{index+1}})&nbsp;{{ c.childFirstName }}&nbsp;{{ c.childLastName }}
          </p>


        </div>
      </div>
      <div class="grid grid-cols-1 gap-2">
        <div className="mt-5">
          <p htmlFor="rangeSlider" style="font-size:12px"
            class="block my-3 text-center text-white font-semibold mb-2">
            ${{ plan.productAddonss[product][k].dependent.maximumCoverageWithoutEvidence | number }}
          </p>
          <div className="relative mt-10">
            <div *ngIf="
                                plan.productAddonss[product][k].dependent.maximumCoverageWithEvidence &&
                                plan.productAddonss[product][k].dependent.minimumCoverage
                              " class="range-ticks flex justify-between mt-2">
              <span *ngFor="
                                  let tick of generateTicks(
                                    plan.productAddonss[product][k].dependent.maximumCoverageWithEvidence,
                                    plan.productAddonss[product][k].dependent.minimumCoverage
                                  );
                                  let i = index
                                " class="tick" [ngStyle]="{
                                  position: 'absolute',
                                  left:
                                    i === 0
                                      ? '0.5%'
                                      : i ===
                                      plan.productAddonss[product][k].dependent.maximumCoverageWithEvidence /
                                      plan.productAddonss[product][k].dependent.minimumCoverage
                                      ? '99.5%'
                                      : (tick /
                                      plan.productAddonss[product][k].dependent.maximumCoverageWithEvidence) *
                                          100 +
                                        '%',
                                  top: '9px',
                                  width: '2px',
                                  height: '6px',
                                  backgroundColor: 'white'
                                }"></span>
            </div>
            <input type="range" [disabled]="
                              isDataAvailable(plan.planCoverage, 'dependent')
                            " id="{{plan.planCoverage }}-dependent-rangeSlider" name="{{
                              plan.planCoverage
                            }}-dependent-rangeSlider" [(ngModel)]="plan.productAddonss[product][k].dependent.maximumCoverageWithoutEvidence" [ngModelOptions]="{standalone: true}"
              (ngModelChange)="
                              handleRangeChangeForDependent(
                                $event,
                                plan.planCoverage,
                                'dependent'
                              )
                            " [min]="0" [max]="plan.productAddonss[product][k].dependent.maximumCoverageWithEvidence"
              [step]="plan.productAddonss[product][k].dependent.minimumCoverage"
              class="my-2 border appearance-none w-full h-4 rounded-md focus:outline-none"
              style="background: #78aaef" />
          </div>
        </div>
      </div>
    </div> -->





<!-- <div className="" style="padding-top: 10px">
*Monthly, applicable taxes extra
</div> -->



}
          }
          <!-- <h3 class="font-bold text-xl mb-2">Card Title 1</h3> -->
          <p class="text-white text-base">
            *{{ lang.monthly_plus_any_applicable_taxes }}
          </p>
        </div>
      </div>
      }
    }
      <!-- <img src="https://via.placeholder.com/400" alt="Card Image" class="w-full h-48 object-cover"> -->

    }
    </div>
    }


    <div class="relative">

      <!-- Left Button -->
      <button class="fixed text-center inline-flex items-center left-0 bottom-16  w-40 h-14 mb-1 rounded-full border-2 border-indigo-700 bg-gray-200 p-0.5 font-bold  mx-2 sm:mx-14 hover:bg-white" (click)="gotoPersonalInfoPage()">

        <svg class="h-10 w-10 text-sky-900"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="5" y1="12" x2="19" y2="12" />  <line x1="5" y1="12" x2="11" y2="18" />  <line x1="5" y1="12" x2="11" y2="6" /></svg>
        &nbsp;{{ lang.previous }}
      </button>

      <!-- Right Button -->
      <button [disabled]="plansnexttab || cartcheckvalue" class="fixed text-center inline-flex items-center right-0 bottom-16 w-36 h-14 mb-1 rounded-full border-2 border-indigo-700 bg-gray-200 p-4 font-bold  mx-12 hover:bg-white" (click)="paymentpage(1)">
        {{ lang.next }}
        &nbsp;
          <svg class="h-9 w-9 me-2 text-sky-900"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">
            <line x1="5" y1="12" x2="19" y2="12" />
            <polyline points="12 5 19 12 12 19" />
          </svg>
      </button>


          <!-- <button
            type="submit"
            class="w-36 h-14 mb-1 rounded-full border-2 text-center inline-flex items-center border-indigo-700 bg-gray-200 p-0.5 font-bold transition duration-700 ease-in-out hover:bg-white px-5 py-2.5 me-2"
          >{{ lang.next }}&nbsp;&nbsp;
          <svg class="h-10 w-10 me-2 text-sky-900"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">
            <line x1="5" y1="12" x2="19" y2="12" />
            <polyline points="12 5 19 12 12 19" />
          </svg>


          </button> -->
      </div>
    <!-- </div> -->

    }
    @if(activeTab === 2){


      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-3 mx-2">
        <!-- First column -->


        <!-- First column -->

        <!-- Second column -->
        <div class="col-span-full bg-white text-black">
            <!-- <div class="form-row" *ngIf="!singleSummaryTable && (companySelectedPlans.length == 0 ? false : true)"> -->

              @if(!singleSummaryTable && (companySelectedPlans.length == 0 ? false : true)){
              <div class="col-span-full">
                <!-- <label for="cardnumnber"><strong>{{lang.core_plans_premium_paid_100%_by_employer}}</strong></label> -->
                <label for="cardnumnber"><strong>Core Plans (Premium paid 100% by employer)</strong></label>



                <div>
                  <table class="table table-bordered text-black" id="customerPlansTable">
                    <tbody>
                      <tr>
                        <!-- <th>S. no</th> -->
                        <th>{{lang.plan}}</th>
                        <!-- <th>Company Share</th>
                          <th>Employee Share</th> -->
                        <th class="taxamount">{{lang.amount}}({{lang.company}})</th>
                        <!-- <td>Plan</td> -->
                        <th class="taxamount">{{lang.tax}}</th>
                        <th class="taxamount">{{lang.total}}</th>
                      </tr>
                      <tr
                        *ngFor="
                            let item of companySelectedPlans;
                            let i = index
                          ">
                        <!-- <td>{{i+1}}</td> -->
                        <!-- {{item.groupName | lowercase}}- -->

                        <td>{{ item.planname }}<br />({{ item.packagename }})</td>
                        <!-- <td>{{item.companyAmountUI}}</td>
                          <td>{{item.employeeAmountUI}}</td> -->
                        <td class="taxamount">
                          {{ currencySymbol }}{{ item.planPrice | number: "1.2-2" }}
                        </td>
                        <!-- <td>{{item.planCoverage}}</td> -->
                        <td class="taxamount">
                          <span *ngIf="item.gstCheck" class="gsttaxes"
                            >{{ item.gstPrice == 0 ? "-" : currencySymbol + (item.gstPrice |
                            number: "1.2-2") + "&nbsp;(GST)" }}</span
                          ><span *ngIf="item.pstCheck" class="gsttaxes"
                            >{{ item.pstPrice == 0 ? "-" : currencySymbol + (item.pstPrice |
                            number: "1.2-2") + "&nbsp;(PST)" }}</span
                          ><span *ngIf="item.hstCheck" class="gsttaxes"
                            >{{ item.hstPrice == 0 ? "-" : currencySymbol + (item.hstPrice |
                            number: "1.2-2") + "&nbsp;(HST)" }}</span
                          ><span
                            *ngIf="
                                !item.gstCheck &&
                                !item.pstCheck &&
                                !item.hstCheck
                              "
                            >-</span
                          >
                        </td>
                        <td class="grandTotal">
                          {{ currencySymbol }}{{ item.totalPrice | number: "1.2-2" }}
                        </td>
                      </tr>

                      <tr>
                          <td>{{lang.total}}</td>
                          <td colspan="2"></td>
                          <td class="grandTotal">
                           <b>{{ currencySymbol
                            }}{{ companyPaidTotal}}</b>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            }

            <br />

            <!-- no use -->
            <div
              class="form-row"
              *ngIf="!singleSummaryTable && (companyCoveredPlans.length == 0 ? false : true)"
              class="form-row">
              <div class="form-group col-md-12">
                <label for="cardnumnber"
                  ><strong
                    >{{lang.upgrade_plans_premium_difference_payroll_deduction}}34</strong
                  ></label
                >

                <div>
                  <table class="table table-bordered text-black" id="customerPlansTable">
                    <tbody>
                      <tr>
                        <!-- <th>S. no</th> -->
                        <th>{{lang.plan}}</th>
                        <!-- <th>Company Share</th>
                        <th>Employee Share</th> -->
                        <th class="taxamount">{{lang.amount}}<br>({{lang.company}}+{{lang.employee}})</th>
                        <!-- <td>Plan</td> -->
                        <th class="taxamount">{{lang.tax}}</th>
                        <th class="taxamount">{{lang.total}}</th>
                      </tr>
                      <ng-container *ngFor="
                      let item of companyCoveredPlans;
                      let i = index
                    ">
                    <tr class="main-details-ccplan-tr">

                    <td>{{i+1}}. {{ item.planname }}     <br>({{ item.packagename }})     </td>
                    <td class="taxamount">
                      {{ currencySymbol }}{{ item.planPrice | number: "1.2-2"
                      }}
                    </td>
                    <td class="taxamount">
                      <span *ngIf="item.gstCheck" class="gsttaxes"
                        >{{ item.gstPrice == 0 ? "-" : currencySymbol + (item.gstPrice |
                        number: "1.2-2") + "&nbsp;(GST)" }}</span
                      ><span *ngIf="item.pstCheck" class="gsttaxes"
                        >{{ item.pstPrice == 0 ? "-" : currencySymbol + (item.pstPrice |
                        number: "1.2-2") + "&nbsp;(PST)" }}</span
                      ><span *ngIf="item.hstCheck" class="gsttaxes"
                        >{{ item.hstPrice == 0 ? "-" : currencySymbol + (item.hstPrice |
                        number: "1.2-2") + "&nbsp;(HST)" }}</span
                      ><span
                        *ngIf="
                          !item.gstCheck &&
                          !item.pstCheck &&
                          !item.hstCheck
                        "
                        >-</span
                      ></td>
                      <td class="grandTotal">
                        {{ currencySymbol }}{{ item.totalPrice | number: "1.2-2" }}
                        </td>
                      </tr>
                      <tr class="additional-details-ccplan-tr">
                        <!-- <td></td> -->
                        <td>Base Plan paid by company <br> {{item.companyCoveredBasePlan.planName}}</td>
                        <td>
                           Company share from base plan -      <i [innerHTML]="item.companyCoveredBasePlan.amount | number: '1.2-2'"></i>
                          <br> Employee share deducted from salary- <i [innerHTML]="item.employeeDeductions.amount | number: '1.2-2'"></i>
                        </td>
                        <td>
                         Company share from base plan -
                         <!-- <i [innerHTML]="item.companyCoveredBasePlan.taxUI"></i>  -->
                         [{{item.companyCoveredBasePlan.tax}}]
                          <br> Employee share deducted from salary-
                          <!-- <i [innerHTML]="item.employeeDeductions.taxUI"></i> -->
                          [{{item.employeeDeductions.tax}}]
                        </td>
                        <td>   Company share from base plan -       <i [innerHTML]="item.companyCoveredBasePlan.total | number: '1.2-2'"></i>
                          <br> Employee share deducted from salary- <i [innerHTML]="item.employeeDeductions.total | number: '1.2-2'"></i>
                      </tr>
                  </ng-container>


                      <tr>
                        <td>Total(All plans)</td>

                        <td class="grandTotal" colspan="3">
                        <b>    {{ currencySymbol
                          }}{{ companyCoveredTotal | number: '1.2-2'}}</b>
                          <br />--------------------
                          <p>
                          <br> Company share from all base plans -      {{ currencySymbol
                          }}{{companyCoveredCompanyShareTotal | number: '1.2-2'}}
                          <br> Employee share from all plans deducted from salary- {{ currencySymbol
                          }}{{companyCoveredEmployeeShareTotal | number: '1.2-2'}}
                        </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <br />
              <!-- no use -->
            @if(!singleSummaryTable && (EmployeeSelectedPlans.length == 0 ? false : true)){
              <div class="col-span-full">
                <label for="cardnumnber"
                  ><strong>{{lang.voluntary_plans__premium_paid_employee}}56</strong></label
                >

                <div>
                  <table class="table table-bordered text-black" id="customerPlansTable">
                    <tbody>
                      <tr>
                        <!-- <th>S. no</th> -->
                        <th>{{lang.plan}}</th>
                        <!-- <th>Company Share</th>
                      <th>Employee Share</th> -->
                        <th class="taxamount">{{lang.amount}}(Employee)</th>
                        <!-- <td>Plan</td> -->
                        <th class="taxamount">{{lang.tax}}</th>
                        <th class="taxamount">{{lang.total}}</th>
                      </tr>
                      <tr
                        *ngFor="
                        let item of EmployeeSelectedPlans;
                        let i = index
                      ">
                        <!-- <td>{{i+1}}</td> -->
                        <!-- {{item.groupName | lowercase}}- -->

                        <td>{{ item.planname }}<br />({{ item.packagename }})</td>
                        <!-- <td>{{item.companyAmountUI}}</td>
                      <td>{{item.employeeAmountUI}}</td> -->
                        <td class="taxamount">
                          {{ currencySymbol }}{{ item.planPrice | number: "1.2-2"
                          }}
                        </td>
                        <!-- <td>{{item.planCoverage}}</td> -->
                        <td class="taxamount">
                          <span *ngIf="item.gstCheck" class="gsttaxes"
                            >{{ item.gstPrice == 0 ? "-" : currencySymbol + (item.gstPrice |
                            number: "1.2-2") + "&nbsp;(GST)" }}</span
                          ><span *ngIf="item.pstCheck" class="gsttaxes"
                            >{{ item.pstPrice == 0 ? "-" : currencySymbol + (item.pstPrice |
                            number: "1.2-2") + "&nbsp;(PST)" }}</span
                          ><span *ngIf="item.hstCheck" class="gsttaxes"
                            >{{ item.hstPrice == 0 ? "-" : currencySymbol + (item.hstPrice |
                            number: "1.2-2") + "&nbsp;(HST)" }}</span
                          ><span
                            *ngIf="
                            !item.gstCheck &&
                            !item.pstCheck &&
                            !item.hstCheck
                          "
                            >-</span
                          >
                        </td>
                        <td class="grandTotal">
                          {{ currencySymbol }}{{ item.totalPrice | number: "1.2-2" }}
                        </td>
                      </tr>

                    <tr>
                      <td>Total</td>
                      <td colspan="2"></td>
                      <td class="grandTotal">
                      <b>   {{ currencySymbol
                        }}{{ paidEmployeedTotal | number: "1.2-2" }}</b>
                      </td>
                    </tr>
                    </tbody>
                  </table>

                </div>
              </div>

            }

            @if(singleSummaryTable && allSelectedPlans.length == 0 ? false : true){
              <div class="cols-span-full">
                <label for="cardnumnber"><strong>{{lang.all_selected_plans_summary}}</strong></label>

                <div>
                  <table class="table table-bordered text-black" id="customerPlansTable">
                    <tbody>
                      <tr>
                        <!-- <th>S. no</th> -->
                        <th>{{lang.plan}}</th>
                        <!-- <th>Company Share</th>
                          <th>Employee Share</th> -->
                          <th>{{lang.type}}</th>
                        <th class="taxamount">Premium</th>
                        <!-- <td>Plan</td> -->
                        <!-- <th class="taxamount">Tax Percentage</th> -->

                        <th class="taxamount">{{lang.tax}}</th>
                        <th class="taxamount">{{lang.total}}</th>
                      </tr>
                      <tr
                        *ngFor="
                            let item of allSelectedPlans;
                            let i = index
                          ">
                        <!-- <td>{{i+1}}</td> -->
                        <!-- {{item.groupName | lowercase}}- -->
                        <td *ngIf="item.isBundle==true;else productname">

                          <!-- ({{ item.packagename }}) -  -->
                          <span *ngIf="item.coveredByCompany==1;else noupgrade">{{ item.upgradeCalculation.planName }}</span>
                          <ng-template #noupgrade>{{item.planname}}</ng-template>

                        </td>

                          <ng-template  #productname>
                            <td>
                              <!-- ({{ item.packagename }}) -  -->
                              <span *ngIf="item.coveredByCompany==1;else noupgrade1">{{ item.upgradeCalculation.planName }}</span>
                              <ng-template #noupgrade1>{{item.planproductname}}</ng-template>
                            </td>
                          </ng-template>

                        <!-- <td>{{item.companyAmountUI}}</td>
                          <td>{{item.employeeAmountUI}}</td> -->
                          <td>{{item.type}}</td>
                        <td class="grandTotal amounts" *ngIf="employercheck">

                          ---
                        </td>
                        <td class="grandTotal amounts" *ngIf="!employercheck">

                          <span *ngIf="item.coveredByCompany==1;else noupgradeprice">{{ currencySymbol }}{{ item.upgradeCalculation.combinedplanPrice | number: "1.2-2"}}</span>
                          <ng-template #noupgradeprice>{{ currencySymbol }}{{ item.planPrice | number: "1.2-2" }}</ng-template>

                        </td>
                        <!-- <td>{{item.planCoverage}}</td> -->
                        <!-- <td>taxRateperecnatge&&taxtype</td> -->
                        <td class="grandTotal amounts" *ngIf="employercheck">
                         ---
                        </td>
                        <td class="grandTotal amounts" *ngIf="!employercheck">
                          <span *ngIf="item.coveredByCompany==1;else noupgradetax">{{ currencySymbol }}{{ item.upgradeCalculation.combinedplanTax | number: "1.2-2"}}</span>
                          <ng-template #noupgradetax>{{ currencySymbol }}{{item.tax | number: "1.2-2"}}</ng-template>

                        </td>

                        <td class="grandTotal amounts" *ngIf="employercheck">
                          ---
                        </td>
                        <td class="grandTotal amounts" *ngIf="!employercheck">
                          <span *ngIf="item.coveredByCompany==1;else noupgradetotal">{{ currencySymbol }}{{ item.upgradeCalculation.combinedplanTotal | number: "1.2-2"}}</span>
                          <ng-template #noupgradetotal>{{ currencySymbol }}{{ item.totalPrice | number: "1.2-2" }}</ng-template>

                        </td>
                      </tr>

                      <tr style="background-color: #D08000 ;">
                          <td>{{lang.total}}</td>
                          <td ></td>
                          <td class="grandTotal amounts" *ngIf="employercheck">---</td>
                          <td class="grandTotal amounts" *ngIf="!employercheck">{{ currencySymbol}}{{ allPlansAmountTotal | number: "1.2-2" }}</td>
                          <td class="grandTotal amounts"  *ngIf="employercheck">---</td>
                          <td class="grandTotal amounts" *ngIf="!employercheck">{{ currencySymbol
                          }}{{allPlansTotaltax | number: "1.2-2"}}</td>
                          <td class="grandTotal amounts" *ngIf="employercheck">
                           ---
                          </td>
                          <td class="grandTotal amounts" *ngIf="!employercheck">
                            <b>{{ currencySymbol
                             }}{{ allPlansTotal | number: "1.2-2" }}</b>
                           </td>
                        </tr>


                        <tr>
                          <td><td>
                          <td></td>
                          <td></td>
                          <td></td>
                          </tr>
                        <tr style="background-color: black">
                          <td style="color:white">Monthly Payment Summary <td>
                          <td></td>
                          <td></td>
                          <td></td>
                          </tr>
                         <tr>
                          <tr *ngIf="companyDealingPlans.length == 0 ? false : true">
                            <td><b>Company Pays</b><td>
                            <td></td>
                            <td></td>
                            <td></td>
                            </tr>
                           <tr *ngFor="let companypay of companyDealingPlans">

                          <td>
                            <span *ngIf="companypay.upgradeCalculation;else normalname">{{companypay.upgradeCalculation.compnayShareStatement}}</span>
                            <ng-template #normalname>
                              {{companypay.planname}}
                            </ng-template>

                          </td>
                          <td >{{companypay.type}}</td>


                          <td class="grandTotal amounts" *ngIf="employercheck">
                            ---
                      </td>
                      <td class="grandTotal amounts" *ngIf="!employercheck">
                        <span *ngIf="companypay.upgradeCalculation;else normalamount">{{ currencySymbol
                        }}{{ companypay.upgradeCalculation.baseplanPrice | number: "1.2-2" }}</span>
                    <ng-template #normalamount>
                      {{ currencySymbol
                      }}{{ companypay.amount | number: "1.2-2" }}
                    </ng-template>
                  </td>
                          <td class="grandTotal amounts" *ngIf="employercheck">
                           ---
                        </td>
                        <td class="grandTotal amounts" *ngIf="!employercheck">
                          <span *ngIf="companypay.upgradeCalculation;else normaltax">{{ currencySymbol
                          }}{{ companypay.upgradeCalculation.baseplanTax | number: "1.2-2" }}</span>

                        <ng-template #normaltax>
                          {{ currencySymbol
                          }}{{ companypay.tax | number: "1.2-2" }}
                        </ng-template>
                      </td>
                          <td class="grandTotal amounts" *ngIf="employercheck">
                            ---
                          </td>
                          <td class="grandTotal amounts" *ngIf="!employercheck">
                            <span *ngIf="companypay.upgradeCalculation;else normaltotal">
                           <b>{{ currencySymbol}}{{ companypay.upgradeCalculation.baseplanTotal | number: "1.2-2" }}</b></span>

                            <ng-template #normaltotal>
                              <b>{{ currencySymbol
                              }}{{ companypay.total | number: "1.2-2" }}</b>
                            </ng-template>
                          </td>



                        </tr>

                        <tr *ngIf="payrollCCplansarr.length == 0 ? false : true">
                          <td><b>Employee pays through Payroll Deduction </b><td>
                          <td></td>
                          <td></td>
                          <td></td>
                          </tr>
                          <tr *ngFor="let companypay of payrollCCplansarr">

                            <td>
                              <span *ngIf="companypay.upgradeCalculation;else normalname">{{companypay.upgradeCalculation.employeeShareStatement}}</span>
                              <ng-template #normalname>
                                {{companypay.planname}}
                              </ng-template>

                            </td>
                            <td >{{companypay.type}}</td>

                            <td class="grandTotal amounts" >
                              <span *ngIf="companypay.upgradeCalculation;else normalamount">{{ currencySymbol
                              }}{{ companypay.upgradeCalculation.deltaplanPrice | number: "1.2-2" }}</span>
                          <ng-template #normalamount>
                            {{ currencySymbol
                            }}{{ companypay.amount | number: "1.2-2" }}
                          </ng-template>
                        </td>
                            <td class="grandTotal amounts">
                              <span *ngIf="companypay.upgradeCalculation;else normaltax">{{ currencySymbol
                              }}{{ companypay.upgradeCalculation.deltaplanTax | number: "1.2-2" }}</span>

                            <ng-template #normaltax>
                              {{ currencySymbol
                              }}{{ companypay.tax | number: "1.2-2" }}
                            </ng-template>
                          </td>
                            <td class="grandTotal amounts">
                              <span *ngIf="companypay.upgradeCalculation;else normaltotal">
                             <b>{{ currencySymbol}}{{ companypay.upgradeCalculation.deltaplanTotal | number: "1.2-2" }}</b></span>

                              <ng-template #normaltotal>
                                <b>{{ currencySymbol
                                }}{{ companypay.total | number: "1.2-2" }}</b>
                              </ng-template>
                            </td>
                          </tr>

                      <tr style="background-color: #D08000" *ngIf="employercheck">

                        <td>Total charge to Company</td>
                        <td ></td>
                        <td class="grandTotal amounts">---</td>
                        <td class="grandTotal amounts">---</td>
                        <td class="grandTotal amounts">
                         <b>---</b>


                        </td>
                      </tr>
                      <tr style="background-color: #D08000" *ngIf="!employercheck">

                        <td>Total charge to Company</td>
                        <td ></td>
                        <td class="grandTotal amounts">{{ currencySymbol
                        }}{{ companydealingplansTotal | number: "1.2-2" }}</td>
                        <td class="grandTotal amounts">{{ currencySymbol
                        }}{{ companydealingplanstaxTotal | number: "1.2-2" }}</td>
                        <td class="grandTotal amounts">
                         <b>{{ currencySymbol
                          }}{{ companydealingplansgrandTotal | number: "1.2-2" }}</b>


                        </td>
                      </tr>
                      <tr>
                        <td><td>
                        <td></td>
                        <td></td>
                        <td></td>
                        </tr>
                      <tr *ngIf="employeeDealingPlansarr.length == 0 ? false : true">
                        <td><b>Employee pays through CC/PAD </b><td>
                        <td></td>
                        <td></td>
                        <td></td>
                        </tr>
                        <tr *ngFor="let companypay of employeeDealingPlansarr">

                          <td>
                            <!-- <span *ngIf="companypay.upgradeCalculation;else normalname">{{companypay.upgradeCalculation.compnayShareStatement}}</span> -->
                            <!-- <ng-template > -->
                              {{companypay.planname}}
                            <!-- </ng-template> -->

                          </td>
                          <td >{{companypay.type}}</td>

                          <td class="grandTotal amounts">
                            <!-- <span *ngIf="companypay.upgradeCalculation;else normalamount">{{ currencySymbol
                            }}{{ companypay.upgradeCalculation.deltaplanPrice | number: "1.2-2" }}</span> -->
                        <!-- <ng-template> -->
                          {{ currencySymbol
                          }}{{ companypay.amount | number: "1.2-2" }}
                        <!-- </ng-template> -->
                      </td>
                          <td class="grandTotal amounts" >
                            <!-- <span *ngIf="companypay.upgradeCalculation;else normaltax">{{ currencySymbol
                            }}{{ companypay.upgradeCalculation.deltaplanTax | number: "1.2-2" }}</span> -->

                          <!-- <ng-template> -->
                            {{ currencySymbol
                            }}{{ companypay.tax | number: "1.2-2" }}
                          <!-- </ng-template> -->
                        </td>
                          <td class="grandTotal amounts">
                            <!-- <span *ngIf="companypay.upgradeCalculation;else normaltotal">
                           <b>{{ currencySymbol}}{{ companypay.upgradeCalculation.deltaplanTotal | number: "1.2-2" }}</b></span> -->

                            <!-- <ng-template > -->
                              <b>{{ currencySymbol
                              }}{{ companypay.total | number: "1.2-2" }}</b>
                            <!-- </ng-template> -->
                          </td>
                        </tr>

                        @if(iafgplansCheck.length>0){
                        <ng-container *ngFor="
                        let plan of iafgplansCheck;
                        let i = index">
                        <tr style="background-color:#ffff">
                          <td colspan="6">&nbsp;
                            <b>{{plan.packagename}}</b>
                          </td>
                        </tr>
                        <tr *ngFor="let product of plan.products;let j = index">
                          <td>&nbsp;
                          {{plan.name}}
                          </td>

                          <td class="align-center">
                            <span *ngIf="plan.coverage =='MEMBER' || plan.coverage =='MEMBER & SPOUSE';else placheck">{{plan.coverage}}</span>
                            <ng-template #placheck>---</ng-template>

                          </td>
                          <td class="align-right">

                            <strong>Member:</strong>

                            <table class="table text-nowrap" style="font-size:12px">

                              <tbody>
                                <tr class=" ">
                                  <td class=" border-right">Units</td>
                                  <td>({{plan.member.coverageValue/plan.member.standardUnits}})</td>

                                </tr>
                                <tr class=" ">
                                  <td class=" border-right">Premium</td>
                                  <td>{{plan.member.premium | currency:"USD"}}</td>

                                </tr>
                                <tr class=" ">
                                  <td class=" border-right">Amount</td>
                                  <td>{{plan.member.amount | currency:"USD"}}</td>

                                </tr>
                                <tr class=" ">
                                  <td class=" border-right">Guarantee Acceptance</td>
                                  <td>
                                    @if(plan.member.defaultRange>=plan.member.maximumCoverageWithoutEvidence){
                                      {{plan.member.maximumCoverageWithoutEvidence | currency:"USD"}}
                                    }
                                    @else{
                                      {{plan.member.defaultRange | currency:"USD"}}
                                    }

                                  </td>

                                </tr>
                                @if(plan.member.maximumCoverageWithoutEvidence>plan.member.defaultRange){
                                  <tr class=" ">
                                    <td class=" border-right">Acceptance pending approval</td>
                                    <td>

                                        {{plan.member.maximumCoverageWithoutEvidence-plan.member.defaultRange | currency:"USD"}}



                                    </td>

                                  </tr>
                                }
                              </tbody>
                            </table>

                          @if(plan.coverage =='MEMBER & SPOUSE'){

                            <strong>Spouse:</strong>
                            <table class="table text-nowrap" style="font-size:12px">

                              <tbody>
                                <tr class=" ">
                                  <td class=" border-right">Units</td>
                                  <td>({{plan.spouse.coverageValue/plan.spouse.standardUnits}})</td>

                                </tr>
                                <tr class=" ">
                                  <td class=" border-right">Premium</td>
                                  <td>{{plan.spouse.premium | currency:"USD"}}</td>

                                </tr>
                                <tr class=" ">
                                  <td class=" border-right">Amount</td>
                                  <td>{{plan.spouse.amount | currency:"USD"}}</td>

                                </tr>
                                <tr class=" ">
                                  <td class=" border-right">Guarantee Acceptance</td>
                                  <td>
                                    @if(plan.spouse.defaultRange>=plan.spouse.maximumCoverageWithoutEvidence){
                                      {{plan.spouse.maximumCoverageWithoutEvidence | currency:"USD"}}
                                    }
                                    @else{
                                      {{plan.spouse.defaultRange | currency:"USD"}}
                                    }

                                  </td>

                                </tr>
                                @if(plan.spouse.maximumCoverageWithoutEvidence>plan.spouse.defaultRange){
                                  <tr class=" ">
                                    <td class=" border-right">Acceptance pending approval</td>
                                    <td>

                                        {{plan.spouse.maximumCoverageWithoutEvidence-plan.spouse.defaultRange | currency:"USD"}}



                                    </td>

                                  </tr>
                                }
                              </tbody>
                            </table>

                      }
                      <tr>
                        @if(plan.coverage =='MEMBER & SPOUSE'){
                        <td>Total:${{plan.member.amount+plan.spouse.amount | number : '1.2-2'}}</td>
                        }
                        @if(plan.coverage =='MEMBER'){
                          <td>Total:${{plan.member.amount | number : '1.2-2'}}</td>
                          }
                      </tr>
                            <!-- {{product.calculatedTax.price  | currency:"USD"}}&nbsp; -->
                          </td>
                          <td class="align-right">
                            <!-- <strong>Member:</strong>

                            <table class="table text-nowrap" style="font-size:12px">

                              <tbody>


                                <tr class=" ">
                                  <td class=" border-right">Tax</td>
                                  <td>{{plan.member.tax | currency:"USD"}}</td>

                                </tr>
                              </tbody>
                            </table> -->

                          <!-- @if(plan.coverage =='MEMBER & SPOUSE'){

                            <strong>Spouse:</strong>
                            <table class="table text-nowrap" style="font-size:12px">

                              <tbody>

                                <tr class=" ">
                                  <td class=" border-right">Tax</td>
                                  <td>{{plan.spouse.tax | currency:"USD"}}</td>

                                </tr>

                              </tbody>
                            </table>

                      } -->
                      <tr>
                        @if(plan.coverage =='MEMBER & SPOUSE'){
                        <td>Total:${{plan.member.tax | number : '1.2-2'}}</td>
                        }
                        @if(plan.coverage =='MEMBER'){
                          <td>Total:${{plan.member.tax | number : '1.2-2'}}</td>
                          }
                        <tr>
                            <!-- {{product.calculatedTax.tax  | currency:"USD"}}&nbsp; -->
                          </td>
                          <td class="align-right">
                            {{product.calculatedTax.total  | currency:"USD"}}&nbsp;
                          </td>
                        </tr>
                        </ng-container>
                      }

                    <tr style="background-color: #D08000" *ngIf="employeeDealingPlansarr.length == 0 ? false : true">

                      <td>Total charge to Employee</td>
                      <td></td>

                      <td class="grandTotal amounts">{{ currencySymbol
                      }}{{ empDirectpayPlanamountTotal | number: "1.2-2" }}</td>
                      <td class="grandTotal amounts">{{ currencySymbol
                      }}{{ empDirectpayPlantaxTotal | number: "1.2-2" }}</td>
                      <td class="grandTotal amounts">
                       <b>{{ currencySymbol
                        }}{{ empDirectpayPlangrandTotal | number: "1.2-2" }}</b>


                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <!-- <table>
                    <label>Monthly payment Sumamry</label>
                    <tr></tr>
                  </table> -->
                </div>
              </div>
            }
            @if(addbenificaryData.length>0){
              <div class="form-group col-md-12">
                <table class="table table-bordered text-black" id="customerPlansTable">

                  <tbody>
                    <tr>
                      <th>Type</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Relation</th>
                      <th>Minor</th>
                      <th>DOB</th>
                      <th>Contingent First Name</th>
                      <th>Contingent Last Name</th>
                      <th>Trustee First Name</th>
                      <th>Trustee Last Name</th>
                      <th>Percentage</th>
                      <th></th>
                    </tr>

                    <tr *ngFor="
                          let broker of addbenificaryData;
                          let i = index
                        " [ngClass]="{
                          orange: i % 2 == 0,
                          white: i % 2 != 0
                        }">

                      <td>{{broker.customerType}}</td>

                      <td>
                        {{broker.firstName}}
                      </td>
                      <td>
                        {{broker.lastName}}
                      </td>
                      <td>
                        {{broker.relationship}}
                      </td>
                      <td>
                        @if(broker.isMinor=="true"){
                        Yes
                        }@else{
                        No
                        }
                      </td>

                      <td>

                        {{broker.dob | date}}
                      </td>

                      <td>
                        {{broker.contingentFirstName}}
                      </td>
                      <td>
                        {{broker.contingentLastName}}
                      </td>
                      <td>

                        @if(broker.trusteeFirstName==""){
                        NA
                        }@else{
                        {{broker.trusteeFirstName}}
                        }

                      </td>
                      <td>
                        @if(broker.trusteeLastName==""){
                        NA
                        }@else{
                        {{broker.trusteeLastName}}
                        }
                      </td>

                      <td>
                        {{broker.percentage}}
                      </td>

                      <td class="thBottomNone text-center inline-flex items-center">
                        <svg class="h-10 w-10 text-green-600" (click)="editbenficiary($event, i, broker)"
                          *ngIf="enableEditIndex != i" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                          stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                          <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                          <line x1="16" y1="5" x2="19" y2="8" />
                        </svg>
                        <svg class="h-9 w-9 text-red-600" (click)="deleteBenficiary(broker, i)" *ngIf="enableEditIndex != i"
                          width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                          stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <rect x="3" y="4" width="18" height="4" rx="2" />
                          <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                          <line x1="10" y1="12" x2="14" y2="12" />
                        </svg>


                      </td>

                    </tr>
                  </tbody>
                </table>



              </div>

              }

              <!-- no use -->
          @if(alloptinplans && alloptinplans.length == 0 ? false : true){
            <div class="col-span-full">
              <label for="cardnumnber"
                ><strong>{{lang.opt_in_plans_summary}}90</strong></label
              >

              <div>
                <table class="table table-bordered text-black" id="customerPlansTable">
                  <tbody>
                    <tr>
                      <!-- <th>S. no</th> -->
                      <th>{{lang.plan}}</th>
                      <th>{{lang.details}}</th>
                      <!-- <th class="taxamount"> Amount</th>

                  <th class="taxamount">Tax</th>
                  <th class="taxamount">Total</th> -->
                    </tr>
                    <tr
                      *ngFor="
                        let item of alloptinplans;
                        let i = index
                      "
                    >
                      <!-- <td>{{i+1}}</td> -->
                      <!-- {{item.groupName | lowercase}}- -->

                      <td>
                        {{ item.planname }}<br />({{
                          item.packagename
                        }})
                      </td>
                      <td>{{ item.details }}</td>
                      <!-- <td class="taxamount">{{currencySymbol}}{{item.planPrice | number : '1.2-2'}}</td>

                  <td class="taxamount"><span *ngIf="item.gstCheck" class="gsttaxes">{{item.gstPrice==0?"-":"(GST)&nbsp;"+currencySymbol+(item.gstPrice | number : '1.2-2')}}</span><span *ngIf="item.pstCheck" class="gsttaxes">{{item.pstPrice==0?"-":"(PST)&nbsp;"+currencySymbol+(item.pstPrice | number : '1.2-2')}}</span><span *ngIf="item.hstCheck"  class="gsttaxes">{{item.hstPrice==0?"-":"(HST)&nbsp;"+currencySymbol+(item.hstPrice | number : '1.2-2')}}</span><span *ngIf="!item.gstCheck && !item.pstCheck && !item.hstCheck ">-</span></td>
                  <td class="grandTotal">{{currencySymbol}}{{item.totalPrice | number : '1.2-2'}}</td> -->
                    </tr>

                    <tr>
                      <!-- <td>Total </td><td colspan="2"></td><td class="grandTotal">{{currencySymbol}}0.00</td> -->
                    </tr>
                    <!-- <tr>
                  <td colspan="3">

                  </td>
                  <td colspan="2" class="grandTotal">Total:&nbsp;{{currencySymbol}}{{planAmount | number : '1.2-2'}}</td>
                </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
         }


            <br />
            <div class="col-span-full">
              <input
              type="checkbox" name="comanyprovided" value="true" (change)="companyPlansConfirmation($event)"

              />&nbsp;<span class="termsandconditions"
              >{{lang.company_provided_confirmation}}
            </span>
            @if (companyplansCheck) {
              <div class="invalid text-red-500">Please confirm Company paid plans</div>
              }
            </div>
            @if(payrollCCplansarr.length>0){
            <div class="col-span-full">
              <input
              type="checkbox" name="upgradeplans" value="true" (change)="upgradeplansConfirmation($event)"

              />&nbsp;<span class="termsandconditions"
              >{{lang.upgrade_plans_confirmation}}
            </span>
            @if (upgradeplansCheck) {
              <div class="invalid text-red-500">Please confirm Upgrade plans</div>
              }
            </div>
          }

          @if(showPaymenthMethod && EmployeeSelectedPlans.length>0 && directpayCCplansarr.length==0 || iafgplansCheck.length>0){
            <div class="col-span-full">
              <input
              type="checkbox"  name="voluntary" value="true" (change)="voluntryplansConfirmation($event)"

              />&nbsp;<span class="termsandconditions"
              >{{lang.voluntary_opt_in_confirmation}}
            </span>
            @if (voluntryplansCheck) {
              <div class="invalid text-red-500">Please confirm voluntary plans</div>
              }
            </div>
          }

          @if(showPaymenthMethod && EmployeeSelectedPlans.length==0 && directpayCCplansarr.length>0){
            <div class="col-span-full">
              <input
              type="checkbox"  name="voluntary"

              />&nbsp;<span class="termsandconditions">
                I confirm enrolling into Upgrade plans split amount and charge monthly premiums to Credit Card or PAD as per data below.
            </span>
            </div>
          }
          @if(showPaymenthMethod && EmployeeSelectedPlans.length>0 && directpayCCplansarr.length>0){
            <div class="col-span-full">
              <input
                type="checkbox"  name="voluntary"

                />&nbsp;<span class="termsandconditions"
                >I confirm enrolling into voluntary opt-ins along with upgrade plans split amount and charge monthly premiums to Credit Card or PAD as per data below.
              </span>
            </div>
          }
          <div class="col-span-w-1/2" [hidden]="showPaymenthMethod">
            <label for="cardnumnber">{{lang.signature}}<span style="color: red">*</span></label>

            <div class="signature-container sm:w-1/4" style="border:1px solid #ccc;border-radius: 4px;">

              <signature-pad [options]="signaturePadOptions" (touchmove)="onMouseMove($event)"
              (mousemove)="onMouseMove($event)"  (onEndEvent)="drawComplete()" (onBeginEvent)="drawStart()"></signature-pad>
              @if(signaturecheckvalue1cc){
                <div style="
                left: 10px;
                color: red;
                text-decoration: underline;cursor:pointer">
                    <p (click)="clearSignature()">x {{lang.clear}}</p>
                </div>
              }
                <!-- <canvas
#sigplanconf
(mousedown)="onMouseDownplan($event)"   (touchstart)="onMouseDownplan($event)" (touchmove)="onMouseMoveplan($event)"
(mousemove)="onMouseMoveplan($event)" required
></canvas>
              <div
                *ngIf="signaturecheckvalue1PlansConfirmation"
                style="
                  position: absolute;
                  top: 32px;
                  left: 20px;
                  color: red;
                  text-decoration: underline;
                  cursor: pointer;
                ">
                <p (click)="clearPadPlansConfirmation()">x {{lang.clear || "Clear"}}</p>
              </div>

              <span class="help-block text-danger" *ngIf="signaturemessagePlansConfirmation"
                >{{lang.please_sign_the_signature}}</span
              > -->
              <!-- <span
            class="help-block text-danger"
            *ngIf="signaturemessageccconfirm">Please confirm the signature</span
          > -->
            </div>

            <br />
            <!-- <form class="signbtn" *ngIf="signaturecheckvaluePlansConfirmation">
            </form> -->
          </div>





        <!-- Second column -->
      </div>
      @if(showPaymenthMethod){
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-3 mx-2">
                <div class="col-span-full tex-center items-center">
          @if(EmployeeSelectedPlans.length>0 && directpayCCplansarr.length==0){
            {{lang.voluntary_opt_ins_payment}}  : {{lang.total}} ({{currencySymbol}}{{cartDisplayAmount}})
          }
          @if(EmployeeSelectedPlans.length==0 && directpayCCplansarr.length>0){
            Upgrade plans  : {{lang.total}} ({{currencySymbol}}{{DirectCCpayPlangrandTotal}})
          }
          @if(EmployeeSelectedPlans.length>0 && directpayCCplansarr.length>0){
            {{lang.voluntary_opt_ins_payment}} & Upgrade plans  : {{lang.total}} {{currencySymbol}}{{empDirectpayPlangrandTotal}} ({{currencySymbol}}{{cartDisplayAmount}} + {{currencySymbol}}{{DirectCCpayPlangrandTotal}})
          }
          <form
          [formGroup]="paymentForm"
          #paymentformCC
          action="https://payments.subscriptionplatform.com/v2/payments/create"
          method="post"
        >
          <!-- <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 gap-4 p-4 "  > -->

          <div class="col-span-1">
            <input
              class="form-control"
              autofocus="autofocus"
              id="CustomerId"
              formcontrolname="cutomerId"
              name="CustomerID"
              type="hidden"
              value="{{ customerid }}"
            />
          </div>
          <div class="col-span-1">
            <input
              class="form-control"
              id="PublicApiKey"
              formcontrolname="PublicApiKey"
              name="PublicApiKey"
              type="hidden"
              value="{{ publicapikey }}"
            />
          </div>

          <div class="col-span-1">
            <input
              class="form-control"
              id="SuccessUri"
              formcontrolname="SuccessUri"
              name="SuccessUri"
              type="hidden"
              value="{{ paymentsuccessuri }}"
            />
          </div>

          <div class="col-span-1">
            <input
              class="form-control"
              id="FailUri"
              formcontrolname="FailUri"
              name="FailUri"
              type="hidden"
              value="{{ paymentUri }}"
            />
          </div>

          <div class="col-span-1">
            <input
              class="form-control"
              id="MakeDefault"
              placeholder="Name on the card"
              value="true"
              type="hidden"
              name="MakeDefault"
            />
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">

            <div class="col-span-3">
              <label for="eamil"
                >{{ lang.email }}<span style="color: red">*</span></label
              >

              <input
                class="form-control"
                id="Email"
                placeholder="{{ lang.email }}"
                type="hidden"
                name="email"
                [(ngModel)]="paymentemail"
                value="{{paymentemail}}"
              />
              <input
                class="form-control"
                id="Email"
                placeholder="{{ lang.email }}"
                type="email" readonly
                formControlName="email"
                [(ngModel)]="paymentemail"
                value="{{paymentemail}}"
              />


            </div>

            <div class="col-span-3">
              <div>
                <label for="cardnumnber">{{ lang.payment_method }}</label>
              </div>
              <div class="flex items-center space-x-2">
                <input
                  type="radio"
                  id="true"
                  formControlName="paymentRadioCheck"
                  value="PAD"
                  class="form-radio text-blue-600" (click)="paymentmethod($event)"
                />
                <label for="true" class="text-gray-700">{{
                  lang.pre_authorized_debit
                }}</label>
              </div>

              <div class="flex items-center space-x-2">
                <input
                  type="radio"
                  id="false"
                  formControlName="paymentRadioCheck"  [checked]="bankpaymentmethod === 'CC'"
                  value="CC"
                  class="form-radio text-blue-600" (click)="paymentmethod($event)"
                />
                <label for="false" class="text-gray-700">{{
                  lang.credit_card
                }}</label>
              </div>
            </div>
          </div>

            @if(paymentForm.get('paymentRadioCheck')?.value === 'CC')
            {
              <div class="grid grid-cols-1 sm:grid-cols-3 gap-1 border-[1px] border-indigo-700 backdrop-blur-sm rounded-xl px-2 py-2">
              <!-- <span class="bg-gradient-to-r from-[#d5dfe1] to-[#616874] rounded-xl px-4 py-8 w-3/4"> -->
                <div class="col-span-3 sm:col-span-1">
                  <label for="firstname"
                    > {{ lang.first_name
                    }}<span style="color: red">*</span></label
                  >
                  <input
                    class="form-control"
                    id="firstname"
                    placeholder="{{ lang.first_name }}"
                    name="FirstName"
                    type="hidden"
                    value="Rakesh"
                  />
                  <input
                    class="form-control"
                    id="firstname"
                    placeholder="{{ lang.first_name }}"
                    formControlName="FirstName"
                    type="text"
                    [(ngModel)]="paymentFirstName"
                    (keypress)="alphabatesOnly($event)"
                  />
                  @if (payment['FirstName'].errors &&
                  payment['FirstName'].touched) {
                  <div class="invalid text-red-500">
                    @if (payment['FirstName'].errors['required']) {
                    <div>{{ lang.please_enter_first_name }}</div>
                    }
                  </div>
                  }
                </div>
                <div class="col-span-3 sm:col-span-1">
                  <label for="lastname"
                    >{{ lang.lastName
                    }}<span style="color: red">*</span></label
                  >
                  <input
                    class="form-control"
                    id="LastName"
                    placeholder="{{ lang.lastName }}"
                    name="LastName"
                    type="hidden"
                    value="test"
                  />
                  <input
                    class="form-control"
                    id="LastName"
                    placeholder="{{ lang.lastName }}"
                    formControlName="LastName"
                    type="text"
                    [(ngModel)]="paymentLastName"
                    (keypress)="alphabatesOnly($event)"
                  />
                  @if (payment['LastName'].errors &&
                  payment['LastName'].touched) {
                  <div class="invalid text-red-500">
                    @if (payment['LastName'].errors['required']) {
                    <div>{{ lang.please_enter_last_name }}</div>
                    }
                  </div>
                  }
                </div>
                <div class="col-span-3 sm:col-span-1 animate-pulse content-end">
                  @if(cardType){
                    <img
                    class="w-20 h-14"
                    src={{cardType}}
                  />
                    }

                </div>

                  <div class="col-span-2">
                    <label for="cardnumnber"
                      >{{ lang.cardnumnber
                      }}<span style="color: red">*</span></label
                    >
                    <!-- (input)="credit_card_number($event)" -->
                    <!-- <img id="card-image" src="" alt="Card Image"> -->
                    <input
                      class="form-control"
                      id="CardNumber"
                      placeholder="{{ lang.cardnumnber }}"
                      name="CardNumber"
                      formControlName="CardNumber"
                      (input)="creditcardlblurmethod($event)"
                      (keypress)="
                        ($event.charCode >= 48 && $event.charCode < 58)
                      "
                    />

                    @if (payment['CardNumber'].errors &&
                    payment['CardNumber'].touched) {
                    <div class="invalid text-red-500">
                      @if (payment['CardNumber'].errors['required']) {
                      <div>{{ lang.please_enter_card_number }}</div>
                      } @if
                      (payment['CardNumber'].errors['invalidcreditcarddetails'])
                      {
                      <div>{{ lang.please_enter_valid_credit_card }}</div>
                      }
                      @else{
                      @if(invalidcreditcard==true){

                        <div class="invalid text-red-500">
                          {{ lang.please_enter_valid_credit_card }}
                        </div>

                        }
                      }
                    </div>
                    }
                  </div>
                  <div class="col-span-1"></div>
                  <div class="col-span-3 sm:col-span-1">
                    <label for="expirymonth"
                      >{{ lang.expirymonth
                      }}<span style="color: red">*</span></label
                    >

                    <select
                      class="form-control"
                      id="ExpirationMonth"
                      name="ExpirationMonth" (change)="filterExpiryDate($event)"
                      formControlName="ExpirationMonth"
                      placeholder="MM"
                    >
                      <option value="" disabled selected>
                        {{ lang.select }}
                      </option>
                      <option value="01">Jan</option>
                      <option value="02">Feb</option>
                      <option value="03">Mar</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">Aug</option>
                      <option value="09">Sept</option>
                      <option value="10">Oct</option>
                      <option value="11">Nov</option>
                      <option value="12">Dec</option>
                    </select>
                    @if (payment['ExpirationMonth'].errors &&
                    payment['ExpirationMonth'].touched) {
                    <div class="invalid text-red-500">
                      @if (payment['ExpirationMonth'].errors['required']) {
                      <div>{{ lang.please_select_expiry_month }}</div>
                      } @if (payment['ExpirationMonth'].errors['min']) {
                      <div>minim</div>
                      } @if (payment['ExpirationMonth'].errors['max']) {
                      <div>maxmim</div>
                      }
                    </div>
                    }
                    <!-- @if (payment['ExpirationMonth'].errors['invalidcreditcarddetails']) {
                                        <div>{{ lang.please_enter_valid_credit_card }}</div>
                                        } -->
                    <!-- <span
                                      class="help-block text-danger"
                                      *ngIf="
                                        paymentForm.get('ExpirationMonth').touched &&
                                        paymentForm
                                          .get('ExpirationMonth')
                                          .hasError('required')
                                      "
                                      >{{lang.please_select_expiry_month}}</span
                                    >

                                    <span
                                      class="help-block text-danger"
                                      *ngIf="showexpirymontherror"
                                      >{{lang.please_enter_valid_expiry_month}}</span
                                    > -->
                  </div>
                  <div class="col-span-3 sm:col-span-1">
                    <label for="expiryyear"
                      >{{ lang.expiryyear
                      }}<span style="color: red">*</span></label
                    >

                    <select
                      class="form-control"
                      id="ExpirationYear"
                      placeholder="YY"
                      name="ExpirationYear" (change)="filterExpiryDate($event)"
                      formControlName="ExporationYear"
                    >
                      <option value="" disabled selected>
                        {{ lang.select }}
                      </option>
                      <option
                        *ngFor="let item of [].constructor(40); let i = index"
                        value="{{ i + 24 }}"
                      >
                        {{ i + 2024 }}
                      </option>
                    </select>
                    @if (payment['ExporationYear'].errors &&
                    payment['ExporationYear'].touched) {
                    <div class="invalid text-red-500">
                      @if (payment['ExporationYear'].errors['required']) {
                      <div>{{ lang.please_select_expiry_year }}</div>
                      } @if (payment['ExporationYear'].errors['expiryDate']) {
                      <div>cant minimminim</div>
                      }
                    </div>
                    }
                    <!-- <span
                                      class="help-block text-danger"
                                      *ngIf="
                                        paymentForm.get('ExporationYear').touched &&
                                        paymentForm.get('ExporationYear').hasError('required')
                                      "
                                      >{{lang.please_select_expiry_year}}</span
                                    >

                                    <span
                                      class="help-block text-danger"
                                      *ngIf="showexpiryyearerror"
                                      >{{lang.please_enter_valid_expiry_year}}</span
                                    > -->
                  </div>

                  <div class="col-span-3 sm:col-span-1">
                    <label for="cvv"
                      >{{ lang.cvv }}<span style="color: red">*</span></label
                    >

                    <input
                      class="form-control"
                      id="Cvv"
                      placeholder="CVV"
                      name="Cvv"
                      formControlName="cvv"
                      maxlength="4"
                      (keypress)="
                        ($event.charCode >= 48 && $event.charCode < 58)
                      "
                    />
                    @if (payment['cvv'].errors && payment['cvv'].touched) {
                    <div class="invalid text-red-500">
                      @if (payment['cvv'].errors['required']) {
                      <div>{{ lang.please_enter_cvv }}</div>
                      } @if (payment['cvv'].errors['minlength']) {
                      <div>{{ lang.cvv_must_be_3_or_4_digits }}</div>
                      }
                    </div>
                    }
                  </div>


                  @if(invalidCardDetails){
                    <div class="col-span-3">
                      <span class="invalid text-red-500">Please enter the correct Month and Year</span>
                    </div>
                  }
                  </div>

                <!-- </span> -->
                <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
            <div class="col-span-3">
              <input type="checkbox"  (click)="homeaddresscheckCC($event)"/>&nbsp;<span class="paymentaddress"
                >{{ lang.paymentaddress }}
              </span>
            </div>

            <div class="col-span-3 sm:col-span-1">
              <label for="maidenname"
                >{{ lang.streetAddressLine
                }}<span style="color: red">*</span></label
              >
              <input
                type="text"
                class="form-control checking-field"
                id="Address1"
                placeholder="{{ lang.streetAddressLine }}"
                formControlName="streetaddress"
                autocomplete="off"

              />
              <input type="hidden" name="Address1" />
              @if (payment['streetaddress'].errors &&
              payment['streetaddress'].touched) {
              <div class="invalid text-red-500">
                @if (payment['streetaddress'].errors['required']) {
                <div>{{ lang.please_enter_street_address }}</div>
                }
              </div>
              }
            </div>
            <div class="col-span-3 sm:col-span-1">
              <label for="maidenname"
                >{{ lang.streetAddressLine2 }}<span></span
              ></label>
              <input
                type="text"
                class="form-control"
                id="Address2"

                formControlName="streetaddressline2"
                autocomplete="off"
                placeholder="{{ lang.streetAddressLine2 }}"
              />
              <input type="hidden" name="Address2" />
            </div>
            <div class="col-span-3 sm:col-span-1"></div>

            <div class="col-span-3 sm:col-span-1">
              <label for="maidenname"
                >{{ lang.city }}<span style="color: red">*</span></label
              >
              <input
                type="text"
                class="form-control checking-field"
                id="City"
                placeholder="{{ lang.city }}"
                formControlName="city"

              />
              <input type="hidden" name="City" />
              @if (payment['city'].errors && payment['city'].touched) {
              <div class="invalid text-red-500">
                @if (payment['city'].errors['required']) {
                <div>{{ lang.please_enter_city }}</div>
                }
              </div>
              }
            </div>
            <div class="col-span-3 sm:col-span-1">
              <label for="Province"
                >{{ lang.province }}<span style="color: red">*</span></label
              >

              <select
                class="form-control"
                id="StateId"
                placeholder="select"
                formControlName="province" (change)="provincelistCreditPayment($event)"

                autocomplete="off"
              >
                <option value="">{{ lang.select }}</option>
                <option
                  *ngFor="let name of configprovinceres"
                  [value]="name.shortName"
                >
                  {{ name.shortName }}
                </option>
              </select>

              <input type="hidden" name="StateId" />

              @if (payment['province'].errors && payment['province'].touched) {
              <div class="invalid text-red-500">
                @if (payment['province'].errors['required']) {
                <div>{{ lang.please_select_province }}</div>
                }
              </div>
              }
            </div>
            <div class="col-span-3 sm:col-span-1">
              <label for="maidenname"
                >{{ lang.postalCode }}<span style="color: red">*</span></label
              >
              <input
                type="text"
                class="form-control checking-field"
                id="PostalZip"
                formControlName="postalcode"
                autocomplete="off"
                placeholder="{{ lang.postalCode }}"
               (input)="changeTextToUppercaseCreditPayment('postalcode',$event)"
              />
              <input type="hidden" name="PostalZip" />

              @if (payment['postalcode'].errors && payment['postalcode'].touched) {
              <div class="invalid text-red-500">
                @if (payment['postalcode'].errors['required']) {
                <div>{{ lang.please_enter_postal_code }}</div>
                }
              </div>
              } @if (creditCradInvalidProvince==true) {
                <div class="invalid text-red-500">
                  {{ lang.please_enter_valid_postal_code }}
                </div>
                }
            </div>
            <div class="col-span-3">
              <label for="signature"
              >{{ lang.signature }}<span style="color: red">*</span></label

            >

            <div class="signature-container sm:w-1/2" style="border:1px solid #ccc;border-radius: 4px;">

              <!-- <button class="btn btn-primary btn-inline" (click)="clearPadcc()" [disabled]="signaturecheckvalue1cc">Clear</button> -->

              <!-- <canvas
                #canvascc
                (touchstart)="startDrawingcc($event)"
                (touchmove)="movedcc($event)"
                (click)="signaturestartcc($event)" required
              ></canvas> -->

      <signature-pad [options]="signaturePadOptions" #signaturePad1 (touchmove)="onMouseMoveCredit($event)"
      (mousemove)="onMouseMoveCredit($event)"  (onEndEvent)="drawCompleteCC()" (onBeginEvent)="drawStartCC()"></signature-pad>
     @if(signaturecheckCC){
              <div style="
              left: 10px;
              color: red;
              text-decoration: underline;cursor:pointer">
                  <p (click)="clearSignatureCC()">x {{lang.clear}}</p>
              </div>
            }




            </div>
            @if(signaturemessagecc){
              <span
              class="invalid text-red-500"
              >{{lang.please_sign_the_signature}}</span
            >
    }
            </div>
            <div class="col-span-3">
              <re-captcha
                (resolved)="resolved($event)"
                formControlName="recaptchaReactive"
                siteKey="{{ fusbillinfocaptchavalue }}"
              >
              </re-captcha>

              <input
                id="recaptcha_token"
                type="hidden"
                name="recaptcha_token"
                [value]="recaptchaResponse"
              />
              @if (payment['recaptchaReactive'].errors &&
              payment['recaptchaReactive'].touched) {
              <div class="invalid text-red-500">
                @if (payment['recaptchaReactive'].errors['required']) {
                <div>{{ lang.please_check_captcha_validation }}</div>
                }
              </div>
              }
            </div>
            </div>

            }


            @if(paymentForm.get('paymentRadioCheck')?.value === 'PAD')
            {
              <form [formGroup]="bankpayForm" #bankForm="ngForm">
                <!-- <div class="grid grid-cols-1 sm:grid-cols-6 gap-2"> -->
                  <!-- style="max-width:90px;margin-left:5px" -->

                  <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
                  <div class="col-span-3 sm:col-span-1">
                    <label for="cardnumnber"
                      >{{ lang.bank_number }}<span style="color: red">*</span></label
                    >
                    <input
                      class="form-control checking-field"
                      id="banknumber"
                      placeholder="{{ lang.bank_number }}"
                      name="banknumber"
                      autocomplete="off"
                      formControlName="banknumber"
                      maxlength="3" (input)="touchedmsg()"
                      (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                    />

                    @if (bankpay['banknumber'].errors &&
                    bankpay['banknumber'].touched) {
                    <div class="invalid text-red-500">
                      @if (bankpay['banknumber'].errors['required']) {
                      <div>{{ lang.please_enter_bank_number }}</div>
                      }
                      @if (bankpay['banknumber'].errors['checkbanknumbervalidator']) {
                        <div>{{ lang.bank_number_must_be_3_digits }}</div>
                        }
                    </div>
                    }

                  </div>
                    <!-- style="max-width:120px;" -->
                    <div class="col-span-3 sm:col-span-1">
                      <label for="transitnumber"
                        >{{ lang.transit_number
                        }}<span style="color: red">*</span></label
                      >
                      <!-- (input)="credit_card_number($event)" -->
                      <input
                        class="form-control checking-field"
                        id="branch"
                        placeholder="{{ lang.transit_number }}"
                        name="branch"
                        formControlName="transitnumber"
                        autocomplete="off"
                        maxlength="5" (input)="touchedmsg()"
                        (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                      />
                      @if (bankpay['transitnumber'].errors &&
                      bankpay['transitnumber'].touched) {
                      <div class="invalid text-red-500">
                        @if (bankpay['transitnumber'].errors['required']) {
                        <div>{{ lang.please_enter_transit_number }}</div>
                        }
                        @if (bankpay['transitnumber'].errors['checktransitnumbervalidator']) {
                          <div>{{ lang.transit_number_must_be_5_digits }}</div>
                          }
                      </div>
                      }

                    </div>
                    <div class="col-span-3 sm:col-span-1">
                      <label for="accountNumber"
                        >{{ lang.account_number
                        }}<span style="color: red">*</span></label
                      >
                      <!-- (input)="credit_card_number($event)" -->
                      <input
                        class="form-control checking-field"
                        id="accountnumber"
                        placeholder="{{ lang.account_number }}"
                        autocomplete="off"
                        name="accountnumber"
                        formControlName="accountnumber"
                        maxlength="12" (input)="touchedmsg()"
                        (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                      />
                      @if (bankpay['accountnumber'].errors &&
                      bankpay['accountnumber'].touched) {
                      <div class="invalid text-red-500">
                        @if (bankpay['accountnumber'].errors['required']) {
                        <div>{{ lang.please_enter_account_number }}</div>
                        }
                        @if (bankpay['accountnumber'].errors['checkaccountnumbervalidator']) {
                          <div>{{ lang.account_number_must_be_5_12_digits }}</div>
                          }
                      </div>
                      }


                    </div>
                    @if(bankerrormsg){
                      <div class="col-span-3 invalid text-red-500 ">{{bankerrormsg}}</div>
                    }

                    <div class="col-span-3">
                      <label for="bankdetails">{{ lang.bank_details }}</label>
                      <!-- (input)="credit_card_number($event)" -->
                      <textarea
                        class="form-control"
                        id="bankname"
                        placeholder="{{ lang.bank_details }}"
                        name="bankname"
                        formControlName="bankname"
                        style="color: rgba(85, 85, 85, 0.9)"
                        [ngModel]="banknameres" rows="6"
                      ></textarea>
                      @if (bankpay['bankname'].errors &&
                      bankpay['bankname'].touched) {
                      <div class="invalid text-red-500">
                        @if (bankpay['bankname'].errors['required']) {
                        <div>{{ lang.please_verify_the_bank_details }}</div>
                        }

                      </div>
                      }


                    </div>


                  <div class="col-span-3" >
                    @if(verifybankdetails && verifybutton){
                      <button
                      type="submit"
                      class="w-36 h-14 mb-1 rounded-full border-[1px] border-indigo-700 bg-white p-0.5 font-bold transition duration-700 ease-in-out hover:bg-white hover:border-green-500" (click)="verifybank()"
                    >
                  {{lang.verify}}
                    </button>&nbsp;

                    }

                    @if(verifybankdetails){

                    <button
                    type="submit"
                    class="w-36 h-14 mb-1 rounded-full border-[1px] border-indigo-700 bg-gray-200 p-0.5 font-bold transition duration-700 ease-in-out hover:bg-white" (click)="checkbankdetails($event)"
                  >
                  {{lang.reset}}
                  </button>
                    }


                    <!-- <form class="signbtn">
                      <button class="btn btn-primary btn-inline"  *ngIf="(verifybankdetails && verifybutton)"  (click)="verifybank()" style="margin-left:5px">{{lang.verify}}</button>
                      <button class="btn btn-primary btn-inline" *ngIf="verifybankdetails"  id="no" value="no" (click)="checkbankdetails($event)" >{{lang.reset}}</button>
                    </form>
                    <div class="clearfix"></div> -->


                    <!-- <span *ngIf="(bankdetailsverify && verifybankdetails)"  class="help-block text-danger" style="margin-top:20px;margin-left:10px">{{lang.please_verify_the_bank_details}}</span> -->

                  </div>


                  <div class="col-span-2">
                      <label for="uploadvoidCheque"
                        >{{ lang.upload_void_cheque
                        }}<span style="color: red">*</span></label
                      >
                      <!-- (input)="credit_card_number($event)" -->
                      <input type="file" formControlName="voidcheck"  (change)="voidcheckupload($event)" />

                      @if (bankpay['voidcheck'].errors &&
                      bankpay['voidcheck'].touched) {
                      <div class="invalid text-red-500">
                        @if (bankpay['voidcheck'].errors['required']) {
                        <div>{{ lang.please_upload_void_cheque_file }}</div>
                        }

                      </div>
                      }

                    </div>
                    <div class="col-span-1">
                      @if(imagedisplay){
                      <!-- <button type="button"
                      class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700"
                          (click)="showvoidcheckpreview()"
                          >
                          {{lang.preview}}
                        </button> -->

                        <div class="">
                          <button
                          type="button"
                          class="w-36 h-14 mb-2 mt-2 rounded-full border-2 border-gray-700 bg-gray-200 p-0.5 font-bold hover:border-indigo-700 hover:bg-white"
                          (click)="showvoidcheckpreview()"
                        >
                        {{lang.preview}}
                        </button>
                          <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
                          <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
                        </div>
                      }
                    </div>

                    <div class="col-span-3">
                      <input type="checkbox" (click)="homeaddresscheck($event)" />&nbsp;<span class="paymentaddress"
                        >{{ lang.paymentaddress }}
                      </span>
                    </div>


                    <div class="col-span-3 sm:col-span-1">
                      <label for="maidenname"
                        >{{ lang.streetAddressLine
                        }}<span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control checking-field"
                        id="Address1"
                        placeholder="{{ lang.streetAddressLine }}"
                        formControlName="bankstreetaddress"
                        autocomplete="off"

                      />
                      @if (bankpay['bankstreetaddress'].errors &&
                      bankpay['bankstreetaddress'].touched) {
                      <div class="invalid text-red-500">
                        @if (bankpay['bankstreetaddress'].errors['required']) {
                        <div>{{ lang.please_enter_street_address }}</div>
                        }
                      </div>
                      }
                    </div>
                    <div class="col-span-3 sm:col-span-1">
                      <label for="maidenname"
                        >{{ lang.streetAddressLine2 }}<span></span
                      ></label>
                      <input
                        type="text"
                        class="form-control"
                        id="Address2"

                        formControlName="bankstreetaddressline2"
                        autocomplete="off"
                        placeholder="{{ lang.streetAddressLine2 }}"
                      />
                    </div>
                    <div class="col-span-3 sm:col-span-1"></div>

                    <div class="col-span-3 sm:col-span-1">
                      <label for="city"
                        >{{ lang.city }}<span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control checking-field"
                        id="City"
                        placeholder="{{ lang.city }}"
                        formControlName="bankcity"
                        value=""
                      />
                      @if (bankpay['bankcity'].errors && bankpay['bankcity'].touched) {
                      <div class="invalid text-red-500">
                        @if (bankpay['bankcity'].errors['required']) {
                        <div>{{ lang.please_enter_city }}</div>
                        }
                      </div>
                      }
                    </div>
                    <div class="col-span-3 sm:col-span-1">
                      <label for="Province"
                        >{{ lang.province }}<span style="color: red">*</span></label
                      >

                      <select
                        class="form-control"
                        id="StateId"
                        placeholder="select"
                        formControlName="bankprovince"
                        value=""  (change)="provincelistpayment($event)"
                        autocomplete="off"
                      >
                        <option value="">{{ lang.select }}</option>
                        <option
                          *ngFor="let name of configprovinceres"
                          [value]="name.shortName"
                        >
                          {{ name.shortName }}
                        </option>
                      </select>


                      @if (bankpay['bankprovince'].errors && bankpay['bankprovince'].touched) {
                      <div class="invalid text-red-500">
                        @if (bankpay['bankprovince'].errors['required']) {
                        <div>{{ lang.please_select_province }}</div>
                        }
                      </div>
                      }
                    </div>
                    <div class="col-span-3 sm:col-span-1">
                      <label for="postalCode"
                        >{{ lang.postalCode }}<span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control checking-field"
                        id="PostalZip"
                        formControlName="bankpostalcode"
                        autocomplete="off" (input)="changeTextToUppercasePayment('bankpostalcode', $event)"
                        placeholder="{{ lang.postalCode }}"
                        value=""
                      />
                      <input type="hidden" name="PostalZip" />

                      @if (bankpay['bankpostalcode'].errors && bankpay['bankpostalcode'].touched) {
                      <div class="invalid text-red-500">
                        @if (bankpay['bankpostalcode'].errors['required']) {
                        <div>{{ lang.please_enter_postal_code }}</div>
                        }
                      </div>
                      }  @if (bankinvalidPostalCode==true) {
                        <div class="invalid text-red-500">
                          {{ lang.please_enter_valid_postal_code }}
                        </div>
                        }


                    </div>

                    <div class="col-span-3">

                      <button
                      type="submit"
                      class="w-full sm:w-1/2 h-14 mb-1 max-2 rounded-full border-[1px] border-indigo-700 bg-white p-0.5 font-bold transition duration-700 ease-in-out text-black mt-2" (click)="showpadaggrement()"
                    >
                    {{lang.review_and_sign_the_pad_agreement}}
                    </button>
                    <span
                    class="invalid text-red-500" >{{padaggrementerror}}</span>
                    </div>


                  </div>
              </form>

            }


            @if(paymentForm.get('paymentRadioCheck')?.value === 'CC'){


              <button class="fixed right-0 bottom-16 w-36 h-14 mb-1 rounded-full border-2 border-indigo-700 bg-gray-200 p-0.5 font-bold  mx-12 hover:bg-white" (click)="creditcardpaysubmit(paymentForm.value,paymentformCC)">
                {{ lang.submit }}
              </button>
            }

          </form>
        </div>
      </div>
      }
    <div class="relative">

      <!-- Left Button -->
      <!-- <button class="fixed left-0 bottom-16  w-36 h-14 mb-1 rounded-full border-2 border-indigo-700 bg-gray-200 p-0.5 font-bold mx-2 sm:mx-12 hover:bg-white" (click)="gotoPlansPage()">
        {{ lang.previous }}
      </button> -->


      <button class="fixed text-center inline-flex items-center left-0 bottom-16  w-40 h-14 mb-1 rounded-full border-2 border-indigo-700 bg-gray-200 p-0.5 font-bold  mx-2 sm:mx-14 hover:bg-white" (click)="gotoPlansPage()">

        <svg class="h-10 w-10 text-sky-900"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="5" y1="12" x2="19" y2="12" />  <line x1="5" y1="12" x2="11" y2="18" />  <line x1="5" y1="12" x2="11" y2="6" /></svg>
        &nbsp;{{ lang.previous }}
      </button>
      <!-- Right Button -->

      @if(paymentForm.get('paymentRadioCheck')?.value === 'PAD'){



        <button class="fixed right-0 bottom-16 w-36 h-14 mb-1 rounded-full border-2 border-indigo-700 bg-gray-200 p-0.5 font-bold  mx-12 hover:bg-white" (click)="bankpayformsubmit(bankpayForm.value)">
          {{ lang.submit }}
        </button>
      }

       <!-- @if(paymentForm.get('paymentRadioCheck')?.value =='') {

        <button class="fixed right-0 bottom-16 w-36 h-14 mb-1 rounded-full border-2 border-indigo-700 bg-gray-200 p-0.5 font-bold  mx-12 hover:bg-white" (click)="registartionform(paymentForm.value)">
          {{ lang.submit }}
        </button>

      } -->

      @if(paymentForm.get('paymentRadioCheck')?.value !== 'CC' && paymentForm.get('paymentRadioCheck')?.value !== 'PAD') {

        <button class="fixed right-0 bottom-16 w-36 h-14 mb-1 rounded-full border-2 border-indigo-700 bg-gray-200 p-0.5 font-bold  mx-12 hover:bg-white" (click)="registartionform(paymentForm.value)">
          {{ lang.submit }}
        </button>

      }

      </div>
      </div>
    }
    </div>
  </div>

<app-confirmation-model
[show]="showDeleteModal"
[message]="modalMessage"
(confirmed)="onConfirmed($event)">
</app-confirmation-model>

@if(showModelDialog || showPADModelDiaslog){
<app-model-dialog
[show]="showModelDialog"
[showPAD]="showPADModelDiaslog"
[message]="pdfData"
[headMessage]="header"
(pdfconfirmed)="onPdfConfirmed($event)" (padPdfconfirmed)="onPADPdfConfirmed($event)">
</app-model-dialog>
}

@if(showModal){
<app-forms-model
    [show]="showModal"
    [editChildData]="editChildInfo" [buttonInfo]="childInfoModalAddAndUpdateButton"
    (submitForm)="onSubmitForm($event)" [formConfigData]="languageTokensArray"
    (close)="onCloseModal()">
  </app-forms-model>
}
@if(ShowModelPopup){
<app-modelpopupconfirmation [show]="ShowModelPopup" [showHeaderMessage]="ShowModelPopupTitle" [showModelContent]="ShowModelPopupMessage" (closedModelpopup)="onClosedModelpopup($event)">

</app-modelpopupconfirmation>
}

@if(missingInformation){
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
<!--
      Background backdrop, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-lg bg-white text-center mx-auto shadow-xl transition-all sm:w-1/3">
          <div class="bg-white">
            <div class="">
              <div class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
              Missing Information
              </div>
              <div class="text-left mx-2" style="  max-height: calc(100vh - 300px);
        overflow-y: auto;">
                @for(item of missingInfo; track item;let i=$index){
                <div >
                  <span>{{i+1}}.&nbsp;{{item}}&nbsp;<span style="color: red">*</span></span>
                </div>
              }
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-2 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700">
            <button
            type="button"
            class="w-36 h-14 mb-2 mt-2 rounded-full border-2 border-gray-700 bg-gray-200 p-0.5 font-bold hover:border-indigo-700 hover:bg-white"
            (click)="closemissingInfoModel()"
          >
         {{lang.close}}
          </button>
            <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
}

@if(planOptionsModel){
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
<!--
      Background backdrop, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div class="relative transform overflow-hidden rounded-lg bg-white text-center mx-auto shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl max-w-2xl">
          <div class="bg-white pb-2 pt-2 ">
            <div class="">
              <div class="text-center font-bold text-2xl">
                {{lang.choose_options}}
              </div>
              <div class="mt-3 text-left" style="  max-height: calc(100vh - 200px);
        overflow-y: auto;">


              <input type="hidden" id="plandetailsobj" value="{{plandetailsobjvalue}}">
              <input type="hidden" id="optionumber" value="{{optionstitle.length}}">


              @for(option of optionstitle; track option;let i = $index){
              <div>
                <h3><b>{{option.name}}</b><span style="color: red">*</span></h3>

                @for(optionvalue of option.planOptionsValues; track optionvalue;let j = $index){
                <div class="row">
                  <div class="col-md-12" style="border: 1px solid black;padding:5px 15px;border-bottom:1px !important">
                    <input type="radio" value="{{optionvalue.json}}" id="planselectionvalue-{{optionvalue.id}}-{{option.id}}" class="optionselectionmethod-{{option.id}}" (click)="planoptionselection($event,optionvalue,option.id,optionvalue.id)">&nbsp;{{optionvalue.name}}

                  </div>
                </div>
              }
                <br>
              </div>
            }
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">


          <p id="planoptionmessage"><b>{{optionmessage}}</b></p>
          <button
          type="button"
          class="w-36 h-14 mb-1 rounded-full border-2 border-gray-700 bg-gray-200 p-0.5 font-bold"
            (click)="closeoptionsselection()"
          >
          {{lang.close}}
          </button>
          <button
          type="button"
          class="w-36 h-14 mb-1 rounded-full border-2 border-green-700 bg-gray-200 p-0.5 font-bold"
            (click)="confirmoptionsselection()"
          >
          {{lang.confirm}}
            <!-- <strong style="color: white">{{lang.confirm}}</strong> -->
          </button>
            <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
}

@if(padAggrementModel){
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

        <div class="relative transform overflow-hidden rounded-xl bg-white text-center mx-auto shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl max-w-2xl">
          <div class="bg-white">
            <div class="">
              <div class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
                {{lang.pad_agreement}}
              </div>
              <div class="text-center">
                <pdf-viewer [src]="generatePADaggrement"  style=" height: 60vh;z-index:9999;overflow-y: scroll;" class="px-0 w-fill object-cover" ></pdf-viewer>

              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700 gap-2">

@if(signaturestatus){
          <button type="button"
          class="w-44 h-14 mb-1 rounded-full order-first border-2 border-green-700 bg-gray-200 p-0.5 font-bold hover:bg-white"

          (click)="signaturestartmodel()">
          {{lang.sign__agree}}
        </button>
      }
      @if(agreestatus){
        <button type="button"
        class="w-36 h-14 mb-1 order-2 rounded-full border-2 border-green-700 bg-gray-200 p-0.5 font-bold hover:bg-white"

          (click)="agreepadconditions()">
          {{lang.agree}}
        </button>
      }
      @if(rejectPADModel){
          <button type="button"
          class="w-36 h-14 mb-1 order-3 rounded-full border-2 border-gray-700 bg-gray-200 p-0.5 font-bold hover:bg-white"

            (click)="rejectPADpafaggrement()"
          >
            {{lang.reject}}
          </button>
        }

            <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
}

@if(padAggrementFinalModel){
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

        <div class="relative transform overflow-hidden rounded-xl bg-white text-center mx-auto shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl max-w-2xl">
          <div class="bg-white">
            <div class="">
              <div class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
                {{lang.pad_agreement}}
              </div>
              <div class="text-center">
                <pdf-viewer [src]="generatePADaggrement"  style=" height: 60vh;z-index:9999;overflow-y: scroll;" class="px-0 w-fill object-cover" ></pdf-viewer>

              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700 gap-2">


      @if(showagree){
        <button type="button"
        class="w-36 h-14 mb-1 order-2 rounded-full border-2 border-green-700 bg-gray-200 p-0.5 font-bold hover:bg-white"

          (click)="agreepadconditions()">
          {{lang.agree}}
        </button>
      }
      @if(rejectPADModel){
          <button type="button"
          class="w-36 h-14 mb-1 order-3 rounded-full border-2 border-gray-700 bg-gray-200 p-0.5 font-bold hover:bg-white"

            (click)="rejectPADpafaggrement1()"
          >
            {{lang.reject}}
          </button>
        }
        @if(closePADModel){
          <button type="button"
          class="w-36 h-14 mb-1 order-4 rounded-full border-2 border-gray-700 bg-gray-200 p-0.5 font-bold hover:bg-white"

            (click)="closePADAdggrementModel()"
          >
            {{lang.close}}
          </button>
        }
            <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
}

@if(signModelForBankPay){
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

        <div class="relative transform overflow-hidden rounded-xl bg-white text-center mx-auto shadow-xl transition-all sm:my-8 sm:w-1/4 sm:max-w-7xl max-w-2xl">
          <div class="bg-white">
            <div class="">
              <div class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
                Signature
              </div>
              <div class="text-center">

        <div class="signature-container col-span-1 w-full" style="border:1px solid #ccc;border-radius: 4px;">

          <!-- <button class="btn btn-primary btn-inline" (click)="clearPadcc()" [disabled]="signaturecheckvalue1cc">Clear</button> -->

          <!-- <canvas
            #canvascc
            (touchstart)="startDrawingcc($event)"
            (touchmove)="movedcc($event)"
            (click)="signaturestartcc($event)" required
          ></canvas> -->

  <signature-pad [options]="signaturePadOptions" #signaturePad2 (touchmove)="onMouseMovePad($event)"
  (mousemove)="onMouseMovePad($event)"  (onEndEvent)="drawCompletePad()" (onBeginEvent)="drawStartpad()"></signature-pad>
 @if(signaturecheckPAD){
          <div style="
          left: 10px;
          color: red;
          text-decoration: underline;cursor:pointer">
              <p (click)="clearSignaturePAD()">x {{lang.clear}}</p>
          </div>
        }

        </div>
        @if(showpaderrorMessage){
          <span
          class="invalid text-red-500"
          >{{lang.please_sign_the_signature}}</span
        >
}
                <!-- <div class="mt-2 border-2 border-green-200 rounded-lg w-1/2"> -->


                  <!-- <canvas
                  #canvasPadElement
                  (mousedown)="onMouseDownpad($event)" (touchstart)="onMouseDownpad($event)" (touchmove)="onMouseMovepad($event)"
      (mousemove)="onMouseMovepad($event)" required  style="border: 1px solid #ccc;border-radius: 4px;"
                ></canvas>
                @if(signaturecheckvalue1){
                  <div style="
                  left: 10px;
                  color: red;
                  text-decoration: underline;cursor:pointer">
                      <p (click)="clearPadSignature()">x {{lang.clear}}</p>
                  </div>
                }
        @if(signaturemessagepad){
                  <span
                  class="invalid text-red-500"
                  >{{lang.please_sign_the_signature}}</span
                >
        } -->
                <!-- </div> -->


              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700">


          <button type="button"
          class="w-36 h-14 mb-1 rounded-full border-2 border-gray-700 bg-gray-200 p-0.5 font-bold hover:bg-white"

          (click)="savePadsignature()">{{lang.confirm}}

          </button>

            <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
}

@if(imageDisplayShow){
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

        <div class="relative transform overflow-hidden rounded-xl bg-white text-center mx-auto shadow-xl transition-all sm:my-8 sm:w-1/4 sm:max-w-7xl max-w-2xl">
          <div class="bg-white">
            <div class="">
              <div class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
                {{lang.preview}}
              </div>
              <div class="text-center">

        <div class="signature-container col-span-1 w-full" style="border:1px solid #ccc;border-radius: 4px;">


          @if(normalview){
            <img [src]="imagedisplay" />
          }

          @if(pdfview){

          <pdf-viewer
            [src]="imagedisplay"
            style=" height: 60vh;z-index:9999;overflow-y: scroll;" class="px-0 w-fill object-cover"
            ></pdf-viewer>

          }

              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700">


          <button type="button"
          class="w-36 h-14 mb-1 rounded-full border-2 border-gray-700 bg-gray-200 p-0.5 font-bold hover:bg-white"

          (click)="closeImagePreview()">{{lang.close}}

          </button>

            <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

}


@if(benficiary){
  <div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
    <div class="bg-white  rounded-xl shadow-lg  overflow-scroll">
      <div
        class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
        <h2 class="text-[14px] font-semibold">Beneficiary Information</h2>
        <!-- <button (click)="onClose()" class="text-gray-500 hover:text-gray-800 flex items-end justify-end">&times;</button> -->
      </div>

      @if(showBenificiaryForm){
      <form [formGroup]="benficiaryForm">

        <div class="grid grid-cols-1 sm:grid-cols-12 gap-2 p-2">
          <div class="sm:col-span-3">
            <label for="" class="">Beneficiary<span class="text-danger">*</span></label>

            <select class="form-control" placeholder="Select" formControlName="customerType">

              <option value="" selected disabled>Select</option>
              <option value="MEMBER" selected>Member</option>
              <option value="SPOUSE" selected>Spouse</option>
            </select>


            @if (b['customerType'].errors && b['customerType'].touched) {
            <div class="invalid text-red-500">
              @if (b['customerType'].errors['required']) {
              <div>Please select beneficiary</div>
              }
            </div>
            }

          </div>

          <div class="sm:col-span-3">
            <label for="" class="">{{lang.first_name}}<span class="text-danger">*</span></label>
            <input class="form-control checking-field" type="text" placeholder="First Name" formControlName="firstName"
              (keypress)="alphabatesOnly($event)">

            @if (b['firstName'].errors && b['firstName'].touched) {
            <div class="invalid text-red-500">
              @if (b['firstName'].errors['required']) {
              <div>{{ lang.please_enter_first_name }}</div>
              } @if (b['firstName'].errors['minlength']) {
              <div>{{ lang.first_name_minm_error }}</div>
              }
            </div>
            }

          </div>
          <div class="sm:col-span-3">
            <label for="" class="">{{lang.lastName}}<span class="text-danger">*</span></label>
            <input type="text" class="form-control checking-field" placeholder="Last Name" formControlName="lastName"
              (keypress)="alphabatesOnly($event)">


            @if (b['lastName'].errors && b['lastName'].touched) {
            <div class="invalid text-red-500">
              @if (b['lastName'].errors['required']) {
              <div>{{ lang.please_enter_last_name }}</div>
              } @if (b['lastName'].errors['minlength']) {
              <div>{{ lang.last_name_minm_error }}</div>
              }
            </div>
            }



          </div>
          <div class="sm:col-span-3">
            <label for="" class="">Relation<span class="text-danger">*</span></label>


            <select class="form-control" placeholder="Select" formControlName="relationship"
              (change)="isChildCheck($event)">

              <option value="" selected disabled>Select</option>
              <option *ngFor="let name of beneficariesDetails?.relationship" [value]="name.value">
                {{ name.label }}
              </option>

            </select>


            @if (b['relationship'].errors && b['relationship'].touched) {
            <div class="invalid text-red-500">
              @if (b['relationship'].errors['required']) {
              <div>Please select relationship</div>
              }
            </div>
            }



          </div>
          @if(benficiaryForm.get('relationship')?.value === 'CHILD'){
          <div class="sm:col-span-3">
            <label for="" class="">Is this person under age 18<span class="text-danger">*</span></label>
            <div class="flex items-center space-x-2">
              <input type="radio" id="true" formControlName="isMinor" value="true" class="form-radio text-blue-600"
                (change)="isMinorCheck($event)" />
              <label for="true" class="text-gray-700">Yes</label>
            </div>

            <div class="flex items-center space-x-2">
              <input type="radio" id="false" formControlName="isMinor" value="false" class="form-radio text-blue-600"
                (change)="isMinorCheck($event)" />
              <label for="false" class="text-gray-700">No</label>
            </div>


            @if (b['isMinor'].errors && b['isMinor'].touched) {
            <div class="invalid text-red-500">
              @if (b['isMinor'].errors['required']) {
              <div>Please select minor status</div>
              }
            </div>
            }



          </div>
          }

          @if(benficiaryForm.get('isMinor')?.value === 'true'){
          <div class="col-span-3">
            <label for="date_of_birth">{{ lang.dateOfBirth }}<span style="color: red">*</span></label>

            <input type="text" (paste)="(false)" class="form-control" formControlName="dob" [minDate]="childminDate"
              [maxDate]="childmaxDate" #dp [bsConfig]="{dateInputFormat: 'MM-DD-YYYY'}" placeholder="MM-DD-YYYY" bsDatepicker
              (blur)="checkDateOfBirthMinor($event)" (input)="checkDateOfBirthMinor($event)"
              (change)="checkDateOfBirth($event)" (keypress)="numbersOnly($event)" />

            <!-- <input id="datepicker-autohide"  formControlName="date_of_birth" datepicker datepicker-autohide type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  placeholder="MM-DD-YYYY"> -->

            @if (b['dob'].errors && b['dob'].touched) {
            <div class="invalid text-red-500">
              @if (b['dob'].errors['required']) {
              <div>{{ lang.please_choose_date_of_birth }}</div>
              }
            </div>
            }
          </div>
          }
          <div class="sm:col-span-3">
            <label for="" class="">Contingent {{lang.first_name}}<span class="text-danger">*</span></label>
            <input class="form-control checking-field" type="text" placeholder="First Name"
              formControlName="contingentFirstName" (keypress)="alphabatesOnly($event)">

            @if (b['contingentFirstName'].errors && b['contingentFirstName'].touched) {
            <div class="invalid text-red-500">
              @if (b['contingentFirstName'].errors['required']) {
              <div>{{ lang.please_enter_first_name }}</div>
              } @if (b['contingentFirstName'].errors['minlength']) {
              <div>{{ lang.first_name_minm_error }}</div>
              }
            </div>
            }

          </div>
          <div class="sm:col-span-3">
            <label for="" class="">Contingent {{lang.lastName}}<span class="text-danger">*</span></label>
            <input type="text" class="form-control checking-field" placeholder="Last Name"
              formControlName="contingentLastName" (keypress)="alphabatesOnly($event)">

            @if (b['contingentLastName'].errors && b['contingentLastName'].touched) {
            <div class="invalid text-red-500">
              @if (b['contingentLastName'].errors['required']) {
              <div>{{ lang.please_enter_last_name }}</div>
              } @if (b['contingentLastName'].errors['minlength']) {
              <div>{{ lang.last_name_minm_error }}</div>
              }
            </div>
            }



          </div>

          @if(benficiaryForm.get('isMinor')?.value === 'true'){
          <div class="sm:col-span-3">
            <label for="" class="">Trustee {{lang.first_name}}<span class="text-danger">*</span></label>
            <input class="form-control checking-field" type="text" placeholder="First Name"
              formControlName="trusteeFirstName" (keypress)="alphabatesOnly($event)">

            @if (b['trusteeFirstName'].errors && b['trusteeFirstName'].touched) {
            <div class="invalid text-red-500">
              @if (b['trusteeFirstName'].errors['required']) {
              <div>{{ lang.please_enter_first_name }}</div>
              } @if (b['trusteeFirstName'].errors['minlength']) {
              <div>{{ lang.first_name_minm_error }}</div>
              }
            </div>
            }

          </div>
          <div class="sm:col-span-3">
            <label for="" class="">Trustee {{lang.lastName}}<span class="text-danger">*</span></label>
            <input type="text" class="form-control checking-field" placeholder="Last Name"
              formControlName="trusteeLastName" (keypress)="alphabatesOnly($event)">

            @if (b['trusteeLastName'].errors && b['trusteeLastName'].touched) {
            <div class="invalid text-red-500">
              @if (b['trusteeLastName'].errors['required']) {
              <div>{{ lang.please_enter_last_name }}</div>
              } @if (b['trusteeLastName'].errors['minlength']) {
              <div>{{ lang.last_name_minm_error }}</div>
              }
            </div>
            }



          </div>
          }
          <div class="sm:col-span-3">
            <label for="" class="">Percentage<span class="text-danger">*</span></label>
            <input type="text" class="form-control checking-field" placeholder="Percentage" formControlName="percentage"
              (keypress)="numbersOnly($event)" (blur)="checkpercentagevalue($event)"
              (input)="checkpercentagevalue($event)">


            @if (b['percentage'].errors && b['percentage'].touched) {
            <div class="invalid text-red-500">
              @if (b['percentage'].errors['required']) {
              <div>Please enter percentage</div>
              }
            </div>
            }



          </div>
          <div class="sm:col-span-3">

            <button type="button"
              class="w-28 h-12 mt-12 rounded-full border-2 border-indigo-700 bg-gray-200 p-0.5 font-bold transition duration-700 ease-in-out hover:bg-white"
              [hidden]="benficiaryForm.invalid" (click)="savebenficiary(benficiaryForm.value)">
              Save
            </button>
          </div>






        </div>
      </form>
      }

      @if(addbenificaryData.length>0){
      <div class="form-group col-md-12">
        <table class="table table-bordered text-black" id="customerPlansTable">

          <tbody>
            <tr>
              <th>Type</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Relation</th>
              <th>Minor</th>
              <th>DOB</th>
              <th>Contingent First Name</th>
              <th>Contingent Last Name</th>
              <th>Trustee First Name</th>
              <th>Trustee Last Name</th>
              <th>Percentage</th>
              <th></th>
            </tr>

            <tr *ngFor="
                  let broker of addbenificaryData;
                  let i = index
                " [ngClass]="{
                  orange: i % 2 == 0,
                  white: i % 2 != 0
                }">

              <td>{{broker.customerType}}</td>

              <td>
                {{broker.firstName}}
              </td>
              <td>
                {{broker.lastName}}
              </td>
              <td>
                {{broker.relationship}}
              </td>
              <td>
                @if(broker.isMinor=="true"){
                Yes
                }@else{
                No
                }
              </td>

              <td>

                {{broker.dob | date}}
              </td>

              <td>
                {{broker.contingentFirstName}}
              </td>
              <td>
                {{broker.contingentLastName}}
              </td>
              <td>

                @if(broker.trusteeFirstName==""){
                NA
                }@else{
                {{broker.trusteeFirstName}}
                }

              </td>
              <td>
                @if(broker.trusteeLastName==""){
                NA
                }@else{
                {{broker.trusteeLastName}}
                }
              </td>

              <td>
                {{broker.percentage}}
              </td>

              <td class="thBottomNone text-center inline-flex items-center">
                <svg class="h-10 w-10 text-green-600" (click)="editbenficiary($event, i, broker)"
                  *ngIf="enableEditIndex != i" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                  <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                  <line x1="16" y1="5" x2="19" y2="8" />
                </svg>
                <svg class="h-9 w-9 text-red-600" (click)="deleteBenficiary(broker, i)" *ngIf="enableEditIndex != i"
                  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <rect x="3" y="4" width="18" height="4" rx="2" />
                  <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                  <line x1="10" y1="12" x2="14" y2="12" />
                </svg>


              </td>

            </tr>
          </tbody>
        </table>

        <button type="button"
          class="w-36 h-12 mt-8 rounded-full border-2 border-indigo-700 bg-gray-200 p-0.5 font-bold transition duration-700 ease-in-out hover:bg-white"
          (click)="addnewbenficiary()">
          Add More
        </button>

      </div>
      <div class="flex justify-end gap-2 border border-t-indigo-700 px-2">
        <button type="button"
          class="w-36 h-14 mt-2 mb-2 rounded-full border-2 border-gray-700 bg-gray-200 p-0.5 font-bold hover:bg-white"
          (click)="onClose()">
          {{ lang.close }}
        </button>
        <button type="submit"
          class="w-36 h-14 mt-2 mb-2 rounded-full border-2 border-indigo-700 p-0.5 font-bold hover:border-green-500"
          (click)="finalbenificiarySubmit()">
          {{lang.submit}}
        </button>
      </div>
      }


    </div>
  </div>
  }


  @if(addeditbenficiary){
  <div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
    <div class="bg-white  rounded-xl shadow-lg w-full md:w-1/2">
      <div
        class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
        <h2 class="text-[14px] font-semibold">{{BenficairyModalAddAndUpdateButton}} Beneficiary Information</h2>
        <!-- <button (click)="onClose()" class="text-gray-500 hover:text-gray-800 flex items-end justify-end">&times;</button> -->
      </div>

      <form [formGroup]="benficiaryForm" (ngSubmit)="addeditbenficarySubmit()">

        <div class="grid grid-cols-1 sm:grid-cols-12 gap-2 p-2">

          <div class="sm:col-span-3">
            <label for="" class="">Beneficiary<span class="text-danger">*</span></label>



            <select class="form-control" placeholder="Select" formControlName="customerType">

              <option value="" selected disabled>Select</option>
              <option value="MEMBER" selected>Member</option>
              <option value="SPOUSE" selected>Spouse</option>

            </select>


            @if (b['customerType'].errors && b['customerType'].touched) {
            <div class="invalid text-red-500">
              @if (b['customerType'].errors['required']) {
              <div>Please select beneficiary</div>
              }
            </div>
            }

          </div>

          <div class="sm:col-span-3">
            <label for="" class="">{{lang.first_name}}<span class="text-danger">*</span></label>
            <input class="form-control checking-field" type="text" placeholder="First Name" formControlName="firstName"
              (keypress)="alphabatesOnly($event)">

            @if (b['firstName'].errors && b['firstName'].touched) {
            <div class="invalid text-red-500">
              @if (b['firstName'].errors['required']) {
              <div>{{ lang.please_enter_first_name }}</div>
              } @if (b['firstName'].errors['minlength']) {
              <div>{{ lang.first_name_minm_error }}</div>
              }
            </div>
            }

          </div>
          <div class="sm:col-span-3">
            <label for="" class="">{{lang.lastName}}<span class="text-danger">*</span></label>
            <input type="text" class="form-control checking-field" placeholder="Last Name" formControlName="lastName"
              (keypress)="alphabatesOnly($event)">


            @if (b['lastName'].errors && b['lastName'].touched) {
            <div class="invalid text-red-500">
              @if (b['lastName'].errors['required']) {
              <div>{{ lang.please_enter_last_name }}</div>
              } @if (b['lastName'].errors['minlength']) {
              <div>{{ lang.last_name_minm_error }}</div>
              }
            </div>
            }



          </div>
          <div class="sm:col-span-3">
            <label for="" class="">Relation<span class="text-danger">*</span></label>



            <select class="form-control" placeholder="Select" formControlName="relationship"
              (change)="isChildCheck($event)">

              <option value="" selected disabled>Select</option>
              <option *ngFor="let name of beneficariesDetails?.relationship" [value]="name.value">
                {{ name.label }}
              </option>

            </select>


            @if (b['relationship'].errors && b['relationship'].touched) {
            <div class="invalid text-red-500">
              @if (b['relationship'].errors['required']) {
              <div>Please select relationship</div>
              }
            </div>
            }



          </div>
          @if(benficiaryForm.get('relationship')?.value === 'CHILD'){
          <div class="sm:col-span-3">
            <label for="" class="">Is this person under age 18<span class="text-danger">*</span></label>
            <div class="flex items-center space-x-2">
              <input type="radio" id="true" formControlName="isMinor" value="true" class="form-radio text-blue-600"
                (change)="isMinorCheck($event)" />
              <label for="true" class="text-gray-700">Yes</label>
            </div>

            <div class="flex items-center space-x-2">
              <input type="radio" id="false" formControlName="isMinor" value="false" class="form-radio text-blue-600"
                (change)="isMinorCheck($event)" />
              <label for="false" class="text-gray-700">No</label>
            </div>


            @if (b['isMinor'].errors && b['isMinor'].touched) {
            <div class="invalid text-red-500">
              @if (b['isMinor'].errors['required']) {
              <div>Please select minor status</div>
              }
            </div>
            }



          </div>
          }

          @if(benficiaryForm.get('isMinor')?.value === 'true' && benficiaryForm.get('relationship')?.value === 'CHILD'){
          <div class="col-span-3">
            <label for="date_of_birth">{{ lang.dateOfBirth }}<span style="color: red">*</span></label>

            <input type="text" (paste)="(false)" class="form-control" formControlName="dob" [minDate]="childminDate"
              [maxDate]="childmaxDate" #dp [bsConfig]="{dateInputFormat: 'MM-DD-YYYY'}" placeholder="MM-DD-YYYY" bsDatepicker
              (blur)="checkDateOfBirthMinor($event)" (input)="checkDateOfBirthMinor($event)"
              (change)="checkDateOfBirth($event)" (keypress)="numbersOnly($event)" />

            <!-- <input id="datepicker-autohide"  formControlName="date_of_birth" datepicker datepicker-autohide type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  placeholder="MM-DD-YYYY"> -->

            @if (b['dob'].errors && b['dob'].touched) {
            <div class="invalid text-red-500">
              @if (b['dob'].errors['required']) {
              <div>{{ lang.please_choose_date_of_birth }}</div>
              }
            </div>
            }
          </div>
          }
          <div class="sm:col-span-3">
            <label for="" class="">Contingent {{lang.first_name}}<span class="text-danger">*</span></label>
            <input class="form-control checking-field" type="text" placeholder="First Name"
              formControlName="contingentFirstName" (keypress)="alphabatesOnly($event)">

            @if (b['contingentFirstName'].errors && b['contingentFirstName'].touched) {
            <div class="invalid text-red-500">
              @if (b['contingentFirstName'].errors['required']) {
              <div>{{ lang.please_enter_first_name }}</div>
              } @if (b['contingentFirstName'].errors['minlength']) {
              <div>{{ lang.first_name_minm_error }}</div>
              }
            </div>
            }

          </div>
          <div class="sm:col-span-3">
            <label for="" class="">Contingent {{lang.lastName}}<span class="text-danger">*</span></label>
            <input type="text" class="form-control checking-field" placeholder="Last Name"
              formControlName="contingentLastName" (keypress)="alphabatesOnly($event)">

            @if (b['contingentLastName'].errors && b['contingentLastName'].touched) {
            <div class="invalid text-red-500">
              @if (b['contingentLastName'].errors['required']) {
              <div>{{ lang.please_enter_last_name }}</div>
              } @if (b['contingentLastName'].errors['minlength']) {
              <div>{{ lang.last_name_minm_error }}</div>
              }
            </div>
            }



          </div>
          @if(benficiaryForm.get('isMinor')?.value === 'true' && benficiaryForm.get('relationship')?.value === 'CHILD'){
          <div class="sm:col-span-3">
            <label for="" class="">Trustee {{lang.first_name}}<span class="text-danger">*</span></label>
            <input class="form-control checking-field" type="text" placeholder="First Name"
              formControlName="trusteeFirstName" (keypress)="alphabatesOnly($event)">

            @if (b['trusteeFirstName'].errors && b['trusteeFirstName'].touched) {
            <div class="invalid text-red-500">
              @if (b['trusteeFirstName'].errors['required']) {
              <div>{{ lang.please_enter_first_name }}</div>
              } @if (b['trusteeFirstName'].errors['minlength']) {
              <div>{{ lang.first_name_minm_error }}</div>
              }
            </div>
            }

          </div>
          <div class="sm:col-span-3">
            <label for="" class="">Trustee {{lang.lastName}}<span class="text-danger">*</span></label>
            <input type="text" class="form-control checking-field" placeholder="Last Name"
              formControlName="trusteeLastName" (keypress)="alphabatesOnly($event)">

            @if (b['trusteeLastName'].errors && b['trusteeLastName'].touched) {
            <div class="invalid text-red-500">
              @if (b['trusteeLastName'].errors['required']) {
              <div>{{ lang.please_enter_last_name }}</div>
              } @if (b['trusteeLastName'].errors['minlength']) {
              <div>{{ lang.last_name_minm_error }}</div>
              }
            </div>
            }



          </div>
          }
          <div class="sm:col-span-3">
            <label for="" class="">Percentage<span class="text-danger">*</span></label>
            <input type="text" class="form-control checking-field" placeholder="Percentage" formControlName="percentage"
              (keypress)="numbersOnly($event)" (blur)="checkpercentagevalue($event)"
              (input)="checkpercentagevalue($event)">


            @if (b['percentage'].errors && b['percentage'].touched) {
            <div class="invalid text-red-500">
              @if (b['percentage'].errors['required']) {
              <div>Please enter percentage</div>
              }
            </div>
            }



          </div>







        </div>



        <div class="flex justify-end gap-2 border border-t-indigo-700 px-2">
          <button type="button"
            class="w-36 h-14 mt-2 mb-2 rounded-full border-2 border-gray-700 bg-gray-200 p-0.5 font-bold hover:bg-white"
            (click)="onCloseaddeditbenificary()">
            {{ lang.close }}
          </button>
          <button type="submit"
            class="w-36 h-14 mt-2 mb-2 rounded-full border-2 border-indigo-700 p-0.5 font-bold hover:border-green-500"
            [disabled]="benficiaryForm.invalid">
            {{lang.submit}}
          </button>
        </div>
      </form>




    </div>
  </div>
  }
  @if(benficiaryCount){
  <div class="fixed inset-0 flex items-center justify-center z-50 backdrop-blur confirm-dialog ">
    <div class="relative px-4 min-h-screen md:flex md:items-center md:justify-center">
      <div class=" opacity-25 w-full h-full absolute z-10 inset-0"></div>
      <div
        class="bg-white rounded-[14px] border border-gray-400 md:max-w-max md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative shadow-lg">
        <div class="md:flex items-center">
          <div
            class="rounded-full border border-gray-300 flex items-center justify-center w-20 h-16 flex-shrink-0 mx-auto">
            <i class="bx bx-error text-3xl">
              &#9888;
            </i>
          </div>
          <div class="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
            <p class="font-bold text-[16px]">Error!</p>
            <p class="text-[16px] text-gray-700 mt-1">{{benficiaryCountMessage}}.
            </p>
          </div>
        </div>
        <div class="text-center md:text-right mt-4 md:flex md:justify-end gap-2">
          <button id="confirm-delete-btn"
            class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 border border-red-500  rounded-full font-semibold text-[14px] md:ml-2 md:order-1"
            (click)="benficiaryconfirm()">
            Ok
          </button>&nbsp;
          <!-- <button id="confirm-cancel-btn" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 border border-gray-400 rounded-full font-semibold text-[14px] mt-4 md:mt-0 md:order-2" (click)="benficiaryconfirm()">
                      Cancel
                      </button> -->
        </div>
      </div>
    </div>
  </div>
  }

  @if(showSpouseSignature){
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

          <div class="relative transform overflow-hidden rounded-xl bg-white text-center mx-auto shadow-xl transition-all sm:my-8 sm:w-1/4 sm:max-w-7xl max-w-2xl">
            <div class="bg-white">
              <div class="">
                <div class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
                  Spouse Signature
                </div>
                <div class="text-center">

          <div class="signature-container col-span-1 w-full" style="border:1px solid #ccc;border-radius: 4px;">



    <signature-pad [options]="signaturePadOptions" (touchmove)="onMouseMove($event)"
    (mousemove)="onMouseMove($event)"  (onEndEvent)="drawComplete()" (onBeginEvent)="drawStart()"></signature-pad>
   @if(signaturecheckvalue1cc){
            <div style="
            left: 10px;
            color: red;
            text-decoration: underline;cursor:pointer">
                <p (click)="clearSignature()">x {{lang.clear}}</p>
            </div>
          }

          </div>
          @if(showIAFGerrorMessage){
            <span
            class="invalid text-red-500"
            >{{lang.please_sign_the_signature}}</span
          >
  }



                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700">


            <button type="button"
            class="w-36 h-14 mb-1 rounded-full border-2 border-gray-700 bg-gray-200 p-0.5 font-bold hover:bg-white"

            (click)="saveIAFGsignature()">{{lang.confirm}}

            </button>

              <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
              <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
            </div>
          </div>
        </div>
      </div>
      </div>


  }


  @if(iafginfo){
    <div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div class="bg-white  rounded-xl shadow-lg w-full md:w-1/2">
        <div class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
          <h2 class="text-[14px] font-semibold">IAFG Info</h2>
          <!-- <button (click)="onClose()" class="text-gray-500 hover:text-gray-800 flex items-end justify-end">&times;</button> -->
        </div>

        <form [formGroup]="iafggenderForm">
          <div class="grid grid-cols-1 sm:grid-cols-12 gap-2 p-2">
            <div class="sm:col-span-12">
              <label for="" class="">This Form have IAFG Insurance plans, do you wish to continue?<span class="text-danger">*</span></label>
              <div class="flex items-center space-x-2">
                <input
                  type="radio"
                  id="true"
                  formControlName="memberinfo"
                  value="true"
                  class="form-radio text-blue-600"  (change)="memberinfogenderCheck($event)"
                />
                <label for="true" class="text-gray-700">Yes</label>
              </div>

              <div class="flex items-center space-x-2">
                <input
                  type="radio"
                  id="false"
                  formControlName="memberinfo"
                  value="false"
                  class="form-radio text-blue-600"  (change)="memberinfogenderCheck($event)"
                />
                <label for="false" class="text-gray-700">No</label>
              </div>


                @if (IA['memberinfo'].errors && IA['memberinfo'].touched) {
                  <div class="invalid text-red-500">
                    @if (IA['memberinfo'].errors['required']) {
                    <div>Please select IAFG GenderAtBirth status</div>
                    }
                  </div>
                  }



         </div>

         @if(iafggenderForm.get('memberinfo')?.value === 'true'){
         <div class="sm:col-span-3">
          <label for="" class="">GenderAtBirth<span class="text-danger">*</span></label>

        <select class="form-control" placeholder="Select" formControlName="gender">

          <option value="" selected disabled>Select</option>

          <option value="male" >Male</option>
          <option value="female">Female</option>
        </select>


        @if (IA['gender'].errors && IA['gender'].touched) {
          <div class="invalid text-red-500">
            @if (IA['gender'].errors['required']) {
            <div>Please select type</div>
            }
          </div>
          }

        </div>
      }
            </div>
        </form>




      <div class="flex justify-end gap-2 border border-t-indigo-700 px-2">
        <button
          type="button"
          class="w-36 h-14 mt-2 mb-2 rounded-full border-2 border-gray-700 bg-gray-200 p-0.5 font-bold hover:bg-white"
          (click)="hideIAFGgenderfrom()"
        >
          {{ lang.close }}
        </button>
        <button
        type="submit"
        class="w-36 h-14 mt-2 mb-2 rounded-full border-2 border-indigo-700 p-0.5 font-bold hover:border-green-500" [disabled]="iafggenderForm.invalid" (click)="hideIAFGgenderfrom()">
{{lang.submit}}
      </button>
      </div>

      </div>
    </div>
  }


  @if(ConfirmModel){
    <div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div class="bg-white  rounded-xl shadow-lg  md:w-1/2 overflow-scroll">
        <div class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white">
          <h2 class="text-[14px] font-semibold">Info</h2>
          <!-- <button (click)="onClose()" class="text-gray-500 hover:text-gray-800 flex items-end justify-end">&times;</button> -->
        </div>
        <p class="p-8"><strong>Important Limitation applies for the first 24 months of this coverage:</strong></p>
        <ul class="p-8" style="list-style: disc !important">
          @if(termLifeplancheck){
          <p class="font-bold">Life Insurance:</p>
          <li>If the cause of death is non-accidental, no benefit will be paid but premiums will be refunded with 5% interest compounded annually . This limitation is removed if the insured Employee or Spouse subsequently apply and are approved for additional life coverage requiring evidence of insurability</li>
          <li>Death from suicide within 24 months of the effective date of an insured person’s coverage is excluded</li>
          }
          @if(criticalplancheck){
            <p class="font-bold">Critical Illness Insurance:</p>
          <li>Pre-existing Condition Exclusion applies for the first 24 months of this coverage: Any symptom, condition, disorder, illness, pre-disease or disease, or any mental, nervous or psychiatric condition or disorder for which any one of medical advice, treatment, service, prescribed medication, diagnosis or consultation, including consultation to investigate and/or diagnose (where diagnosis has not yet been made) was received by the insured or would have been received by a prudent individual within the 24 months immediately preceding the effective date.</li>
          <li>This pre-existing condition exclusion is removed if the insured Employee subsequently applies and is approved for additional critical illness coverage requiring evidence of insurability.</li>
          }
        </ul>
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700">


          <button type="button"
          class="w-36 h-14 mb-1 rounded-full border-2 border-gray-700 bg-gray-200 p-0.5 font-bold hover:bg-white"

          (click)="closeConfirmModel()">{{lang.confirm}}

          </button>
          </div>
        </div>
        </div>


      }
